import { ActionTypes } from "../helpers/enums"

const setFactories = (factories) => {
    return {
        type: ActionTypes.SET_ADMIN_FACTORIES,
        payload: factories
    }
}

const setActiveFactory = (factory) => {
    return{
        type: ActionTypes.SET_ADMIN_ACTIVE_FACTORY,
        payload: factory
    }
}

let adminFactoryActions = {setFactories, setActiveFactory};

export default adminFactoryActions;