import React, { forwardRef } from 'react';
import { Input } from 'reactstrap';
import { objectToString } from '../../helpers/commonHelpers';

export const CalculatedValueInput = forwardRef(
    (props, ref) => {
        const { value, onChange, ...properties } = props;
        //console.log('props', props);
        if (typeof value === "object") {
            const propertyCount = Object.keys(value).length;
            const rows = propertyCount > 9 ? 10 : propertyCount + 2;

            return (<Input {...properties}
                type="textarea"
                ref={ref}
                value={objectToString(value)}
                rows={rows}
                disabled={true}
            />)
        }

        return (<Input {...properties}
            type="text"
            ref={ref}
            value={value}
            disabled={true}
        />)
    }
)