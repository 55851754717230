import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import workspaceActions from '../../actions/workspaceActions';
import StorageLayout from './StorageLayout';
import { ItemSearch } from '../common/ItemSearch';
import { Section } from '../../helpers/enums';
import { ElementSearch } from './ElementSearch';

export function Elements({ msalInstance }){
    const type = Section.WORKSPACE;
    const dispatch = useDispatch();
    const [refreshItems, setRefreshItems] = useState([]);

    const setActiveStorage = (storage) => {
        //console.log('Elements.js - Set active storage!');
        dispatch(workspaceActions.setSelectedItems([]));
        //setStorageFactories(storage.id, dispatch);// We do not need to call this one since factories are refreshed already within StorageLayout.js!!!
        setRefreshItems([]);
    }

    const rowsSelected = (items) => {
        //console.log('selected items', items);//console.log('items.length', items.length);
        dispatch(workspaceActions.setSelectedItems(items));
    }

    const elementsUpdated = (itemIds) => {
        //console.log('Elements updated - items should be refreshed!', itemIds);
        setRefreshItems(itemIds);
    }

    return (
        <StorageLayout
            title="Manage items &amp; elements"
            onActiveStorageChanged={setActiveStorage}
            showFactoryList={true}
            showElementList={true}
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                <b>Items</b> are business objects. Examples: <i>product</i>, <i>service</i>, <i>feature</i>, <i>option</i>, <i>document</i>.
                <br /><b>Elements</b> are properties of business objects. Examples: <i>cost</i>, <i>price</i>, <i>identifier</i>.
            </div>
            <ItemSearch
                type={type}
                setSelectedRows={rowsSelected}
                refreshItems={refreshItems}
                onEntitiesRefreshed={() => setRefreshItems([])}
                msalInstance={msalInstance}
            />
            <ElementSearch
                type={type}
                onElementsUpdated={elementsUpdated}
                msalInstance={msalInstance}
            />
        </StorageLayout>
    );
}