import React, { useEffect } from 'react';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import EntityModalBase from './EntityModalBase';

const schema = yup.object().shape({
    name: yup.string().label('Category').required().max(100)
});

export default function CategoryModal({
    isOpen,
    toggle,
    status,
    selectedCategory,
    onEntityCreated,
    onEntityUpdated,
    msalInstance
}){
    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, clearErrors, trigger, setValue } = useForm({resolver: yupResolver(schema), mode: 'onChange'});

    let defaultValues = {
        id: 0,
        name:""
    };

    const handleNameChange = (input) => {
        clearErrors('submitError');
        trigger('submitError');
        setValue('name', input.target.value);
        trigger('name');
    }

    useEffect(() => {
        if (selectedCategory && selectedCategory.id > 0) {
            setValue('id', selectedCategory.id);
            setValue('name', selectedCategory.name);
        }
        else {
            setValue('id', 0);
            setValue('name', "");
        }
    }, [selectedCategory, setValue]);

    return(
        <EntityModalBase 
            isOpen={isOpen}
            toggle={toggle}
            status={status}
            titleBase="category"
            baseUrl="category"
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            entityIsCreated={onEntityCreated}
            entityIsUpdated={onEntityUpdated}
            msalInstance={msalInstance}
        >
            <Controller name="id"
                control={control}
                defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <FormGroup row className="required form-group">
                <Label for="name" sm={3}>Category</Label>
                <Col sm={9}>
                    <Controller name="name"
                        control={control}
                        defaultValue={defaultValues.name}
                        render={({ field }) =>
                            <Input
                                {...field}
                                type="text"
                                onChange={handleNameChange}
                            />
                        }
                    />
                    {errors.name && <p className="text-danger">{errors.name.message}</p>}
                </Col>
            </FormGroup>
        </EntityModalBase>
    );
}