import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { callAPI } from '../../../helpers/api';
import { useForm, Controller } from "react-hook-form";
import ModalBase from '../../common/modals/ModalBase';
import SimpleCustomTable from '../../common/SimpleCustomTable';
import { CustomSpinner } from '../../common/CustomSpinner';
import { FormGroup, Col, Row, Label, Button, Input, Container } from 'reactstrap';
import { CUSTOM_ELEMENT_TYPE } from '../.';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function LogSearchModal({
    isOpen,
    toggle,
    entityProperties,
    columns,
    user,
    msalInstance
}) {
    const countPerPage = 10;
    const properties = entityProperties ?? [];
    const selectedStorage = useSelector(state => state.workspace.activeItem);
    const selectedFactoryIds = useSelector(state => state.workspace.selectedFactories);
    const selectedElementTypeIds = useSelector(state => state.workspace.selectedElementTypes); //console.log('selectedFactoryIds, selectedElementTypeIds', selectedFactoryIds, selectedElementTypeIds);
    const factories = useSelector(state => state.workspace.factories);
    const elementTypes = useSelector(state => state.workspace.storageElementTypes);
    const extendedElementTypes = elementTypes?.length > 0 ? [...elementTypes, CUSTOM_ELEMENT_TYPE] : [CUSTOM_ELEMENT_TYPE];

    const { reset, setError, formState: { errors }, control, getValues, clearErrors, setValue } = useForm();
    const [revisions, setRevisions] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [loadMoreButtonDisabled, setLoadMoreButtonDisabled] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]); //console.log('dateRange', dateRange);
    const [startDate, endDate] = dateRange; //console.log('startDate, endDate', startDate, endDate);

    const defaultValues = {
        name: "",
        date: ""
    };

    const fetchElements = async (more) => {
        if (properties.baseUrl) {
            var page = more ? currentPage + 1 : 1;
            setCurrentPage(page);
            let url = properties.baseUrl + "search?storageId=" + selectedStorage.id
                + "&count=" + countPerPage + "&page=" + page;
            if (selectedFactoryIds?.length > 0) {
                url += "&factoryIds=" + selectedFactoryIds.join(';')
            }
            if (selectedElementTypeIds?.length > 0) {
                url += "&types=" + selectedElementTypeIds.join(';');
            }
            var userName = getValues("userName"); //console.log('form values', getValues());
            if (userName) {
                url += "&userName=" + userName;
            }
            if (startDate) {
                url += "&from=" + startDate.toDateString();
            }
            if (endDate) {
                url += "&to=" + endDate.toDateString();
            }
            const result = await callAPI(url, msalInstance, user);//console.log('result', result);
            const resultJson = result.json;
            if (result.errorMsg && !resultJson) {
                setError("getError", {
                    type: "manual",
                    message: result.errorMsg
                });
                throw new Error();
            }
            var elements = (resultJson && resultJson.parent && resultJson.revisions) ? resultJson.revisions : resultJson;
            //console.log('elements?.length', elements?.length);
            if (elements?.length > 0) {
                if (elements.length < countPerPage) {
                    setLoadMoreButtonDisabled(true);
                }
                else {
                    setLoadMoreButtonDisabled(false);
                }
            }
            else {
                setLoadMoreButtonDisabled(true);
            }
            return elements;
        }
    }

    const searchRevisions = async () => {
        if (isOpen) { //console.log('Get revisions url', url);
            clearErrors("getError");
            setIsLoading(true);
            setRevisions([]);
            try {
                var elements = await fetchElements(false);
                if (elements?.length > 0) {
                    setRevisions(elements); //console.log('elements', elements);
                }
                else {
                    console.log('Set error - no elements found!');
                    setError("getError", {
                        type: "manual",
                        message: "No elements found with given parameters."
                    });
                }
            }
            catch(error) {
                console.log(error);
            }
            finally {
                setIsLoading(false);
            }
        }
        
    }

    const loadMore = async () => {
        try {
            setLoadMoreLoading(true);
            var fetchedElements = await fetchElements(true);
            if (fetchedElements?.length > 0) {
                setRevisions([...revisions, ...fetchedElements]);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoadMoreLoading(false);
        }
    }

    const dateRangeChanged = (range) => {
        setDateRange(range);
        setValue('date', range);
    }

    const modalIsClosed = () => {
        reset();
        setRevisions([]);
    }

    const subTitle = <React.Fragment>
        {selectedStorage?.code}
        {selectedElementTypeIds?.length > 0 && <>
            &nbsp;({extendedElementTypes.filter(e => selectedElementTypeIds.includes(e.id)).map(e => e.code).join(', ')})
        </>}
        {selectedFactoryIds?.length > 0 && <>
            &nbsp;- <i>{factories.filter(f => selectedFactoryIds.includes(f.id)).map(f => f.code).join(', ')}</i>
        </>}
    </React.Fragment>

    useEffect(() => {
        reset();
        setDateRange([null, null]);
        if (isOpen) {
            searchRevisions();
        }
    }, [isOpen]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ModalBase
            isOpen={isOpen}
            toggle={toggle}
            title="Search"
            subTitle={subTitle}
            className="modal-xxl"
            onModalIsClosing={modalIsClosed}
        >
            <Row>
                <Col md={12} lg={9} xl={6}>
                    <Row>
                        <Col md={12} lg={6}>
                            <FormGroup row className="form-group">
                                <Label for="userName" sm={2}>Name</Label>
                                <Col sm={10}>
                                    <Controller
                                        name="userName"
                                        control={control}
                                        defaultvalue={defaultValues.name}
                                        render={({ field }) => <Input {...field} type="text" />}
                                    />
                                    {errors.name && <p className="text-danger">{errors.name.message}</p>}
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col md={12} lg={6}>
                            <FormGroup row className="form-group">
                                <Label for="date" sm={2}>Date</Label>
                                <Col sm={10}>
                                    <Controller
                                        name="date"
                                        control={control}
                                        defaultValue={dateRange}
                                        render={({ field }) => <DatePicker {...field}
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                dateRangeChanged(update);
                                            }}
                                            isClearable={true}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Start date - end date"
                                            autoComplete="off"
                                            maxDate={new Date()}
                                        />}
                                    />
                                    {errors.date && <p className="text-danger">{errors.date.message}</p>}
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col sm={3}>
                    <Button
                        color="primary"
                        className="me-1"
                        onClick={() => searchRevisions()}
                    >Search
                    </Button>
                </Col>
            </Row>
            {loading && <CustomSpinner />}
            {revisions.length > 0 ? <>
                <SimpleCustomTable
                    className="table-with-states"
                    tableColumns={columns}
                    tableData={revisions}
                    exportData={{ show: true, fileName: 'searchLogData' }}
                />
                <div className="d-flex justify-content-center">
                    {loadMoreLoading ? <CustomSpinner /> :
                        <Button
                            color="primary"
                            className="me-1"
                            disabled={loadMoreButtonDisabled}
                            onClick={() => loadMore()}
                        >Load more
                        </Button>
                    }
                </div>
            </> : <Container className="mt-5">&nbsp;</Container>}
            {errors.getError && <p className="text-danger h-15rem">{errors.getError.message}</p>}
        </ModalBase>
        )
}