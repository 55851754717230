import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import ModalBase from './ModalBase';

export default function ActionButtonModalBase({
    isOpen,
    toggle,
    title,
    subTitle,
    className,
    onClick,
    actionButtonText = "OK",
    actionButtonColor = "primary",
    onModalIsClosing,
    children,
    formIsValid = false,
    setNotLoading = 0
}) {
    const [loading, setIsLoading] = useState(false);
    //console.log('actionButtonText', actionButtonText);
    const actionButtonClicked = () => {
        setIsLoading(true);
        onClick();
    }

    const modalIsClosing = () => {
        setIsLoading(false);
        if (onModalIsClosing) {
            onModalIsClosing();
        }
    }

    const actionButton = <Button color={actionButtonColor}
        className="me-1"
        onClick={actionButtonClicked}
        disabled={!formIsValid}>{actionButtonText}</Button>;

    useEffect(() => {
        if (setNotLoading > 0) {
            setIsLoading(false); //console.log('setIsLoading', false);
        }
    }, [setNotLoading]);

    return (
        <ModalBase
            isOpen={isOpen}
            toggle={toggle}
            onModalIsClosing={modalIsClosing}
            title={title}
            subTitle={subTitle}
            actionButtons={actionButton}
            className={className}
            loading={loading}
        >
            {children}
        </ModalBase>
    )
}