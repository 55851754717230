import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormModalBase from '../../common/modals/FormModalBase';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { Action, UserStatus } from '../../../helpers/enums';
import { callAPI } from '../../../helpers/api';
import { getButtonText } from '../../../helpers/commonHelpers';
import StorageCheckboxList from '../StorageCheckBoxList';
import SimpleCustomTable from '../../common/SimpleCustomTable';
import { UserColumns } from '../Columns';

const schema = yup.object().shape({});

export default function AccessRightsModal({
    isOpen,
    toggle,
    selectedUsers,
    onUsersUpdated,
    msalInstance,
    currentUser
}) {
    const [selectedStorages, setSelectedStorages] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [submitted, setSubmitted] = useState(0);
    const [resetStates, setResetStates] = useState(false);

    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, setValue } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    const onSubmit = async (data) => {
        let method = 'PUT';
        data.statusId = isActive ? UserStatus.ACTIVE : UserStatus.DISABLED;
        data.storageOwnerIds = selectedStorages;
        let result = await callAPI("user", msalInstance, currentUser, data, method);
        let updatedUserData = result.json;
        if (result.errorMsg && !updatedUserData) {
            setError("editError", {
                type: "manual",
                message: result.errorMsg
            });
        }
        else {
            if (onUsersUpdated) {
                onUsersUpdated(updatedUserData);
            }
            toggle();
            modalIsClosed();
        }
        setSubmitted(submitted + 1);
    }

    const isActiveChanged = (e) => {
        var isChecked = e.target.checked;
        setIsActive(isChecked);
        setValue("active", isChecked);
        setResetStates(!isChecked)
    }

    const modalIsClosed = () => {
        reset();
    }

    let defaultValues = {
        ids: selectedUsers ? selectedUsers.map(u => u.id) : ""
    };

    useEffect(() => {
        //console.log('selectedUsers', selectedUsers);
        if (selectedUsers?.length > 0) {
            if (selectedUsers.length === 1) {
                const user = selectedUsers[0];
                setSelectedStorages(user.storageOwnerIds);
                if (user.statusId === UserStatus.ACTIVE) {
                    setIsActive(true);
                }
                else {
                    setIsActive(false);
                }
            }
            else {
                setSelectedStorages([]);
                setIsActive(true);
            }
            setValue('ids', selectedUsers.map(u => u.id));
        }
        else {
            setSelectedStorages([]);
            setIsActive(false);
            setValue('ids', "");
        }
        if (!isOpen) {
            modalIsClosed();
        }
    }, [selectedUsers, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const EditUsers = ({ users }) => {
        if (!users) {
            return ("");
        }

        if (users.length === 1) {
            const user = users[0];
            return (
                <React.Fragment>
                    <FormGroup row>
                        <Label for="name" sm={3}>Name</Label>
                        <Col sm={9}>
                            <span className="input-group-text input-disabled">{user.name}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="name" sm={3}>Email</Label>
                        <Col sm={9}>
                            <span className="input-group-text input-disabled">{user.email}</span>
                        </Col>
                    </FormGroup>
                </React.Fragment>
            );
        }

        return (
            <SimpleCustomTable
                tableColumns={UserColumns()}
                tableData={selectedUsers}
                className="table-with-states"
            />
        );
    }

    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={Action.EDIT + " user(s)"}
            className={selectedUsers?.length > 1 ? "modal-lg" : ""}
            onSubmit={handleSubmit(data => onSubmit(data))}
            actionButtonText={getButtonText(Action.EDIT)}
            formIsValid={isValid}
            onModalIsClosing={modalIsClosed}
            setNotLoading={submitted}
        >
            <Controller name="ids" control={control} defaultValue={defaultValues.ids}
                render={({ field }) => <Input {...field} type="hidden" />}
            />
            <EditUsers users={selectedUsers} />
            <FormGroup row>
                <Label for="active" sm={selectedUsers?.length > 1 ? 2 : 3}>Active</Label>
                <Col sm={selectedUsers?.length > 1 ? 10 : 9}>
                    <Controller name="active" control={control} defaultValue={isActive}
                        render={({ field }) => <Input {...field} type="checkbox" onChange={isActiveChanged} checked={isActive} />}
                    />
                </Col>
            </FormGroup>
            <div className="border-bottom mb-2 mt-4">
                <h4>Storage owner</h4>
            </div>
            <StorageCheckboxList
                selectedStorageIds={selectedStorages}
                onStorageSelected={setSelectedStorages}
                resetStates={resetStates}
                disabled={!isActive}
            />
            {errors.editError && <p className="text-danger">{errors.editError.message}</p>}
        </FormModalBase>
    );
}