import React from 'react';
import { useSelector } from 'react-redux';
import SiteLayout from '../common/SiteLayout';
import { subMenuItems } from './';
import { getSubMenuItems } from '../../helpers/commonHelpers';
import { Container } from 'reactstrap';
import { NotAuthorized } from '../common/NotAuthorized';

export default function AdminLayout({
    title,
    subTitle,
    sideMenu,
    requiredRole,
    children
}) {
    let currentUser = useSelector(state => state.application.user);
    let isUnauthorize = useSelector(state => state.application.userUnauthorized);
    const menuItemsForUser = getSubMenuItems(subMenuItems, currentUser.highestRole);

    if (!currentUser.highestRole || currentUser.highestRole < requiredRole || isUnauthorize) {
        return (
            <SiteLayout subMenuItems={menuItemsForUser}>
                <NotAuthorized />
            </SiteLayout>
        );
    }

    return (
        <SiteLayout
            sideMenu={sideMenu}
            subMenuItems={menuItemsForUser}
        >
            <Container fluid={true} className="mt-1 mb-3">
                <h1>
                    {title}
                    {subTitle?.length > 0 && <span className="heading-info ms-2">{subTitle}</span>}
                </h1>
            </Container>
            { children }
        </SiteLayout>
    );
}