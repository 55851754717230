import React from 'react';
import { NavLink as RRNavLink, useLocation } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { useDispatch } from 'react-redux';

export function CustomNavLink({
	to,
	title,
	imgSrc,
	baseRoute,
	dispatchFunction,
}) {
	const location = useLocation();
	const dispatch = useDispatch();
	const className = location.pathname.includes("/" + baseRoute + "/") ? "active" : "";

	function doDispatch() {
		dispatch(dispatchFunction([]));
	}

	return (
		<NavItem>
			<NavLink tag={RRNavLink} to={to} className={className} onClick={() => doDispatch()}>
				{imgSrc && <>
					<img src={imgSrc} className="nav-icon" alt="" />
					<br />
				</>}
				{title}
			</NavLink>
		</NavItem>
	)
}