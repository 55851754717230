import React, { useEffect, useImperativeHandle, forwardRef, useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { fuzzyMultipleFn } from './ColumnFilters';
import CustomPaging from './CustomPaging';
import { getLocalStorageValue } from '../../helpers/localStorageHelpers';
import ExportData from '../common/ExportData';
import { getDefaultPageSize, getHiddenColumnsObject, getCheckboxColumnDef, CustomTable } from '../../helpers/tableHelpers';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

const CustomTableWithFilters = forwardRef(({
    tableColumns,
    tableData,
    className,
    onSelectedChanged,
    hiddenColumns,
    pageSizes,
    localStorageKey,
    showPagination = true,
    size = "sm",
    onRowCountChanged,
    globalFilterValue = "",
    rowSelectionDisabled = false,
    useRowSelection = true,
    showColumnFilters = true
}, ref) => {
    const [data, setData] = useState(() => []); //console.log('rowSelectionDisabled, useRowSelection, tableData', rowSelectionDisabled, useRowSelection, tableData);
    const [columns] = useState(() => {
        if (useRowSelection) {
            return [getCheckboxColumnDef(tableData.some(d => d.disabledSelect) || rowSelectionDisabled, rowSelectionDisabled), ...tableColumns];
        }
        return [...tableColumns];
    });

    const hidden = getHiddenColumnsObject(hiddenColumns); //console.log('hidden', hidden);
    const [columnVisibility, setColumnVisibility] = useState(hidden);
    const [rowSelection, setRowSelection] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: getLocalStorageValue(localStorageKey, getDefaultPageSize(pageSizes))
    });
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [globalFilter, setGlobalFilter] = React.useState('');

    const tableWithSelection = useRowSelection ? "table-with-selection" : "";
    const tableClasses = className ? className + " mt-3 " + tableWithSelection : "mt-3 " + tableWithSelection;
    
    const filterChanged = useCallback((value) => {
        //console.log('filterChanged', value, table);
        table.toggleAllRowsSelected(false); // Unselect all rows
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            columnVisibility,
            rowSelection,
            pagination,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: (row, columnId, filterValue) => {
            const filterFunction = table.getColumn(columnId).columnDef.filterFn; //console.log('columnDef', columnDef)
            //console.log({ row, columnId, filterValue });
            if (typeof filterFunction === 'function') {
                return filterFunction(row, columnId, filterValue);
            }
            else {
                return fuzzyMultipleFn(row, columnId, filterValue);
            }
        },
        onColumnVisibilityChange: setColumnVisibility,
        //enableRowSelection: true, //enable row selection for all rows
        enableRowSelection: row => !row.original.disabledSelect,
        getRowId: row => row.id,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        autoResetPageIndex: false, //turn off auto reset of pageIndex
    }); //console.log('columnVisibility, table', columnVisibility, table, table.getVisibleFlatColumns());
    
    // return table instance
    useImperativeHandle(ref, () => table); //console.log('table', table);

    useEffect(() => {
        const rowCount = table.getRowCount();
        //console.log('row count changed', rowCount);
        if (onRowCountChanged) {
            onRowCountChanged(rowCount);
        }
        // Let's check that the last item in last page was not deleted which would mean an empty page is shown
        // Let's also take filtering account so instead of checking the tableData count let's check the table row count!
        const pagination = table.getState().pagination;
        if (pagination.pageIndex > 0 && rowCount > 0 && pagination.pageIndex * pagination.pageSize >= rowCount) {
            table.setPageIndex(pagination.pageIndex - 1);
        }
    }, [table.getRowCount()]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Let's move to the first page
        setPagination({ ...pagination, pageIndex: 0 }); //console.log('Move to first page!', table, table.getRowCount());
    }, [columnFilters, globalFilter]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log('row selection changed', rowSelection);
        if (onSelectedChanged) {
            onSelectedChanged(Object.keys(rowSelection).map(Number));
        }
    }, [rowSelection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log('tableData changed', tableData, table.getRowCount(), table.getState()); //console.log('table.getPageCount()', table.getPageCount());
        setData([...tableData]);
    }, [tableData, table]);

    useEffect(() => {
        //console.log('hiddenColumns', hiddenColumns);
        if (hiddenColumns) {
            setColumnVisibility(() => getHiddenColumnsObject(hiddenColumns));
        }
        else {
            setColumnVisibility([]);
        }
    }, [hiddenColumns]);

    useEffect(() => {
        setGlobalFilter(String(globalFilterValue)); //console.log('table.getState().globalFilter', table.getState().globalFilter, table.getRowCount())
        //Let's move to the first page
        table.setPageIndex(0);
    }, [globalFilterValue, table]);

    if (!data || data.length === 0) { return (<></>); }
    
    return (
        <>
            <Col lg={12}>
                {/*<pre>{JSON.stringify({ filters }, null, 2)}</pre>*/}
                {/*<pre>*/}
                {/*    <code>*/}
                {/*        {JSON.stringify(*/}
                {/*            {*/}
                {/*                rowSelection*/}
                {/*            },*/}
                {/*            null,*/}
                {/*            2*/}
                {/*            )} */}
                {/*    </code>*/}
                {/*</pre>*/}
                <CustomTable
                    table={table}
                    render={flexRender}
                    tableClasses={tableClasses}
                    size={size}
                    showColumnFilters={showColumnFilters}
                    filterChanged={filterChanged}
                />
            </Col>
            <Row>
                <Col xs={11}>
                    {showPagination && data.length > 0 &&
                        <CustomPaging
                            table={table}
                            pageSizes={pageSizes}
                            pageKey={localStorageKey}
                        />
                    }
                </Col>
                <Col xs={1} className="d-flex justify-content-end">
                    <ExportData rows={table.getRowModel().rows}/>
                </Col>
            </Row>
            {/*<pre>
                <code>
                    {JSON.stringify(
                        {selectedRowIds: selectedRowIds,
                            'selectedRows': selectedFlatRows.map(
                              d => d.original
                            ),
                        },
                        null,
                        2
                    )}
                </code>
            </pre> */}
        </>
    );
});

export default CustomTableWithFilters;