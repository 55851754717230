import { ActionTypes } from "../helpers/enums";

const defaultValues = {
    user: {},
    loggedIn: false,
    userUnauthorized: false,
    environment: "",
    flags: []
}

const application = (state = defaultValues, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER:
            return {
                ...state,
                user: action.payload,
                loggedIn: action.payload && action.payload.authUser ? true : false
            }
        case ActionTypes.SET_USER_UNAUTHORIZED:
            return {
                ...state,
                userUnauthorized: action.payload
                }
        case ActionTypes.SET_ENVIRONMENT:
            return {
                ...state,
                environment: action.payload
            }
        case ActionTypes.SET_APPLICATION_FLAGS:
            return {
                ...state,
                flags: action.payload
            }
        default: return state;
    }
}

export default application;