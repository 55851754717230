import React, { forwardRef } from "react";
import Select from 'react-select';

export const CustomSelect = forwardRef(
    (props, ref) => {
        //console.log('props', props);
        return (
            <Select {...props} isClearable menuPortalTarget={document.body}
                styles={customStyles} menuShouldBlockScroll={true} />
        );
    });

export const SelectWithStyles = forwardRef(
    (props, ref) => {
        const formatOptionLabel = (data) => {
            if (!data) {
                return (<span></span>);
            }

            if (data.symbolName) {
                return (
                    <span>
                        <span className="me-1">{data.symbolName} {data.label}</span>
                        {data.subLabel &&
                            <span className="secondaryLabel"> ({data.subLabel})</span>
                        }
                    </span>
                )
            }

            return (
                <span>
                    {data.label}
                    {data.subLabel &&
                        <span className="secondaryLabel"> ({data.subLabel})</span>
                    }
                </span>
            );
        }

        if (props.styles) {
            return (<Select {...props}
                formatOptionLabel={formatOptionLabel}
                menuShouldBlockScroll={true}
                maxMenuHeight={200}
            />)
        }

        return (
            <Select {...props}
                formatOptionLabel={formatOptionLabel}
                menuPortalTarget={document.body}
                styles={customStyles}
                menuShouldBlockScroll={true}
                maxMenuHeight={200}
                //menuIsOpen={true}
                classNamePrefix="custom_dropdown"
            />
        )
    }
)

const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (styles) => ({
        ...styles,
        fontSize: '0.9rem'
    }),
    control: (styles, state) => ({
        ...styles,
        backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
        outlineStyle: state.isFocused ? 'solid !important' : 'none !important',
        outlineColor: state.isFocused ? '#BDCAF4 !important' : 'transparent !important',
        outlineWidth: state.isFocused ? '2px !important' : '1px !important',
        borderStyle: 'solid !important',
        borderColor: '#CED4DA !important',
        borderWidth: '1px !important',
    }),
    placeholder: styles => ({
        ...styles,
        color: '#334334'
    }),
    indicatorSeparator: styles => ({
        ...styles,
        backgroundColor: '#ced4da'
    }),
    indicatorsContainer: styles => ({
        ...styles,
        color: '#ced4da'
    }),
    singleValue: styles => ({
        ...styles,
        color: '#334334'
    }),
}