import React, { useState, useEffect } from 'react';
import { Form, Button } from 'reactstrap';
import ModalBase from './ModalBase';

export default function FormModalBase({
    isOpen,
    toggle,
    title,
    subTitle,
    className,
    onSubmit,
    additionalButton,
    actionButtonText = "Save",
    actionButtonColor = "primary",
    onModalIsClosing,
    children,
    formIsValid = false,
    disabled = false,
    headerClassName = "text-white",
    setNotLoading = 0
}) {
    const [loading, setIsLoading] = useState(false);
    const formId = title.replaceAll(" ", "_") + "_modal";
    //console.log('loading', loading, formId);
    //console.log('actionButtonText', actionButtonText);

    const formIsSubmitting = (event) => {
        event.preventDefault();
        setIsLoading(true);
        onSubmit();
    }

    const onToggle = () => {
        setIsLoading(false);
        toggle();
    }

    const modalIsClosing = () => {
        setIsLoading(false); //console.log('onModalIsClosing', onModalIsClosing);
        if (onModalIsClosing) {
            onModalIsClosing();
        }
    }

    const submitButton = <Button color={actionButtonColor}
        type="submit"
        className="me-1"
        form={formId}
        disabled={!formIsValid || loading}>{actionButtonText}</Button>;

    const actionButtons = additionalButton ?
        <>
            {submitButton}
            {additionalButton}
        </> : submitButton

    useEffect(() => {
        if (setNotLoading > 0) {
            setIsLoading(false);
        }
    }, [setNotLoading]);

    return (
        <ModalBase
            isOpen={isOpen}
            toggle={onToggle}
            onModalIsClosing={modalIsClosing}
            title={title}
            subTitle={subTitle}
            actionButtons={disabled ? null : actionButtons}
            className={className}
            loading={loading}
            headerClassName={headerClassName}
        >
            {disabled ? 
                <fieldset disabled={true}>
                    {children}
                </fieldset> :
                <Form onSubmit={formIsSubmitting} autoComplete="off" id={formId}>
                    {children}
                </Form>
            }
        </ModalBase>
    )
}