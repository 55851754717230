import React, { useState } from 'react';
import FormModalBase from '../../common/modals/FormModalBase';
import { Action } from '../../../helpers/enums';
import { useForm, } from "react-hook-form";
import ModalBase from '../../common/modals/ModalBase';
import { callDeleteAPI } from '../../../helpers/api';

export default function DeleteQueryModal({
    id,
    isOpen,
    toggle,
    onQueryDeleted,
    user,
    msalInstance,
    children
}) {
    const deleteTitle = Action.DELETE + " query";
    const [submitted, setSubmitted] = useState(0);
    const { handleSubmit, setError, formState: { errors } } = useForm();

    const onSubmit = async() => {
        //console.log('Delete query', data);
        const result = await callDeleteAPI('query/' + id, msalInstance, user, null, 'DELETE');
        if (result.errorMsg && !result.json) {
            setError("deleteError", {
                type: "manual",
                message: result.errorMsg
            });
        }
        else {
            if (onQueryDeleted) {
                onQueryDeleted(id);
            }
        }
        toggle();
        setSubmitted(submitted + 1);
    }

    if (!(id > 0)) {
        return (
            <ModalBase
                isOpen={isOpen}
                toggle={toggle}
                title={deleteTitle}
                subTitle="Not found!"
            >
                <p>No valid id found. Please check!</p>
            </ModalBase>)
    }

    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={deleteTitle}
            actionButtonColor="danger"
            onSubmit={handleSubmit(data => onSubmit(data))}
            actionButtonText={Action.DELETE}
            formIsValid={id > 0}
            headerClassName="text-danger"
            setNotLoading={submitted}
        >
            {children}
            {errors.deleteError && <p className="text-danger">{errors.deleteError.message}</p>}
        </FormModalBase>
    );
}