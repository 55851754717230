import React, { useState } from 'react';
import { Container, Form, FormGroup, Col, Row, Label, Button, ButtonToolbar, Input } from 'reactstrap';
import { useForm,  Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { searchABBUsers } from '../../helpers/graphService';
import SimpleCustomTable from '../common/SimpleCustomTable';
import { ADUserColumns } from './Columns';
import UserModal from './modals/UserModal';
import { NotAuthorized } from '../common/NotAuthorized';
import { isAdmin } from '../../helpers/commonHelpers';
import { Info } from '../common/Info';
import { getActionsColumnDef } from '../../helpers/tableHelpers';

const schema = yup.object().shape({
    name: yup.string().label('Name').required().min(3).max(100)
});

export default function UserForm({ msalInstance }) {
    const [graphData, setGraphData] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchMsg, setSearchMsg] = useState(null);

    let currentUser = useSelector(state => state.application.user);

    const formId = "search_user";
    const { handleSubmit, formState: { errors, isValid }, control } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });
    
    let defaultValues = {
        id: 0,
        name: ""
    };

    const onSubmit = async (data) => {
        setSearchMsg(null);
        setGraphData([]);
        //console.log('Search from Azure AD!', data);
        const graphResponse = await searchABBUsers(data.name, msalInstance, currentUser.authUser); //console.log('graph response', graphResponse);
        if (graphResponse && graphResponse.totalCount > 0) {
            setGraphData(graphResponse.users);
            const userCount = graphResponse.users.length;
            if (userCount < graphResponse.totalCount && !graphResponse.allFetched) {
                setSearchMsg('Showing first ' + userCount + ' matching users with criteria "' + data.name + '".');
            }
            else {
                setSearchMsg('Found ' + userCount + ' matches with criteria "' + data.name + '".');
            }
        }
        else {
            setSearchMsg('No results found!');
        }
    }

    const toggleModal = (selectedUser) => {
        //console.log('toggle modal', selectedUser);
        const modalIsAboutToOpen = !showModal;
        
        if (selectedUser && modalIsAboutToOpen) {
            setSelectedUser(selectedUser);
        }
        setShowModal(modalIsAboutToOpen);
    }

    const cellFn = (original) => {
        return (<ButtonToolbar>
            <Button
                color="primary"
                className="ms-3 me-1 btn-xs"
                title="Add as RUCA user"
                onClick={() => toggleModal(original)}
            >
                <img className="icon" src="./icons/button_create.svg" alt="" />
            </Button>
        </ButtonToolbar>);
    }
    const columnsWithActions = [...ADUserColumns(), getActionsColumnDef(cellFn)];

    if (!isAdmin(currentUser)) {
        return (<NotAuthorized />);
    }

    return (
        <React.Fragment>
            <Container fluid={true}>
                <Form autoComplete="off" id={formId} onSubmit={handleSubmit(data => onSubmit(data))} className="mt-2">
                    <Col xs={8} sm={5} lg={4} xl={3}>
                        <Row>
                            <Col>
                                <h2>Add user</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="required form-group">
                                    <Label for="name">Name or email</Label>
                                    <Col>
                                        <Controller name="name" control={control} defaultValue={defaultValues.name}
                                            render={({ field }) => <Input {...field} type="text" />}
                                        />
                                            {errors.name && <p className="text-danger">{errors.name.message}</p>}
                                    </Col>
                                    <ButtonToolbar className="mt-3">
                                        <Button color="primary" type="submit" className="me-1" form={formId} disabled={!isValid}>Search</Button>
                                    </ButtonToolbar>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            </Container>
            {searchMsg &&
                <Container fluid={true}>
                    <Col lg={12} xl={9}>
                        <Info msg={searchMsg} />
                    </Col>
                </Container>
            }
            <Container fluid={true}>
                {graphData && graphData.length > 0 &&
                    <Col lg={12} xl={9}>
                        <SimpleCustomTable
                            tableColumns={columnsWithActions}
                            tableData={graphData}
                            />
                    </Col>
                }
            </Container>
            <UserModal
                isOpen={showModal}
                toggle={toggleModal}
                selectedUser={selectedUser}
                msalInstance={msalInstance}
                user={currentUser.authUser}
            />
        </React.Fragment>
    );
}