import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button, ButtonToolbar } from 'reactstrap';
import DeleteModal from '../common/modals/DeleteModal';
import { CustomSpinner } from '../common/CustomSpinner';
import { callAPI } from '../../helpers/api';
import { Error } from '../common/Error';
import { getEntityProperties } from '../common/EntityProperties';
import { EntityType } from '../../helpers/enums';

export default function AdminFormBase({
    entityType,
    setActiveAction,
    setEntitiesAction,
    onEntityAdded,
    formIsValid,
    errors,
    setError,
    reset,
    handleSubmit,
    setFormValues,
    children,
    msalInstance,
    resetErrors = false
}) {
    const [showModal, setShowModal] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState({});
    const [loading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const entityProperties = getEntityProperties(entityType);

    let user = useSelector(state => state.application.user);
    let entityList = useSelector(state => state[entityProperties.section].list);
    let activeEntity = useSelector(state => state[entityProperties.section].activeItem);//console.log('activeEntity', activeEntity);

    const toggle = () => {
        setShowModal(!showModal);
    }

    const entityIsDeleted = (entities) => {
        //console.log('entity is deleted', entities);
        if (entities?.length > 0) {
            dispatch(setActiveAction({}));
            dispatch(setEntitiesAction(entityList.filter(s => !entities.map(i => i.id).includes(s.id))));
        }
    }

    const onSubmit = async (data) => {
        setIsLoading(true); //console.log('onSubmit entity', data);
        data.code = data.code.replace(/\s/g, '_').toUpperCase();
        // Are we adding a new entity or updating existing one?
        let method = 'POST';
        if (activeEntity.id) {
            method = 'PUT';
        }

        let result = await callAPI(entityProperties.baseUrl, msalInstance, user ? user.authUser : {}, data, method);
        //console.log('callAPI data', result);
        if (result.errorMsg && !result.json) {
            setError("formSubmit", {
                type: "manual",
                message: result.errorMsg
            });
        }
        else {
            var resultEntity = result.json;
            if (!activeEntity.id) {
                // Entity added
                if (onEntityAdded) {
                    onEntityAdded();
                }
                dispatch(setEntitiesAction([...entityList, resultEntity]));
            }
            else {
                // Entity updated
                const index = entityList.findIndex(s => s.id === resultEntity.id);
                if (index >= 0) {
                    entityList[index].name = resultEntity.name;
                    entityList[index].code = resultEntity.code;
                    if (entityType === EntityType.FACTORY) {
                        entityList[index].factorySymbol = resultEntity.factorySymbol;
                    }
                    dispatch(setEntitiesAction(entityList));
                }
            }
            dispatch(setActiveAction(resultEntity));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        reset();//console.log('activeEntity', activeEntity);
        if (activeEntity.id > 0) {
            setDeletedEntity({});
        }
        if (setFormValues) {
            setFormValues(activeEntity);
        }
    }, [activeEntity.id]);// eslint-disable-line react-hooks/exhaustive-deps

    if (deletedEntity.id && !resetErrors) {
        return (<Error msg={"Deleted " + entityProperties.single + " '" + deletedEntity.code + ", " + deletedEntity.name + "'!"}></Error>)
    }

    return (
        <Container fluid={true} className="mt-3">
            <Col xs={8} sm={5} lg={4} xl={3}>
                {loading ? <CustomSpinner margin={false} /> :
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <h2>General</h2>
                            </Col>
                        </Row>
                        {children}
                        <ButtonToolbar className="mt-3 mb-5">
                            <Button
                                color="primary"
                                type="submit"
                                className="me-1"
                                disabled={!formIsValid}>
                                {activeEntity.id ? "Save" : "Create"}
                            </Button>
                            {activeEntity.id && <Button color="danger" onClick={toggle}>Delete</Button>}
                        </ButtonToolbar>
                        {errors.submitError && <p className="text-danger">{errors.submitError.message}</p>}
                    </Form>
                }
            </Col>
            <DeleteModal
                isOpen={showModal}
                toggle={toggle}
                entityProperties={entityProperties}
                deleteIds={[activeEntity.id]}
                onDeleted={entityIsDeleted}
                msalInstance={msalInstance}
                user={user ? user.authUser : {}}
            >
                <p>Are you sure you want to delete {entityProperties.single}</p>
                <p className="fw-bold">{activeEntity.code}, {activeEntity.name}</p>
            </DeleteModal>
        </Container>
    )
}