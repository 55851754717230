import React from 'react';
import { ButtonToolbar, Button } from 'reactstrap';
import { Action } from '../../helpers/enums';
import { hasRoleForStorageAndFactory, hasContributorRole } from '../../helpers/commonHelpers';
import { useSelector } from 'react-redux';

export default function ButtonRowForTable({
    type,
    buttons,
    selectedEntity
}) {
    let currentUser = useSelector(state => state.application.user);
    let selectedStorage = useSelector(state => state[type].activeItem);

    const selectedStorageId = selectedStorage ? selectedStorage.id : 0;

    if (!buttons || buttons.length === 0 || !selectedEntity) {
        return (<></>);
    }

    const buttonIsDisabled = (entity, requiredRole) => {
        // Button is disable if related entity is global or a configuration entity or user does not have privileges.
        //console.log('requiredRole & entity', requiredRole, entity);
        if (requiredRole) {
            return entity.isGlobal || entity.isConfig || !hasRoleForStorageAndFactory(currentUser, requiredRole, selectedStorageId, entity.factoryId);
        }

        return entity.isGlobal || entity.isConfig;
    }

    const editButton = buttons.find(b => b.action === Action.EDIT); //console.log('edit button for', selectedEntity, editButton, selectedStorageId, selectedEntity.factoryId);
    const viewButton = buttons.find(b => b.action === Action.VIEW);
    const copyButton = buttons.find(b => b.action === Action.COPY);
    const flagsButton = buttons.find(b => b.action === Action.FLAG);
    const deleteButton = buttons.find(b => b.action === Action.DELETE);
    const logButton = buttons.find(b => b.action === Action.LOG);

    return (
        <ButtonToolbar>
            {
                // If edit  button is disable a view button is shown instead.
                editButton && !buttonIsDisabled(selectedEntity, editButton.requiredRole, selectedStorageId, selectedEntity.factoryId) ?
                    <Button color="primary"
                        className="me-1 btn-xs"
                        title="Edit"
                        onClick={() => editButton.onClick(Action.EDIT, selectedEntity)}
                        disabled={selectedEntity.isGlobal}
                    >
                        <img className="icon" src="./icons/button_edit.svg" alt=""/>
                    </Button>
                    :
                    <Button color="primary"
                        className="me-1 btn-xs"
                        title="View"
                        onClick={() => viewButton.onClick(Action.VIEW, selectedEntity)}
                    >
                        <img className="icon" src="./icons/button_view.svg" alt="" />
                    </Button>
            }
            {copyButton && hasContributorRole(currentUser, selectedStorageId, selectedEntity.factoryId) &&
                <Button color="primary"
                    className="me-1 btn-xs"
                    title="Copy"
                    onClick={() => copyButton.onClick(Action.COPY, selectedEntity)}
                    disabled={buttonIsDisabled(selectedEntity)}
                >
                    <img className="icon" src="./icons/button_copy.svg" alt="" />
                </Button>
            }
            {flagsButton && 
                <Button color="primary"
                    className="btn-xs me-1"
                    title="Flags"
                    onClick={() => flagsButton.onClick(selectedEntity)}
                >
                    <img className="icon" src="./icons/button_flag.svg" alt="" />
                </Button>
            }
            {deleteButton && hasContributorRole(currentUser, selectedStorageId, selectedEntity.factoryId) &&
                <Button color="danger"
                    className="btn-xs me-1"
                    title="Delete"
                    onClick={() => deleteButton.onClick(selectedEntity)}
                    disabled={selectedEntity.references > 0 || buttonIsDisabled(selectedEntity)}
                >
                    <img className="icon" src="./icons/button_delete.svg" alt="" />
                </Button>
            }
            {logButton &&
                <Button color="primary"
                    className="btn-xs me-1"
                    title="Log"
                    onClick={() => logButton.onClick(selectedEntity)}
                >
                    <img className="icon" src="./icons/button_log.svg" alt="" />
                </Button>
            }
        </ButtonToolbar>
    );
}