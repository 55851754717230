import React from 'react';
import { Navbar, Nav } from 'reactstrap';
import workspaceActions from '../actions/workspaceActions';
import { CustomNavLink } from '../components/common/CustomNavLink';

const SubMenu = ({ menuItems }) => {
    
    return (
        <Navbar className="navbar-sub mb-3 bg-dark" expand="sm" dark>
            <Nav className="me-auto" navbar>
                {menuItems.map((item, index) => (
                    <CustomNavLink
                        key={index}
                        to={item.to}
                        title={item.name}
                        imgSrc={item.icon}
                        dispatchFunction={workspaceActions.setSelectedItems}
                    />
                ))}
            </Nav>
        </Navbar>
    );
}

export default SubMenu;
