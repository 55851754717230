import { Button } from 'reactstrap';

export default function ExportData({
    rows,
    fileName = 'data'
}) {

    const download = () => {
        //console.log('Donwload rows', rows);
        const jsonData = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(rows.map(r => r.original), null, 4)
        )}`;
        const link = document.createElement("a");
        link.href = jsonData;
        link.download = fileName + ".json";

        link.click();
    }

    return (
        <Button color="link" onClick={download} className="mb-3 align-self-start" title="Download filtered data in json format">
            <img src="./icons/download.svg" className="icon icon-export dark" alt="" />
        </Button>
    );
}