import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, FormGroup, Label, Row, Col, Input, ButtonToolbar, Button } from 'reactstrap';
import { Action, Role } from '../../helpers/enums';
import SimpleCustomTable from '../common/SimpleCustomTable';
import { UserRoleColumns } from './Columns';
import FactoryRoleModal from './modals/FactoryRoleModal';
import { getActionsColumnDef } from '../../helpers/tableHelpers';

export default function StorageFactoryRoles({
    roles,
    isStorageOwner,
    setIsStorageOwner,
    setRoles
}) {
    let selectedStorage = useSelector(state => state.adminStorages.activeItem);
    let allStorageFactories = useSelector(state => state.adminStorages.storageFactories);
    let storageFactories = allStorageFactories.filter(f => f.isSelected);

    const [selectedRole, setSelectedRole] = useState(null);
    const [modalStatus, setModalStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const toggleModal = (role, status) => {
        setSelectedRole(role);
        setModalStatus(status);
        setShowModal(!showModal);
    }

    const isOwnerChanged = (e) => {
        var isChecked = e.target.checked;
        setIsStorageOwner(isChecked);
        setRoles([]);
    }

    const roleAdded = (roleData) => {
        let selectedFactory = storageFactories.filter(f => f.id === roleData.factory.value);
        setRoles([...roles, {
            storageId: selectedStorage.id,
            factoryId: roleData.factory.value,
            factory: selectedFactory && selectedFactory.length > 0 ? selectedFactory[0].code : "",
            role: roleData.role,
            publishingRole: roleData.publishingRole
        }]);
    }

    const roleUpdated = (role) => {
        let newRoles = mapRoles(roles);
        const index = newRoles.findIndex(r => r.factoryId === role.factory.value);
        if (index >= 0) {
            let updatedRole = newRoles[index];
            updatedRole.role = role.role;
            updatedRole.publishingRole = role.publishingRole;
            setRoles(newRoles);
        }
    }

    const roleDeleted = (role) => {
        let newRoles = mapRoles(roles);
        setRoles(newRoles.filter(r => r.factoryId !== role.factoryId));
    }

    const mapRoles = (roleArray) => {
        return roleArray.filter(r => r.role !== Role.NONE);
    }

    const cellFn = (original) => {
        return (<ButtonToolbar>
            <Button
                color="primary"
                className="ms-3 me-1 btn-xs"
                title="Edit"
                onClick={() => toggleModal(original, Action.CHANGE)}
            >
                <img className="icon" src="./icons/button_edit.svg" alt="" />
            </Button>
            <Button
                color="danger"
                className="ms-3 me-1 btn-xs"
                title={"Delete user right for factory " + original.code}
                onClick={() => roleDeleted(original)}
            >
                <img className="icon" src="./icons/button_delete.svg" alt="" />
            </Button>
        </ButtonToolbar>);
    }
    const columnsWithActions = [...UserRoleColumns(), getActionsColumnDef(cellFn)];

    return (
        <React.Fragment>
            <FormGroup row>
            <Label for="active" sm={3}>Is owner</Label>
            <Col sm={9}>
                <Input type="checkbox" onChange={isOwnerChanged} checked={isStorageOwner} />
            </Col>
        </FormGroup>
            {!isStorageOwner &&
                <React.Fragment>
                    <Container fluid={true} className="mt-4">
                        <Row className="border-bottom mb-2">
                            <Col sm={3} className="ps-0">
                                <h4 className="mt-2">Factory roles</h4>
                            </Col>
                            <Col sm={9}>
                                <ButtonToolbar>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="me-1"
                                        onClick={() => toggleModal(null, Action.ADD)}
                                        disabled={!storageFactories || storageFactories.length === 0}>
                                        Add
                                    </Button>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </Container>
                    <SimpleCustomTable
                        tableColumns={columnsWithActions}
                        tableData={roles}
                        className="table-with-states"
                    />
                    <FactoryRoleModal
                        isOpen={showModal}
                        toggle={toggleModal}
                        status={modalStatus}
                        existingRoles={roles}
                        factoryRole={selectedRole}
                        onRoleAdded={roleAdded}
                        onRoleUpdated={roleUpdated}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    );
}