import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import FormModalBase from '../../common/modals/FormModalBase';
import { Action } from '../../../helpers/enums';
import { useSelector } from 'react-redux';
import FlagModal from './FlagModal';
import { Col, ButtonToolbar, Button } from 'reactstrap';
import SimpleCustomTable from '../../common/SimpleCustomTable';
import { FlagColumns } from '../Columns';
import { callAPI, callDeleteAPI } from '../../../helpers/api';
import { getActionsColumnDef } from '../../../helpers/tableHelpers';

const getFlags = (applicationFlags, itemFlags) => {
    if (!itemFlags) {
        return [];
    }

    if (!applicationFlags) {
        return itemFlags;
    }

    return itemFlags.map(i => {
        const appFlag = applicationFlags.find(f => f.id === i.flagId);
        return {
            flagId: i.flagId,
            symbol: appFlag?.symbol,
            name: appFlag?.name,
            comments: i.comments,
            createdBy: i.createdBy,
            createdDate: i.createdDate,
            added: false
        };
    });
}

export default function ItemFlagsModal({
    isOpen,
    toggle,
    selectedItem,
    onFlagsUpdated,
    msalInstance,
    currentUser
}) {
    let applicationFlags = useSelector(state => state.application.flags);

    const [showModal, setShowModal] = useState(false); 
    const [flags, setFlags] = useState([]); 
    const [selectedFlag, setSelectedFlag] = useState([]);
    const [modalStatus, setModalStatus] = useState(null);
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    const [submitted, setSubmitted] = useState(0);

    const { handleSubmit, reset, setError, clearErrors, formState: { errors } } = useForm();

    const toggleModal = (flag, status) => {
        setSelectedFlag(flag);
        setModalStatus(status);
        setShowModal(!showModal);
    }

    const onSubmit = async () => {
        if (selectedItem?.flags?.length === 0 && flags?.length === 0) {
            // Nothing has been added/updated/deleted
            reset();
            toggle();
        }
        else {
            let errorMsg = null;
            let updatedFlags = [];
            // Has user deleted all existing flags?
            if (selectedItem?.flags?.length > 0 && flags?.length === 0) {
                let deleteResult = await callDeleteAPI("flag/item/" + selectedItem?.id, msalInstance, currentUser.authUser, null, 'DELETE');
                errorMsg = deleteResult.errorMsg;
            }
            else {
                let result = await callAPI("flag/item/" + selectedItem?.id, msalInstance, currentUser.authUser, flags, 'PUT');
                errorMsg = result.errorMsg
                updatedFlags = result.json;
            }
            if (errorMsg && !updatedFlags) {
                setError("editError", {
                    type: "manual",
                    message: errorMsg
                });
            }
            else {
                reset();
                if (onFlagsUpdated) {
                    onFlagsUpdated(selectedItem?.id, updatedFlags);
                }
                toggle();
            }
        }
        setSubmitted(submitted + 1);
    }

    const flagAdded = (flagData) => {
        const newFlags = [...flags, {
            flagId: flagData.flag.value,
            name: flagData.flag.symbolName,
            symbol: flagData.flag.label,
            comments: flagData.comments,
            createdBy: currentUser.name,
            added: true
        }];
        setFlags(newFlags);
        if (applicationFlags.length <= newFlags.length) {
            setAddButtonDisabled(true);
        }
    }

    const flagUpdated = (flagData) => {
        var newFlags = flags.map(f => {
            if (f.flagId !== flagData.flag.value) {
                return f;
            }
            return {
                ...f,
                comments: flagData.comments
            }
        });
        setFlags(newFlags);
    }

    const flagDeleted = (flagId) => {
        setFlags(flags.filter(f => f.flagId !== flagId));
        setAddButtonDisabled(false);
    }

    const modalIsClosed = () => {
        setFlags([]);
    }

    useEffect(() => {
        //console.log('isOpen && selectedItem && currentFlags', isOpen, selectedItem, currentFlags);
        clearErrors();
        if (isOpen && selectedItem?.flags?.length > 0) {
            const itemFlags = getFlags(applicationFlags, selectedItem.flags);
            setFlags(itemFlags);
            if (applicationFlags.length <= itemFlags.length) {
                setAddButtonDisabled(true);
            }
        }
        else {
            setFlags([]);
            setAddButtonDisabled(false);
        }
    }, [isOpen, selectedItem, applicationFlags, clearErrors]);

    const cellFn = (original) => {
        return (<ButtonToolbar>
            <Button
                color="primary"
                className="ms-1 btn-xs"
                title="Edit"
                onClick={() => toggleModal(original, Action.EDIT)}
            >
                <img className="icon" src="./icons/button_edit.svg" alt="" />
            </Button>
            <Button
                color="danger"
                className="ms-1 btn-xs"
                title={"Delete flag"}
                onClick={() => flagDeleted(original.flagId)}
            >
                <img className="icon" src="./icons/button_delete.svg" alt="" />
            </Button>
        </ButtonToolbar>);
    }
    const columnsWithActions = [...FlagColumns(), getActionsColumnDef(cellFn)];

    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={Action.EDIT + " flags"}
            subTitle={selectedItem?.code}
            className="modal-lg"
            onSubmit={handleSubmit(data => onSubmit(data))}
            onModalIsClosing={modalIsClosed}
            formIsValid={true}
            setNotLoading={submitted}
        >
            
                <Col className="mb-2">
                        <ButtonToolbar>
                            <Button
                                color="primary"
                                size="sm"
                                className="me-1"
                                onClick={() => toggleModal(null, Action.ADD)}
                                disabled={!applicationFlags || applicationFlags.length === 0 || addButtonDisabled}>
                                Add
                            </Button>
                        </ButtonToolbar>
                </Col>
            <SimpleCustomTable
                tableColumns={columnsWithActions}
                tableData={flags}
                className="table-with-states action-3"
            />
            <FlagModal
                isOpen={showModal}
                toggle={toggleModal}
                status={modalStatus}
                selectedFlag={selectedFlag}
                existingFlags={flags}
                onFlagAdded={flagAdded}
                onFlagUpdated={flagUpdated}
            />
            {errors.editError && <p className="text-danger">{errors.editError.message}</p>}
        </FormModalBase>)
}