import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StorageLayout from './StorageLayout';
import { Section, Action, EntityType } from '../../helpers/enums';
import EntityLayout from '../common/EntityLayout';
import { CategoryColumns } from './Columns';
import { setStorageCategories } from '.';
import { isStorageOwner } from '../../helpers/commonHelpers';
import workspaceActions from '../../actions/workspaceActions';

export function Categories({ msalInstance }){
    const dispatch = useDispatch();

    const [enabledActions, setEnabledActions] = useState([]);

    let selectedStorage = useSelector(state => state.workspace.activeItem);
    let currentUser = useSelector(state => state.application.user);

    const setValues = (entity, newValues, action) => {
        if (action && action === Action.EDIT) {
            entity.name = newValues.name;
        }
    }

    const refreshStorageCategories = () => {
        setStorageCategories(selectedStorage.id, dispatch, msalInstance, currentUser.authUser);
    }

    const setCategories = (categories) => {
        dispatch(workspaceActions.setStorageCategories(categories));
    }

    useEffect(() => {
        if (currentUser) {
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }];
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            if (isStorageOwner(currentUser, selectedStorageId)) {
                userActions.push({ action: Action.CREATE });
                userActions.push({ action: Action.EDIT });
                userActions.push({ action: Action.COPY });
                userActions.push({ action: Action.DELETE });
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage]);
    
    return (
        <StorageLayout
            title="Manage categories"
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                <b>Category</b> is a container for related <b>items</b>. Categorized items are easier to find and manage.
            </div>
            <EntityLayout
                type={Section.WORKSPACE}
                entityType={EntityType.CATEGORY}
                columns={CategoryColumns()}
                setValues={setValues}
                enabledActions={enabledActions}
                separateFilterField={true}
                tableStyle="action-3"
                onEntitiesUpdated={refreshStorageCategories}
                msalInstance={msalInstance}
                onEntitiesFetched={setCategories}
            />
        </StorageLayout>
    );
}