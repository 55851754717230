import React, { useState } from 'react';
import { ButtonToolbar, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Action, EntityStatus, RevisionStatus } from '../../helpers/enums';
import { hasPublishingRole } from '../../helpers/commonHelpers';
import { useSelector } from 'react-redux';

export default function ButtonRow({
    buttons,
    type,
    selectedEntities,
    clearFiltersClicked,
    clearFilterDisabled
}) {
    let currentUser = useSelector(state => state.application.user);
    let selectedStorage = useSelector(state => state[type].activeItem);

    const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
    const selectedFactoryIds = selectedEntities && selectedEntities.length > 0 ?selectedEntities.map(e => e.factoryId) : [];
    const userHasStagePublishingRole = hasPublishingRole(currentUser, EntityStatus.STAGE, selectedStorageId, selectedFactoryIds);
    const userHasProdPublishingRole = hasPublishingRole(currentUser, EntityStatus.PROD, selectedStorageId, selectedFactoryIds);
    const stagePublishingDisabled = selectedEntities.length < 1 ||
        (selectedEntities.length > 0 &&
        selectedEntities.some(e => e.stageStatus === RevisionStatus.LATEST_REVISION)) ||
        !userHasStagePublishingRole;
    const prodPublishingDisabled = selectedEntities.length < 1 ||
        (selectedEntities.length > 0 &&
        selectedEntities.some(e => e.prodStatus === RevisionStatus.LATEST_REVISION)) ||
        !userHasProdPublishingRole;
    
    const [dropdownOpen, setOpen] = useState(false);

    const toggleDropdown = () => setOpen(!dropdownOpen);

    if (!buttons || buttons.length === 0) {
        return (<></>);
    }

    const createButton = buttons.find(b => b.action === Action.CREATE);
    const deleteButton = buttons.find(b => b.action === Action.DELETE);
    const publishButton = buttons.find(b => b.action === Action.PUBLISH);
    const settingsButton = buttons.find(b => b.action === Action.SETTINGS);

    const buttonIsDisabled = () => {
        return selectedEntities.length === 0 ||
            (selectedEntities.length > 0 && (selectedEntities.some(e => e.references > 0) || selectedEntities.some(e => e.isConfig)));
    }

    return (
        <ButtonToolbar>
            {createButton &&
                <Button
                    color="primary"
                    className="me-1"
                    onClick={() => createButton.onClick(Action.CREATE)}
                    disabled={createButton.disabled || !selectedStorage}>
                    <img className="icon icon-with-text" src="./icons/button_create.svg" alt=""/>
                    Create
                </Button>
            }
            {deleteButton &&
                <Button
                    color="danger"
                    className="me-1"
                    onClick={() => deleteButton.onClick()}
                    disabled={buttonIsDisabled()}>
                    <img className="icon icon-with-text" src="./icons/button_delete.svg" alt=""/>
                    Delete selected
                </Button>
            }
            {publishButton &&
                <ButtonDropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="me-1">
                    <DropdownToggle
                        color="primary"
                        caret
                        className="text-white"
                        disabled={stagePublishingDisabled && prodPublishingDisabled}
                    >
                        <img className="icon icon-with-text" src="./icons/button_publish.svg" alt=""/>
                        Publish selected
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            key="publish_stage"
                            onClick={() => publishButton.onClick(EntityStatus.STAGE)}
                            disabled={stagePublishingDisabled}
                        >
                            To stage
                        </DropdownItem>
                        <DropdownItem
                            key="publish_prod"
                            onClick={() => publishButton.onClick(EntityStatus.PROD)}
                            disabled={prodPublishingDisabled}
                        >
                            To stage &amp; production
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            }
            <Button
                color="primary"
                className="me-1"
                onClick={() => clearFiltersClicked()}
                disabled={clearFilterDisabled}>
                <img className="icon icon-with-text" src="./icons/button_clear_filters.svg" alt=""/>
                Clear filters
            </Button>
            {settingsButton &&
                <Button
                    color="primary"
                    onClick={settingsButton.onClick}>
                    <img className="icon" src="./icons/button_settings.svg" alt=""/>
                </Button>
            }
        </ButtonToolbar>
    );
}