import { Role, PublishingRole, PublishingRoleText, PublishingRoleTextShort } from '../../helpers/enums';

export const subMenuItems = [
    {
        name: 'Users',
        to: '/admin/users',
        icon: './icons/admin_user.svg',
        requiredRole: Role.ADMIN
    },
    {
        name: 'Storages',
        to: '/admin/storages',
        icon: './icons/admin_storage.svg',
        requiredRole: Role.OWNER
    },
    {
        name: 'Factories',
        to: '/admin/factories',
        icon: './icons/admin_factory.svg',
        requiredRole: Role.ADMIN
    }
];

export const getPublishingRoleTextById = (roleId, abbreviation = true) => {
    switch (roleId) {
        case PublishingRole.NONE:
            return abbreviation ? PublishingRoleTextShort.NONE : PublishingRoleText.NONE;
        case PublishingRole.STAGE:
            return abbreviation ? PublishingRoleTextShort.STAGE : PublishingRoleText.STAGE;
        case PublishingRole.PROD:
            return abbreviation ? PublishingRoleTextShort.PROD : PublishingRoleText.PROD;
        default: return "";
    }
}