import { ActionTypes } from "../helpers/enums"

const setRefreshedDate = () => {
    return {
        type: ActionTypes.SET_STORAGES_REFRESH_DATE
    }
}

const setStorages = (storages) => {
    return {
        type: ActionTypes.SET_STORAGES,
        payload: storages
    }
}

const setActiveStorage = (storage) => {
    return {
        type: ActionTypes.SET_STORAGES_ACTIVE_STORAGE,
        payload: storage
    }
}

const setSelectedItems = (items) => {
    return {
        type: ActionTypes.SET_STORAGES_SELECTED_ITEMS,
        payload: items
    }
}

const setSelectedElements = (elements) => {
    return {
        type: ActionTypes.SET_STORAGES_SELECTED_ELEMENTS,
        payload: elements
    }
}

const setStorageFactories = (factories) => {
    return {
        type: ActionTypes.SET_STORAGES_STORAGE_FACTORIES,
        payload: factories
    }
}

const setSelectedFactories = (factoryIds) => {
    return {
        type: ActionTypes.SET_STORAGES_SELECTED_FACTORIES,
        payload: factoryIds
    }
}

const setSelectedValueTypes = (valueTypes) => {
    return {
        type: ActionTypes.SET_STORAGES_SELECTED_VALUETYPES,
        payload: valueTypes
    }
}

const setStorageValueTypes = (valueTypes) => {
    return {
        type: ActionTypes.SET_STORAGES_STORAGE_VALUETYPES,
        payload: valueTypes
    }
}

const setStorageCategories = (categories) => {
    return {
        type: ActionTypes.SET_STORAGES_STORAGE_CATEGORIES,
        payload: categories
    }
}

const setStorageUnits = (units) => {
    return {
        type: ActionTypes.SET_STORAGES_STORAGE_UNITS,
        payload: units
    }
}

const setStorageElementTypes = (elements) => {
    //console.log('set element types', elements);
    return {
        type: ActionTypes.SET_STORAGES_STORAGE_ELEMENT_TYPES,
        payload: elements
    }
}

const setStorageUnitGroups = (categories) => {
    return {
        type: ActionTypes.SET_STORAGES_STORAGE_UNITGROUPS,
        payload: categories
    }
}

const setSelectedElementTypes = (elementTypes) => {
    return {
        type: ActionTypes.SET_STORAGES_SELECTED_ELEMENTTYPES,
        payload: elementTypes
    }
}

const setNoItemsFound = (notFound) => {
    return {
        type: ActionTypes.SET_STORAGES_NO_ITEMS_FOUND,
        payload: notFound
    }
}

const setDataLoading = (loading) => {
    return {
        type: ActionTypes.SET_STORAGES_LOADING_DATA,
        payload: loading
    }
}

const setEntitiesLoading = (loading) => {
    return {
        type: ActionTypes.SET_STORAGES_LOADING_ENTITIES,
        payload: loading
    }
}

const setLoading = (loading) => {
    return {
        type: ActionTypes.SET_STORAGES_LOADING,
        payload: loading
    }
}

const setSelectedUnitGroups = (groups) => {
    return {
        type: ActionTypes.SET_STORAGES_SELECTED_UNITGROUPS,
        payload: groups
    }
}

let workspaceActions = {
    setRefreshedDate,
    setStorages,
    setActiveStorage, 
    setSelectedItems, 
    setSelectedElements, 
    setStorageFactories,
    setSelectedFactories,
    setSelectedValueTypes,
    setStorageValueTypes,
    setStorageCategories,
    setStorageUnits,
    setStorageElementTypes,
    setSelectedElementTypes,
    setStorageUnitGroups,
    setNoItemsFound,
    setDataLoading,
    setEntitiesLoading,
    setLoading,
    setSelectedUnitGroups
};

export default workspaceActions;