import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StorageLayout from './StorageLayout';
import { Section, Action, EntityType, Role, PublishingRole } from '../../helpers/enums';
import { ValueTypeColumns, ValueTypeLogColumns, ValueTypeColumnsUserAndDateCombined } from './Columns';
import { getBaseType, setStorageValueTypes, getUnitType } from '.';
import EntityLayout from '../common/EntityLayout';
import { hasContributorRole, hasRoleForStorageAndFactory, hasPublishingRole } from '../../helpers/commonHelpers';

export function ValueTypes({ msalInstance }){
    const dispatch = useDispatch();

    const [enabledActions, setEnabledActions] = useState([]);

    let selectedStorage = useSelector(state => state.workspace.activeItem);
    let currentUser = useSelector(state => state.application.user);
    let selectedFactories = useSelector(state => state.workspace.selectedFactories);
    const selectedStorageId = selectedStorage ? selectedStorage.id : 0;

    const setValues = (entity, newValues, action) => {
        //console.log('newValues', newValues);
        if (action && (action === Action.EDIT || action === Action.PUBLISH)){
            entity.allReferences = newValues.allReferences;
            entity.code = newValues.code;
            entity.comments = newValues.comments;
            entity.description = newValues.description;
            entity.factories = newValues.factories; 
            entity.factorySymbol = newValues.factorySymbol;
            entity.formula = newValues.formula;
            entity.id = newValues.id;// input???
            entity.inputDefinedLater = newValues.inputDefinedLater;
            entity.internalNotes = newValues.internalNotes;
            entity.isGlobal = newValues.isGlobal;
            entity.prodStatus = newValues.prodStatus;
            entity.references = newValues.references;
            entity.revisionId = newValues.revisionId;
            entity.revisions = newValues.revisions;
            entity.stageStatus = newValues.stageStatus;
            entity.storageId = newValues.storageId;
            entity.supportedFactories = newValues.supportedFactories;
            entity.supportsAllFactories = newValues.supportsAllFactories;
            entity.typeId = newValues.typeId;
            entity.unit = newValues.unit;
            entity.unitGroup = newValues.unitGroup;
            entity.unitGroupId = newValues.unitGroupId;
            entity.unitId = newValues.unitId;
            entity.unitType = newValues.unitType;
            entity.unitTypeId = newValues.unitTypeId;
            entity.updatedBy = newValues.updatedBy;
            entity.updatedDate = newValues.updatedDate;
            entity.userInputs = newValues.userInputs;
        }
            
        //entity.origin = newValues.isGlobal ? ValueTypeOrigin.DEFAULT : ValueTypeOrigin.CUSTOM;
        entity.type = getBaseType(newValues.typeId, newValues.type);
        entity.input = newValues.isGlobal ? "" :
            (newValues.userInputs && newValues.userInputs.length > 0) ? newValues.userInputs.map(i => i.code).join(", ") : "";
        entity.unitType = getUnitType(newValues.unitTypeId, newValues.unitType);
        // Set revision data
        entity.revisions.forEach(r => { 
            r.type = getBaseType(r.typeId, r.type); 
            r.unitType = getUnitType(r.unitTypeId, r.unitType);
        });
        entity.disabledSelect = !hasRoleForStorageAndFactory(currentUser, Role.CONTRIBUTOR, selectedStorageId, newValues.factoryId);
    }

    const refreshStorageValueTypes = () => {
        setStorageValueTypes(selectedStorage.id, dispatch, msalInstance, currentUser.authUser);
    }

    useEffect(() => {
        if (currentUser) {
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }, { action: Action.LOG }];
            if (hasContributorRole(currentUser, selectedStorageId, selectedFactories)) {
                userActions.push({
                    action: Action.CREATE,
                    disabled: selectedFactories.length === 0
                });
                userActions.push({
                    action: Action.EDIT,
                });
                userActions.push({
                    action: Action.COPY,
                });
                userActions.push({
                    action: Action.DELETE,
                });
                if (hasPublishingRole(currentUser, PublishingRole.STAGE, selectedStorageId, selectedFactories, false) ||
                    hasPublishingRole(currentUser, PublishingRole.PROD, selectedStorageId, selectedFactories, false)) {
                    userActions.push({
                        action: Action.PUBLISH,
                    });
                }
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage, selectedFactories]);

    return (
        <StorageLayout
            title="Manage value types"
            showFactoryList={true}
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                <b>Value type</b> determines the structure of an <b>element</b> by specifying its type (e.g. string, integer..), the number of user inputs required, and how the element's value is calculated.
                <br />Each element must select one value type.
            </div>
            <EntityLayout
                type={Section.WORKSPACE}
                entityType={EntityType.VALUETYPE}
                columns={ValueTypeColumns()}
                setValues={setValues}
                enabledActions={enabledActions}
                logColumns={ValueTypeLogColumns()}
                onEntitiesUpdated={refreshStorageValueTypes}
                combinedColumns={ValueTypeColumnsUserAndDateCombined()}
                msalInstance={msalInstance}
            />
        </StorageLayout>
    );
}