import React from 'react';
import { Row, Col } from 'reactstrap';
import { InputFilter, SelectFilter, SelectMultiFilter } from './ColumnFilters';
import { useSelector } from 'react-redux';

export default function FilterRow({
    type,
    filters,
    disabled,
    filterChanged,
    clearFilters
}) {
    const size = filters && filters.length === 1 ? 12
        : filters && filters.length === 3 ? 4 : 3;
    const storageDataLoading = useSelector(state => state[type].storageDataLoading);

    if (!filters || filters.length === 0) {
        return (<></>);
    }

    return (
        <Row>
            {filters.map((f, index) => {
                if (f.options) {
                    if (f.isMulti) {
                        return <Col sm={size} md={size} lg={size}
                            key={"filterRow" + index}
                            className="mb-1 pe-0 my-1">
                            <SelectMultiFilter
                                key={f.id}
                                options={f.options}
                                disabled={disabled}
                                id={f.id}
                                filterChanged={filterChanged}
                                clearFilterValue={clearFilters || storageDataLoading}
                                placeholder={f.placeholder} />
                        </Col>
                    }
                    return <Col sm={size} md={size} lg={size}
                        key={"filterRow" + index}
                        className="mb-1 pe-0 my-1">
                        <SelectFilter
                            key={f.id}
                            options={f.options}
                            disabled={disabled}
                            id={f.id}
                            filterChanged={filterChanged}
                            clearFilterValue={clearFilters || storageDataLoading}
                            placeholder={f.placeholder} />
                    </Col>
                }
                else {
                    return <Col sm={size} md={size} lg={size}
                        key={"filterRow" + index}
                        className="mb-1 pe-0 my-1">
                        <InputFilter
                            key={f.id}
                            disabled={disabled}
                            id={f.id}
                            filterChanged={filterChanged}
                            clearFilterValue={clearFilters}
                            isGlobal={f.isGlobal === true} />
                    </Col>
                }
            })
            }
        </Row>
    );
}