import React, { useEffect } from 'react';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { Action } from '../../../helpers/enums';
import { CustomSelect } from '../../common/CustomSelect';
import EntityModalBase from './EntityModalBase';

const schema = yup.object().shape({
    code: yup.string().label('Code').required().max(10),
    group: yup.object().shape({value:yup.number().required('Select a Group')}).nullable().required('Select a Group'),
    description: yup.string().label('Description').required().max(50),
});

export default function UnitModal({
    isOpen,
    toggle,
    status,
    selectedUnit,
    onEntityCreated,
    onEntityUpdated,
    msalInstance
}) {
    const storageUnitGroups = useSelector(state => state.workspace.storageUnitGroups);//console.log('storageUnitGroups', storageUnitGroups);
    const selectedUnitGroupIds = useSelector(state => state.workspace.selectedUnitGroups);
    const { handleSubmit, reset, setError, formState: {errors, isValid}, control, setValue, clearErrors, trigger} = useForm({resolver: yupResolver(schema), mode: 'onChange'});

    let selectedUnitGroups = storageUnitGroups && storageUnitGroups.length > 0 ? 
        storageUnitGroups.filter(f => selectedUnitGroupIds.includes(f.id)) : [];
    let groups = selectedUnitGroups.length > 0 ? selectedUnitGroups.map(c => { return {value: c.id, label: c.name}}) : [];

    const defaultValues = {
        id: 0,
        code: "",
        group: groups.length === 1 ? groups[0] : [],
        description: ""
    };

    const getData = (data) => {
        let { group, ...body } = data;
        if (data.group) {
            body.groupId = data.group.value;
        }
        return body;
    }

    const handleCodeChange = (input) => {
        clearErrors('submitError');
        trigger('submitError');
        setValue('code', input.target.value);
        trigger('code');
    }

    useEffect(() => {
        if (selectedUnit && selectedUnit.id > 0) {
            setValue('id', selectedUnit.id);
            setValue('code', selectedUnit.code);
            setValue('group', { value: selectedUnit.groupId, label: selectedUnit.group });
            setValue('description', selectedUnit.description);
            trigger();
        }
        else {
            //console.log('groups', groups);
            setValue('id', 0);
            setValue('code', "");
            setValue('group', groups.length === 1 ? groups[0] : []);
            setValue('description', "");
        }
    }, [selectedUnit, status, selectedUnitGroupIds]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <EntityModalBase
            isOpen={isOpen}
            toggle={toggle}
            status={status}
            titleBase="unit"
            baseUrl="unit"
            getData={getData}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            entityIsCreated={onEntityCreated}
            entityIsUpdated={onEntityUpdated}
            msalInstance={msalInstance}
        >
            <Controller name="id"
                control={control}
                defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <FormGroup row className="required form-group">
                <Label for="group" sm={3}>Group</Label>
                <Col sm={9}>
                    <Controller name="group"
                        control={control}
                        defaultValue={defaultValues.group}
                        render={({ field }) => <CustomSelect {...field}
                            options={groups}
                            isDisabled={status === Action.VIEW}
                        />}
                    />
                    {errors.group && <p className="text-danger">{errors.group.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="code" sm={3}>Unit code</Label>
                <Col sm={9}>
                    <Controller name="code"
                        control={control}
                        defaultValue={defaultValues.code}
                        render={({ field }) =>
                            <Input {...field}
                                type="text"
                                onChange={handleCodeChange}
                            />
                        }
                    />
                    {errors.code && <p className="text-danger">{errors.code.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="description" sm={3}>Description</Label>
                <Col sm={9}>
                    <Controller name="description"
                        control={control}
                        defaultValue={defaultValues.description}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                </Col>
            </FormGroup>
        </EntityModalBase>
    );
}