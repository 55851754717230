import { StatusCell, ChangesCell, ChangesFromListCell, EditableCell, StatusCellSimple, ChangesWithSubstCell, 
    ChangesLogCell, DivCell, DateCell, CustomChangesLogCell, RowChangesLogCell, RowChangesCell, UserAndDateCell,
    ElementValueCell, ItemCell, ElementUnitCell, SimpleDivCell, CellWithSymbol, FactoryLogCell,
    ChangesWithSymbolCell} from '../common/Cell';
import {
    RevisionStatus,
    RevisionStatusText,
    ElementStatusText,
    EntityStatus
} from '../../helpers/enums';
import {
    SUPPORTS_ALL_FACTORIES,
    getTypeById,
    getUserInputs,
    userInputsAreSame,
    getUnitTypeById,
    revisionPropertiesAreSame,
    getActionStatusText
} from '.';
import {
    getTruncatedValue,
    getValueString
} from '../../helpers/commonHelpers';
import { fuzzyMultipleFn } from '../common/ColumnFilters';

const maxLength = 500;

const getRevisionStatusText = (revisionStatus) => {
    if (revisionStatus === RevisionStatus.LATEST_REVISION) {
        return RevisionStatusText.LATEST_REVISION;
    }

    if (revisionStatus === RevisionStatus.EARLIER_REVISION) {
        return RevisionStatusText.EARLIER_REVISION;
    }

    return RevisionStatusText.NO_REVISION;
}

const getRevision = (revisions, status) => {
    if (revisions?.length > 0) {
        return revisions.find(r => r.status === status);
    }
    return null;
}

const getPublisher = (revisions, status) => {
    var revision = getRevision(revisions, status);
    if (revision) {
        return revision.updatedBy;
    }
}

const getPublishingDate = (revisions, status) => {
    var revision = getRevision(revisions, status);
    if (revision) {
        return revision.updatedDate;
    }
}

const EnvironmentColumns = () => {
    return [{
            header: 'Stage',
            id: 'stage', // key is needed for settings modal!
            accessorFn: row => getRevisionStatusText(row.stageStatus), 
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            maxFilterWidth: 50,
            className: 'w-50px',
            cell: StatusCell
        },
        {
            header: 'Prod',
            id: 'prod', // key is needed for settings modal!
            accessorFn: row => getRevisionStatusText(row.prodStatus), 
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            maxFilterWidth: 50,
            className: 'w-50px',
            cell: StatusCell
        }
    ];
}

const ElementColumnsBase = () => {
    return [{
            header: 'Elem. code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: ChangesCell
        },
        {
            header: 'Factory',
            id: 'factory',
            accessorFn: row => {
                if (row.isSubstitutable) {
                    return row.factory + " (subst.)";
                }
                return row.factory;
            },
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                const current = {
                    id: original.revisionId,
                    value: getValue(),
                    factorySymbol: original.factorySymbol ?? null
                }; //console.log('current', current);
                return <ChangesWithSubstCell revisions={original.revisions} current={current} propertyId="factory" />
            }
        },
        {
            header: 'Criterion',
            accessorKey: 'criterion',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id } }) => {
                const getCriterion = ({ value, maxLength }) => {
                    if (maxLength > 0) {
                        return (<div className="line-clamp">{getTruncatedValue(value, maxLength)}</div>);
                    }
                    return (<div>{getTruncatedValue(value, maxLength)}</div>);
                }
                return (<RowChangesCell value={getValue()} row={{ original: original }} column={{ id: id }} getMethod={getCriterion} compareMethod={revisionPropertiesAreSame} maxLength={maxLength} />);
            }
        },
        {
            header: 'Type',
            accessorKey: 'valueType.code',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Input',
            id: 'input',
            accessorFn: row => {
                let elementInput = row.input;
                if (row.valueType?.userInputs?.length > 0) {
                    elementInput = row.valueType.userInputs.map(i => i.code + ' = ' + i.value).join(' ');
                }
                return elementInput;
            },
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RowChangesCell value={getValue()} row={{ original: original }} column={{ id: id }} getMethod={getUserInputs} compareMethod={userInputsAreSame} maxLength={maxLength} />);
            }
        },
        {
            header: 'Value',
            id: 'value',
            accessorFn: row => {
                return getValueString(row.value);
            },
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major whitespace-pre-wrap',
            cell: ({ row: { original }, column: { id } }) => {
                return (<ElementValueCell row={{ original: original }} column={{ id: id }} maxLength={maxLength} />);
            }
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                return (<ElementUnitCell value={getValue()} row={{ original: original }} />);
            }
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
    ];
}

export const ElementColumns = () => {
    return [//React.useMemo( // Cannot use React.Memo - updating table rows is not functioning!!!
        //() => [
        ...EnvironmentColumns(),
        {
            header: 'Item',
            id: 'item',
            accessorFn: row => row.itemCode + ' ' + row.itemDescription,
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<ItemCell code={original.itemCode} description={original.itemDescription} />);
            }
        },
        ...ElementColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Updated',
            accessorKey: 'updatedDate',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Publ. by (stage)',
            id: 'publishedToStageBy',
            accessorFn: row => getPublisher(row.revisions, EntityStatus.STAGE),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Publ. (stage)',
            id: 'publishedToStageDate',
            accessorFn: row => getPublishingDate(row.revisions, EntityStatus.STAGE),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Publ. by (prod)',
            id: 'publishedToProdBy',
            accessorFn: row => getPublisher(row.revisions, EntityStatus.PROD),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Publ. (prod)',
            id: 'publishedToProdDate',
            accessorFn: row => getPublishingDate(row.revisions, EntityStatus.PROD),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
    ];
}

export const ElementColumnsUserAndDateCombined = () => {
    return [//React.useMemo( // Cannot use React.Memo - updating table rows is not functioning!!!
        //() => [
        ...EnvironmentColumns(),
        {
            header: 'Item code',
            accessorKey: 'itemCode',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Item desc.',
            accessorKey: 'itemDescription',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        ...ElementColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate} />);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate} />);
            }
        },
    ];
}

export const ElementLogColumns = () => {
    return [
        {
            header: 'Stage',
            accessorKey: 'publishedToStageDate',
            cell: StatusCellSimple
        },
        {
            header: 'Prod',
            accessorKey: 'publishedToProdDate',
            cell: StatusCellSimple
        },
        {
            header: 'Elem. code',
            accessorKey: 'code',
            className: 'text-major',
            cell: ChangesLogCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            cell: ({ getValue, row: { index, original }, column: {id}, table}) => {
                return (<FactoryLogCell value={getValue()} row={{index: index, original: original}} column={{id: id}} table={table}/>);
            }
        },
        {
            header: 'Criterion',
            accessorKey: 'criterion',
            className: 'whitespace-pre-wrap',
            cell: ChangesLogCell
        },
        {
            header: 'Type',
            accessorKey: 'valueType.code',
            cell: ChangesLogCell
        },
        {
            header: 'Input',
            accessorKey: 'input',
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { index, original }, column: { id }, table }) =>{
                return (<RowChangesLogCell value={getValue()} row={{ index: index, original: original }} column={{ id: id }} table={table} getMethod={getUserInputs} compareMethod={userInputsAreSame}/>);
            }
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            cell: ChangesLogCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            cell: ChangesLogCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            cell: ChangesLogCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.updatedDate}/>);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate}/>);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate}/>);
            }
        },
    ]
}

export const ElementLogHistoryColumns = () => {
    return [
        {
            header: 'Stage',
            accessorFn: row => getRevisionStatusText(row.stageStatus),
            cell: StatusCell
        },
        {
            header: 'Prod',
            accessorFn: row => getRevisionStatusText(row.prodStatus),
            cell: StatusCell
        },
        {
            header: 'Item code',
            accessorKey: 'itemCode',
            cell: DivCell
        },
        {
            header: 'Elem. code',
            accessorKey: 'code',
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.factorySymbol', original.factorySymbol);
                return (<CellWithSymbol value={getValue()} symbol={original.factorySymbol} />);
            }
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Operation',
            id: 'operation',
            accessorFn: row => getActionStatusText(row.action),
            className: 'text-nowrap',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        }
    ]
}

export const ItemBaseColumns = () => {
    return [
        {
            header: 'Category',
            id: 'category',
            accessorFn: row => row.category ?? "",
            disabled: true,
            default: true,
            enableGlobalFilter: true,
            filterFn: 'equalsString',
            cell: DivCell
        },
        {
            header: 'Item code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Item desc.',
            accessorKey: 'description',
            disabled: false,
            default: true,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Internal notes',
            id: 'internalNotes',
            accessorFn: row => row.internalNotes ?? "",
            disabled: false,
            default: true,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Internal ID',
            id: 'internalId',
            accessorFn: row => row.internalId ?? "",
            disabled: false,
            default: false,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
    ]
}

export const ItemColumns = () => {
    return [
        {
            header: 'Elements',
            id: 'elementStatus',
            accessorFn: row => {
                // For filtering we need to return the values shown in filter dropdown!
                if (row.elementStatus === EntityStatus.PROD) {
                    return ElementStatusText.ALL;
                }
                if (row.elementStatus === EntityStatus.STAGE || row.elementStatus === EntityStatus.INWORK) {
                    return ElementStatusText.SOME;
                }
                return ElementStatusText.WITHOUT;
            },
            disabled: true,
            default: true,
            enableGlobalFilter: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: ({ row: { original } }) => {
                const value = original.elementStatus;//console.log('value', value, typeof value);
                if (value === EntityStatus.PROD){
                    return (<div className="bg-success text-custom-light">{ElementStatusText.PUBLISHED}</div>)
                }
                if (value === EntityStatus.STAGE || value === EntityStatus.INWORK){
                    return (<div className="bg-warning">{ElementStatusText.UNPUBLISHED}</div>)
                }
                return (<div className="bg-secondary text-custom-light">{ElementStatusText.NONE}</div>)
            }
        },
        {
            header: 'Flags',
            accessorKey: 'flagSymbols',
            disabled: false,
            default: true,
            enableGlobalFilter: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-50px',
            cell: DivCell
        },
        ...ItemBaseColumns()
    ]
}

export const FlagColumns = () => {
    return [
        {
            header: 'Flag',
            accessorKey: 'name',
            className: 'w-100px',
            cell: ({ getValue, row: { original } }) => {
                const flag = original.symbol + ' ' + getValue();
                return (<SimpleDivCell value={flag} className="text-major" />);
            }
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: SimpleDivCell
        },
        {
            header: 'Created by',
            accessorKey: 'createdBy',
            className: 'text-nowrap w-160px',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.createdDate} />);
            }
        }
    ];
}

export const ValueTypeColumnsBase = () => {
    return [//React.useMemo( // Cannot use React.Memo - updating table rows is not functioning!!!
        //() => [
        ...EnvironmentColumns(),
        {
            header: 'Value code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: ({ getValue, row: { original }, column: { id } }) => {
                if (original.isGlobal) {
                    return (<div>{getValue()}</div>);
                }
                return (<ChangesCell getValue={getValue} row={{ original: original }} column={{ id: id }} />);
            }
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Factory',
            accessorKey: 'factories',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                if (original.isGlobal) {
                    return (<CellWithSymbol value={SUPPORTS_ALL_FACTORIES} symbol={original.factorySymbol} />);
                }
                return (<ChangesWithSymbolCell value={getValue()} row={{ original: original }} column={{ id: id }} symbolId="factorySymbol"/>);
            }
        },
        {
            header: 'Base type',
            accessorKey: 'type',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Free-form',
            accessorKey: 'inputDefinedLater',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'User input',
            accessorKey: 'input',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.userInputs',original.userInputs);
                if (original.isGlobal) {
                    return (<div className="empty"></div>);
                }
                var current = {
                    id: original.revisionId,
                    value: getValue()
                };

                return (<ChangesFromListCell revisions={original.revisions} current={current}
                    propertyId="userInputs" mappingPropertyId="code" />);
            }
        },
        {
            header: 'Formula',
            accessorKey: 'formula',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id } }) => {
                //console.log('original', original);
                if (original.isGlobal) {
                    return (<div className="empty"></div>);
                }

                return (<ChangesCell getValue={getValue} row={{ original: original }} column={{ id: id }} maxLength={maxLength} />);
            }
        },
        {
            header: 'Unit type',
            accessorKey: 'unitType',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Unit group',
            accessorKey: 'unitGroup',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
    ];
}

export const ValueTypeColumns = () => {
    return [//React.useMemo( // Cannot use React.Memo - updating table rows is not functioning!!!
        //() => [
        ...ValueTypeColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Updated',
            accessorKey: 'updatedDate',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Publ. by (stage)',
            id: 'publishedToStageBy',
            accessorFn: row => getPublisher(row.revisions, EntityStatus.STAGE),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Publ. (stage)',
            id: 'publishedToStageDate',
            accessorFn: row => getPublishingDate(row.revisions, EntityStatus.STAGE),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Publ. by (prod)',
            id: 'publishedToProdBy',
            accessorFn: row => getPublisher(row.revisions, EntityStatus.PROD),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Publ. (prod)',
            id: 'publishedToProdDate',
            accessorFn: row => getPublishingDate(row.revisions, EntityStatus.PROD),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ValueTypeColumnsUserAndDateCombined = () => {
    return [
        ...ValueTypeColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate} />);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate} />);
            }
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ValueTypeLogColumns = () => {
    return [
        {
            header: 'Stage',
            accessorKey: 'publishedToStageDate',
            cell: StatusCellSimple
        },
        {
            header: 'Prod',
            accessorKey: 'publishedToProdDate',
            cell: StatusCellSimple
        },
        {
            header: 'Value code',
            accessorKey: 'code',
            className: 'text-major',
            cell: ChangesLogCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: ChangesLogCell
        },
        {
            header: 'Factory',
            accessorKey: 'supportedFactories',
            cell: ({ getValue, row: { original, index }, column: {id}, table}) => {
                return (<FactoryLogCell value={getValue()} row={{ index: index, original: original }} column={{ id: id }} table={table} />);
            }
        },
        {
            header: 'Base type',
            accessorKey: 'type',
            cell: ({ getValue, row: { index }, column: { id }, table }) => {
                return (<CustomChangesLogCell value={getValue()} row={{ index: index }} column={{ id: id }} table={table} getMethod={getTypeById}/>)
            }
        },
        {
            header: 'Free-form',
            accessorKey: 'inputDefinedLater',
            cell: ChangesLogCell
        },
        {
            header: 'User input',
            accessorKey: 'userInputs',
            cell: ChangesLogCell
        },
        {
            header: 'Formula',
            accessorKey: 'formula',
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original, index }, column: { id }, table }) => {
                //console.log('props', table.getCoreRowModel().flatRows);
                if (original.isGlobal){
                    return (<div className="empty"></div>);
                }
                return (<ChangesLogCell getValue={getValue} row={{ index: index }} column={{ id: id }} table={table} />);
            }
        },
        {
            header: 'Unit type',
            accessorKey: 'unitType',
            cell: ({ getValue, row: { index }, column: { id }, table }) => {
                return (<CustomChangesLogCell value={getValue()} row={{ index: index }} column={{ id: id }} table={table} getMethod={getUnitTypeById} />)
            }
        },
        {
            header: 'Unit group',
            accessorKey: 'unitGroup',
            cell: ChangesLogCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            cell: ChangesLogCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            cell: ChangesLogCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate}/>);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate}/>);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate}/>);
            }
        },
    ];
}

export const UserInputColumns = (inputName) => {
    return [
        {
            header: 'Code',
            accessorKey: 'code',
            cell: DivCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: DivCell
        },
        {
            header: 'Type',
            accessorKey: 'type',
            cell: DivCell
        },
        {
            header: inputName ?? 'Input',
            accessorKey: 'value',
            cell: EditableCell
        },
    ];
}

const ParameterColumnsBase = () => {
    return [
        ...EnvironmentColumns(),
        {
            header: 'Param. code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: ChangesCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<ChangesWithSymbolCell value={getValue()} row={{ original: original }} column={{ id: id }} symbolId="factorySymbol" />);
            }
        },
        {
            header: 'Type',
            accessorKey: 'type',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Value',
            accessorKey: 'value',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<ChangesCell getValue={getValue} row={{ original: original }} column={{ id: id }} maxLength={maxLength} />);
            }
        },
        {
            header: 'Has unit',
            accessorKey: 'hasUnit',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        }
    ];
}

export const ParameterColumns = () => {
    return [
        ...ParameterColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Updated',
            accessorKey: 'updatedDate',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Publ. (stage)',
            accessorKey: 'publishedToStageDate',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Publ. (prod)',
            accessorKey: 'publishedToProdDate',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: DateCell
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ParameterColumnsUserAndDateCombined = () => {
    return [
        ...ParameterColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate} />);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate} />);
            }
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ParameterLogColumns = () => {
    return [
        {
            header: 'Stage',
            accessorKey: 'publishedToStageDate',
            cell: StatusCellSimple
        },
        {
            header: 'Prod',
            accessorKey: 'publishedToProdDate',
            cell: StatusCellSimple
        },{
            header: 'Param. code',
            accessorKey: 'code',
            className: 'text-major',
            cell: ChangesLogCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: ChangesLogCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
                return (<FactoryLogCell value={getValue()} row={{ index: index, original: original }} column={{ id: id }} table={table} />);
            }
        },
        {
            header: 'Type',
            accessorKey: 'type',
            cell: ChangesLogCell
        },
        {
            header: 'Value',
            accessorKey: 'value',
            className: 'text-major whitespace-pre-wrap',
            cell: ChangesLogCell
        },
        {
            header: 'Has unit',
            accessorKey: 'hasUnit',
            cell: ChangesLogCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            cell: ChangesLogCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            cell: ChangesLogCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            cell: ChangesLogCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate}/>);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate}/>);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate}/>);
            }
        },
    ];
}

export const ClientInputsColumns = () => {
    return [
        {
            header: 'Input code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Type',
            accessorKey: 'type',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Namespace',
            accessorKey: 'namespace',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const CategoryColumns = () => {
    return [
        {
            header: 'Category',
            accessorKey: 'name',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
        },
        {
            header: 'Items',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}

export const UnitColumns = () => {
    return [//React.useMemo( // Cannot use React.Memo - updating table rows is not functioning!!!
        //() => [
        {
            header: 'Group',
            accessorKey: 'group',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
        },
        {
            header: 'Unit code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}

export const ElementTypeColumns = () => {
    return [
        {
            header: 'Element code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
        },
        {
            header: 'Description',
            accessorKey: 'description',
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Value types',
            accessorKey: 'types',
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                const value = getValue();
                //console.log('value', value);
                if (!value || !value.includes(';;')){
                    return (<div>{value}</div>);
                }
                let codes = value.split(';;');//console.log('codes', codes);
                if (!codes || codes.length === 0){
                    return (<div>{value}</div>);
                }
                return (<div>{codes.map((code, index) => {return(<span key={index}>{code}<br></br></span>);}) }</div>);
            }
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}

export const UnitGroupColumns = () => {
    return [
        {
            header: 'Group',
            accessorKey: 'name',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
        },
        {
            header: 'Units',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}