import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, ButtonToolbar } from 'reactstrap';
import { CustomSpinner } from '../common/CustomSpinner';
import { Error } from '../common/Error';
import CustomTableWithFilters from '../common/CustomTableWithFilters';

export default function TableFiltersAndButtons({
    title,
    data,
    selectedData,
    setSelectedData,
    columns,
    loading,
    msg = "",
    localStorageKey,
    buttons,
    useRowSelection = true,
    pageSizes = [5, 10, 15],
    tableXl = 10,
    tableClass = ""
}) {
    const tableInstance = useRef(null); //console.log('buttons', buttons, msg);
    //console.log('selectedData', selectedData);
    const [filteredRowsCount, setFilteredRowsCount] = useState(0);
    const [clearFilterDisabled, setClearFilterDisabled] = useState(true);

    const getSelectedTableRows = () => {
        return tableInstance.current.getSelectedRowModel().rows;
    }

    const getSelectedIds = () => {
        return getSelectedTableRows().map(r => r.id);
    }

    const setSelected = (selected) => {
        //console.log('selected', selected);
        if (setSelectedData) {
            setSelectedData(selected);
        }
    }

    const onSelectedChanged = (selectedIds) => {
        //console.log('selectedIds, data', selectedIds, data);
        if (selectedIds?.length > 0) {
            setSelected(data.filter(d => selectedIds.includes(d.id)));
        }
        else {
            setSelected([]);
        }
    }

    const deselectAll = () => {
        // Empty all selected rows
        if (tableInstance.current && tableInstance.current.toggleAllRowsSelected) {
            tableInstance.current.toggleAllRowsSelected(false);
        }
        setSelected([]);
    }

    const rowCountChanged = (count) => {
        setFilteredRowsCount(count);
        const columnFilters = tableInstance?.current ? tableInstance.current.getState().columnFilters : [];
        //console.log('filters changed', columnsFilters);
        if (!columnFilters || columnFilters.length === 0) {
            setClearFilterDisabled(true);
        }
        else {
            setClearFilterDisabled(false);
        }
    }

    const clearFiltersClicked = () => {
        setClearFilterDisabled(true);
        // Empty all selected rows
        setSelected([]);
        if (tableInstance?.current?.toggleAllRowsSelected) {
            tableInstance.current.toggleAllRowsSelected(false);
        }
        //console.log('tableInstance.current.resetColumnFilters', tableInstance.current.resetColumnFilters);
        if (tableInstance?.current?.resetColumnFilters) {
            tableInstance.current.resetColumnFilters();
        }
    }

    return (
        <React.Fragment>
            <Container fluid={true} className="mb-3">
                <Col lg={12} xl={9}>
                    <h2 className="mt-3">{title}
                        <span className="heading-info ms-2">{filteredRowsCount} / {data.length}</span>
                        {useRowSelection && selectedData && selectedData.length > 0 &&
                            <Button
                                color="link"
                                size="sm"
                                className="pb-1"
                                onClick={() => deselectAll()}>
                                Deselect all ({selectedData.length})
                            </Button>
                        }
                    </h2>
                </Col>
            </Container>
            <Container fluid={true} className="mt-3">
                <Col sm={12}>
                    <Row>
                        <ButtonToolbar>
                            {buttons?.length > 0 && buttons.map((button, index) => 
                                <Button 
                                    key={"button_" + index}
                                    color={button.color} 
                                    className="me-1" 
                                    onClick={() => button.onClick(getSelectedIds())}
                                    disabled={button.disabled}> 
                                    <img className="icon icon-with-text" src={button.icon} alt="" /> 
                                    {button.title} 
                                </Button> 
                            )}
                            <Button
                                color="primary"
                                className="me-1"
                                onClick={() => clearFiltersClicked()}
                                disabled={clearFilterDisabled}>
                                <img className="icon icon-with-text" src="./icons/button_clear_filters.svg" alt="" />
                                Clear filters
                            </Button>
                        </ButtonToolbar>
                    </Row>
                </Col>
            </Container>
            <Container fluid={true} className="mb-4">
                <Col lg={12} xl={tableXl}>
                    {loading && <CustomSpinner />}
                    {(!loading && msg) && <Error msg={msg} />}
                    {data?.length > 0 &&
                        <CustomTableWithFilters
                            tableColumns={columns}
                            tableData={data}
                            className={tableClass}
                            onSelectedChanged={onSelectedChanged}
                            pageSizes={pageSizes}
                            ref={tableInstance}
                            localStorageKey={localStorageKey}
                            onRowCountChanged={rowCountChanged}
                            useRowSelection={true}
                        />
                    }
                </Col>
            </Container>
        </React.Fragment>
    );
}