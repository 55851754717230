import { loginRequest } from './authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

async function innerCallAPI(url, setOptions, msalInstance, account) {
    // Silently acquires an access token which is then attached to a request for api.
    const token = await msalInstance
        .acquireTokenSilent({
            ...loginRequest,
            account: account,
        })
        .then((tokenResponse) => {
            return tokenResponse.accessToken;
        })
        .catch(async (e) => {
            // Catch interaction_required errors and call interactive method to resolve
            if (e instanceof InteractionRequiredAuthError) {
                await msalInstance.acquireTokenRedirect(loginRequest);
            }

            throw e;
        });

    let options = {
    headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    },
    ...setOptions
    };
    //console.log('innerCallAPI options', options);

    const defaultErrorMsg = "Something when wrong! ";
    var fetchResponse = await fetch(url, options)
    .then(async response => {
        //console.log('response', response);
        if (!response.ok){
            if (response.status === 500) {
                //console.log('response', response);
                return response.json()
                    .then(json => ({ json, response }))
                    .catch (error => {
                        throw new Error(defaultErrorMsg + response.statusText);
                    });
            }

            if (response.status === 403 || response.status === 401) {
                return { json: null, response: response };
            }

            if (response.status === 400) {
                return response.json()
                    .then(json => ({ json, response }))
                    .catch(error => {
                        throw new Error("Server validation error!");
                    });
            }

            throw new Error(defaultErrorMsg + response.statusText);
        }
    
        var jsonResponse = response.json();
        //console.log('jsonResponse', jsonResponse);
        return jsonResponse
            .then(json => ({ json, response }))
            .catch(error => {
                throw new Error(defaultErrorMsg);
            });
    })
    .then(({json, response}) =>{
        //console.log('response', response); console.log('json', json);
        if (response) {
            if (!response.ok) {
                //console.log('json.errorMessage', json.errorMessage)
                if (response.status === 401 || response.status === 403) {
                    return { errorMsg: "You are not authorized to make the request.", json: null, unAuthorized: true }
                }
                if (json.errors) {
                    //console.log('json.errors', Array.isArray(json.errors), json.errors);
                    if (typeof json.errors === "object") {
                        //console.log('Object.entries', Object.entries(json.errors));
                        // Let's loop through all validation errors
                        var validationErrors = Object.entries(json.errors).map(([key, value]) => {
                            return key + ": " + (Array.isArray(value) ? value.map(i => i).join(", ") : value) + " ";
                        });
                        return { errorMsg: validationErrors, json: null }
                    }
                    if (typeof json.errors === "string") {
                        return { errorMsg: json.errors, json: null }
                    }
                    return { errorMsg: "Server validation error!", json: null }
                }

                if (json.errorMessage) {
                    throw new Error(json.errorMessage);
                }

                throw new Error(defaultErrorMsg);
            }
            if (json) {
                return {errorMsg: null, json: json};
            }
        }
        throw new Error(defaultErrorMsg);
    })
    .catch(error => { 
        //console.log('Catched error!', error, error.message); 
        return {errorMsg: error?.message, json: null};
    }); 

    return fetchResponse;
}

export const callAPI = async (url, msalInstance, account, body, method = 'POST') => {
    //console.log('on callAPI method', url, JSON.stringify(body), msalInstance, account);
    let options = {};

    if (body){
        options = {
            body: JSON.stringify(body),
            method
        };
    }
        else{
        options = {
            method: 'GET'
        };
    }
  
    return await innerCallAPI(url, options, msalInstance, account);
}

export const callDeleteAPI = async (url, msalInstance, account, body, method = 'PUT') => {
    //console.log('on callDeleteAPI method', url);
    let options = { method: method };
    if (body){
        options = {body: JSON.stringify(body), ...options};
    }
    return await innerCallAPI(url, options, msalInstance, account);
}

export const callPublishAPI = async (url, msalInstance, account, body) => {
    let options = {method: 'PUT'};
    if (body) {
        options = {body: JSON.stringify(body), ...options};
    }
    return await innerCallAPI(url, options, msalInstance, account);
}