import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section, Action, EntityType } from '../../helpers/enums';
import StorageLayout from './StorageLayout';
import EntityLayout from '../common/EntityLayout';
import { UnitColumns } from './Columns';
import { setStorageUnits } from '.';
import { isStorageOwner } from '../../helpers/commonHelpers';
import workspaceActions from '../../actions/workspaceActions';

export function Units({ msalInstance }){
    const dispatch = useDispatch();

    const [enabledActions, setEnabledActions] = useState([]);

    let currentUser = useSelector(state => state.application.user);
    let selectedStorage = useSelector(state => state.workspace.activeItem);
    let groups = useSelector(state => state.workspace.storageUnitGroups);
    let selectedGroups = useSelector(state => state.workspace.selectedUnitGroups);

    const setValues = (entity, newValues, action) => {
        if (action && action === Action.EDIT){
            entity.code = newValues.code;
            entity.description = newValues.description;
        }
        entity.groupId = newValues.groupId;
        if (newValues.group){
            entity.group = newValues.group;
        }
        else if (groups && groups.length > 0){
            var selectedGroup = groups.filter(c => c.id === newValues.groupId);
            if (selectedGroup && selectedGroup.length > 0){
                entity.groupId = selectedGroup[0].name;
            }
        }
    }

    const columns = UnitColumns();

    const refreshStorageUnits = () => {
        setStorageUnits(selectedStorage.id, dispatch, msalInstance, currentUser.authUser);
    }

    const setUnits = (units) => {
        dispatch(workspaceActions.setStorageUnits(units));
    }

    useEffect(() => {
        if (currentUser) {
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }];
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            if (isStorageOwner(currentUser, selectedStorageId)) {
                userActions.push({
                    action: Action.CREATE,
                    disabled: selectedGroups.length === 0
                });
                userActions.push({ action: Action.EDIT });
                userActions.push({ action: Action.COPY });
                userActions.push({ action: Action.DELETE });
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage, selectedGroups]);

    return (
        <StorageLayout
            title="Manage units"
            showUnitGroupList={true}
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                <b>Unit</b> associates <b>element's value</b> with a known measure. Note: element does not need to have a unit, unless it is required by a <b>value type</b>.
                <br />Examples: <i>EUR (Euro)</i>, <i>USD (US Dollar)</i>, <i>kW (kilowatt)</i>, <i>hp (horse power)</i>, <i>mm (millimeter)</i>, <i>in (inch)</i>.
            </div>
            <EntityLayout
                type={Section.WORKSPACE}
                entityType={EntityType.UNIT}
                columns={columns}
                setValues={setValues}
                enabledActions={enabledActions}
                tableStyle="action-3"
                onEntitiesUpdated={refreshStorageUnits}
                msalInstance={msalInstance}
                onEntitiesFetched={setUnits}
            />
        </StorageLayout>
    );
}