import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ModalBase from '../../common/modals/ModalBase';
import FormModalBase from '../../common/modals/FormModalBase';
import { Action } from '../../../helpers/enums';
import { callAPI } from '../../../helpers/api';

const schema = yup.object().shape({});

export default function UserModal({
    isOpen,
    toggle,
    selectedUser,
    msalInstance,
    user
}) {
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const title = Action.ADD + " user";

    const { handleSubmit, reset, setError, formState: { errors, isValid } } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    const onSubmit = async () => {
        if (selectedUser && selectedUser.id) {
            setSubmitDisabled(true);
            let method = 'POST';
            let result = await callAPI("user", msalInstance, user,
                {
                    azureId: selectedUser.id,
                    name: selectedUser.displayName,
                    email: selectedUser.mail ?? selectedUser.userPrincipalName,
                    userPrincipal: selectedUser.userPrincipalName
                }, method);
            let newUser = result.json;
            if (result.errorMsg && !newUser) {
                setError("createError", {
                    type: "manual",
                    message: result.errorMsg
                });
            }
            else {
                setSubmitted(true);
                reset();
            }
        }
    }

    const modalIsClosed = () => {
        reset();
        setSubmitDisabled(false);
        setSubmitted(false);
    }

    useEffect(() => {
        //console.log('selectedUser', selectedUser);
        if (isOpen) {
            reset();
            setSubmitDisabled(false);
            setSubmitted(false);
        }
    }, [isOpen, reset]);

    if (!selectedUser || !selectedUser.id) {
        <ModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            subTitle="Not found!"
            formIsValid={isValid}>
            <p>No user selected!</p>
        </ModalBase>
    }

    let defaultValues = {
        name: selectedUser ? selectedUser.displayName : "",
        email: selectedUser ? (selectedUser.mail ?? selectedUser.userPrincipalName) : ""
    };
    
    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            onSubmit={handleSubmit(data => onSubmit(data))}
            actionButtonText={Action.ADD}
            formIsValid={isValid && !submitDisabled}
            onModalIsClosing={modalIsClosed}
            setNotLoading={(errors && Object.keys(errors).length > 0) || submitted ? 1 : 0}
        >
            <p>Add <span className="fw-bold">{defaultValues.name} ({defaultValues.email})</span> as a new user?</p>
            <p className="heading-info mt1">Note! Newly added user cannot access any storages before appropriate storage specific permissions are granted.</p>

            {errors.createError && <p className="text-danger">{errors.createError.message}</p>}
            {submitted && <p className="text-success">User {defaultValues.name} added successfully!</p>}
        </FormModalBase>
    );
}