import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import SubMenu from '../SubNavMenu';
import { getSubMenuItems } from '../../helpers/commonHelpers';

export default function SiteLayout({
    sideMenu,
    subMenuItems,
    children
}) {

    let currentUser = useSelector(state => state.application.user);
    let env = useSelector(state => state.application.environment);

    return (
        <div>
            <SubMenu menuItems={getSubMenuItems(subMenuItems, currentUser.highestRole)} />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="3" xl="2">
                        { sideMenu }
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="9" xl="10">

                        <hr className="mt-4 mb-4 d-lg-none" />
                        {
                            env !== "Production" &&
                            <Container fluid={true}>
                                <div className="mt-2 col-xl-10 alert alert-danger fade show" role="alert">
                                        <img src="./icons/test.svg" className="icon icon-alert-dialog me-2" alt="" />
                                        This application is running in <u>{env?.toLowerCase()} environment</u> and is not intended for production use cases. Data may be deleted or reset, and bugs can occur.
                                </div>
                            </Container>
                        }
                        { children }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}