import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { callPublishAPI } from '../../../helpers/api';
import ActionButtonModalBase from './ActionButtonModalBase';
import { Action, EntityStatus } from '../../../helpers/enums';

export default function PublishModal({
    isOpen,
    toggle,
    entityProperties,
    publishIds,
    selectedStorageId,
    publishStatus,
    subTitle,
    onItemIsPublished,
    user,
    msalInstance,
    children
}) {
    const [published, setPublished] = useState(0);

    const properties = entityProperties ?? [];
    const titlePart = publishIds && publishIds.length > 0 ?
        publishIds.length + " " + (publishIds.length > 1 ? properties.plural : properties.single) :
        " - No " + properties.plural + " selected!";
    
    const { reset, setError, formState: { errors } } = useForm();

    const publish = async () => {
        if (!properties.baseUrl || !publishIds || publishIds.length === 0){
            //console.log('Publish url was empty, lets just redirect to parent event!');
            onItemIsPublished();
            toggle();
        }
        else{
            let url = properties.baseUrl + 'publish?ids=' + publishIds.join(";") + '&storageId=' + selectedStorageId;
            url += "&status=" + publishStatus; //console.log('Publish url with status', url);
            let result = await callPublishAPI(url, msalInstance, user);//console.log('result', result);
            const publishResult = result.json;//console.log('publishResult', publishResult);
            if (result.errorMsg && !publishResult){
                setError("publishError", {
                    type:"manual",
                    message: result.errorMsg
                }); 
            }
            else{
                if (publishResult.publishedEntities){
                    onItemIsPublished(publishResult.publishedEntities);
                    if (publishResult.errorMsg){
                        setError("publishError", {
                            type:"manual",
                            message: publishResult.errorMsg
                        });
                    }
                    else{
                        toggle();
                    }
                }
                else{
                    onItemIsPublished(publishResult);
                    toggle();
                }
            }
        }
        setPublished(published + 1);
    }

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen, reset]);

    return(
        <ActionButtonModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={Action.PUBLISH + ' ' + titlePart}
            actionButtonText={"Publish to " + (publishStatus === EntityStatus.PROD ? "stage & production" : "stage")}
            subTitle={subTitle}
            className="modal-xl"
            onClick={() => publish(publishStatus)}
            formIsValid={true}
            setNotLoading={published}
        >
            {children}
            {errors.publishError && <p className="text-danger">{errors.publishError.message}</p>}
        </ActionButtonModalBase>
    )
}