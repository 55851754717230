import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, FormGroup, Label, Input } from 'reactstrap';
import { Error } from '../common/Error';

export default function StorageCheckboxList({
    selectedStorageIds,
    onStorageSelected,
    resetStates,
    disabled
}) {
    let storageList = useSelector(state => state.adminStorages.list);
    
    //const selectedStates = storageList ? storageList.reduce((allStates, item) => (
    //    {
    //        ...allStates,
    //        [item.id]: selectedStorageIds && selectedStorageIds.includes(item.id) ? true : false
    //    }), {}
    //) : {};

    const [checkedState, setCheckedState] = useState({});

    const setSelected = (e) => {
        var key = e.target.value;
        let newStates = { ...checkedState };
        newStates[key] = e.target.checked;
        setCheckedState(newStates);
        if (onStorageSelected) {
            onStorageSelected(Object.keys(newStates).filter(key => newStates[key] === true).map(Number));
        }
    }

    const resetAllCheckboxes = () => {
        let newStates = { ...checkedState };
        Object.keys(newStates).forEach(key => newStates[key] = false);
        setCheckedState(newStates);
    }

    useEffect(() => {
        if (resetStates) {
            //console.log('resetStates');
            resetAllCheckboxes();
        }
    }, [resetStates]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedStorageIds && selectedStorageIds.length > 0) {
            const selectedStates = storageList ? storageList.reduce((allStates, item) => (
                {
                    ...allStates,
                    [item.id]: selectedStorageIds && selectedStorageIds.includes(item.id) ? true : false
                }), {}
            ) : {};
            setCheckedState(selectedStates);
        }
        else {
            resetAllCheckboxes();
        }
    }, [selectedStorageIds]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!storageList || storageList.length < 1) {
        <Error msg="No storages found!" />
    }

    return (
        <Container className="checkboxlist m-2 ms-3 mt-0 me-0 p-3">
            {storageList && storageList.map((item, index) =>
                <FormGroup key={item.id} className="form-check">
                    <Input
                        type="checkbox"
                        id={"storage_" + index}
                        value={item.id}
                        onChange={setSelected}
                        checked={checkedState[item.id]}
                        style={{ cursor: 'pointer' }}
                        className="flex-shrink-0"
                        disabled={disabled === true}
                    />
                    <Label check for={"storage_" + index} className="mb-0">
                        <div className="d-flex flex-column ms-2 mb-2">
                            <span className="list-group-item-heading">{item.code}</span>
                            <span className="list-group-item-text">{item.name}</span>
                        </div>
                    </Label>
                </FormGroup>
            )}
        </Container>
    );
}