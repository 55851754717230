import React, { useState } from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { IconType, Role } from '../../helpers/enums';
import ListHeader from '../common/ListHeader';
import UserForm from './UserForm';
import AccessRightsForm from './AccessRightsForm'
import AdminLayout from './AdminLayout';

function Users({ msalInstance }) {
    const [activeMenuItem, setActiveMenuItem] = useState(1);
    const title = "User management";
    const icon = IconType.USER;

    const setActive = (item) => {
        //console.log('Selected item', item);
        setActiveMenuItem(item);
    }

    const sideMenu = <React.Fragment>
        <ListHeader
            title={title}
            showButtons={false}
            icon={icon}>
        </ListHeader>
        <ListGroup className="m-2 ms-3 me-3 me-lg-0">
            <ListGroupItem
                key="side_menu_item_1"
                action
                tag="button"
                className={activeMenuItem === 1 ? "border-0 active" : "border-0"}
                onClick={() => setActive(1)}
            >
                <ListGroupItemHeading className="mb-0">Add user</ListGroupItemHeading>
                <ListGroupItemText className="mb-0">Search user by name or email</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem
                key="side_menu_item_2"
                action
                tag="button"
                className={activeMenuItem === 2 ? "border-0 active" : "border-0"}
                onClick={() => setActive(2)}
            >
                <ListGroupItemHeading className="mb-0">Set permissions</ListGroupItemHeading>
                <ListGroupItemText className="mb-0">Manage user's access rights</ListGroupItemText>
            </ListGroupItem>
        </ListGroup>
    </React.Fragment>

    return (
        <AdminLayout
            title="Manage users"
            sideMenu={sideMenu}
            requiredRole={Role.Admin}
        >
            {activeMenuItem === 1 ?
                <UserForm msalInstance={msalInstance} /> :
                <AccessRightsForm populate={activeMenuItem === 2} msalInstance={msalInstance} />
            }
        </AdminLayout>
    );
}

export default Users;

