import React, { useEffect } from 'react';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { CustomSelect } from '../../common/CustomSelect';
import { extendedInputTypes } from '..';
import { Action } from '../../../helpers/enums';
import EntityModalBase from './EntityModalBase';

const schema = yup.object().shape({
    code: yup.string().label('Code').required().max(50),
    type: yup.object().shape({ value: yup.number().required('Select a Type') }).nullable().required('Select a Type'),
    namespace: yup.string().label('Namespace').max(50),
    description: yup.string().label('Description').required().max(100)
});

export default function ClientInterfaceModal({
    isOpen,
    toggle,
    status,
    selectedClientInput,
    onEntityCreated,
    onEntityUpdated,
    msalInstance
}) {
    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, clearErrors, setValue, trigger } = useForm({resolver: yupResolver(schema), mode: 'onChange'});
    
    const defaultValues = {
        id: 0,
        code: "",
        type: [],
        namespace: "",
        description: ""
    };

    const getData = (data) => {
        let { type, ...body } = data;
        if (data.type) {
            body.typeId = data.type.value;
        }
        return body;
    }

    const handleCodeChange = (input) => {
        clearErrors('submitError');
        trigger('submitError');
        setValue('code', input.target.value);
        trigger('code');
    }

    useEffect(() => {
        if (selectedClientInput && selectedClientInput.id > 0) {
            if (status !== Action.COPY) {
                setValue('id', selectedClientInput.id);
            }
            setValue('code', selectedClientInput.code);
            setValue('type', { value: selectedClientInput.typeId, label: selectedClientInput.type });
            setValue('namespace', selectedClientInput.namespace);
            setValue('description', selectedClientInput.description);
            trigger();
        }
        else {
            setValue('id', 0);
            setValue('code', "");
            setValue('type', []);
            setValue('namespace', "");
            setValue('description', "");
        }
    }, [selectedClientInput, status, setValue, trigger]);
    
    return (
        <EntityModalBase
            isOpen={isOpen}
            toggle={toggle}
            status={status}
            titleBase="client input"
            baseUrl="clientInput"
            getData={getData}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            entityIsCreated={onEntityCreated}
            entityIsUpdated={onEntityUpdated}
            msalInstance={msalInstance}
        >
            <Controller name="id"
                control={control}
                defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <FormGroup row className="required form-group">
                <Label for="code" sm={3}>Input code</Label>
                <Col sm={9}>
                    <Controller name="code"
                        control={control}
                        defaultValue={defaultValues.code}
                        render={({ field }) =>
                            <Input {...field}
                                type="text"
                                disabled={status === Action.EDIT && selectedClientInput?.allReferences > 0}
                                onChange={handleCodeChange}
                            />
                        }
                    />
                    {errors.code && <p className="text-danger">{errors.code.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="description" sm={3}>Description</Label>
                <Col sm={9}>
                    <Controller name="description"
                        control={control}
                        defaultValue={defaultValues.description}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="type" sm={3}>Type</Label>
                <Col sm={9}>
                    <Controller name="type"
                        control={control}
                        defaultValue={defaultValues.type}
                        render={({ field }) => 
                            <CustomSelect {...field}
                                options={extendedInputTypes()}
                                isDisabled={status === Action.VIEW}
                            />
                        }
                    />
                    {errors.type && <p className="text-danger">{errors.type.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="namespace" sm={3}>Namespace</Label>
                <Col sm={9}>
                    <Controller name="namespace"
                        control={control}
                        defaultValue={defaultValues.namespace}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                    {errors.namespace && <p className="text-danger">{errors.namespace.message}</p>}
                </Col>
            </FormGroup>
        </EntityModalBase>
    );
}