import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import inspectorActions from '../../actions/inspectorActions';
import { IconType } from '../../helpers/enums';
import ListHeader from '../common/ListHeader';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { CreateButton } from '../common/CreateButton';

export default function QueryList({
    queries,
    onAddNew
}) {
    let activeQuery = useSelector(state => state.inspector.activeItem);
    const dispatch = useDispatch();
    
    const icon = IconType.TEST;

    const addNewQuery = () => {
        setActive({id: 0});
        if (onAddNew) {
            onAddNew();
        }
    }

    const setActive = (q) => {
        dispatch(inspectorActions.setActiveQuery(q));
        dispatch(inspectorActions.queryTabsHidden(false));
    }

    return (
        <React.Fragment>
            <ListHeader
                title="Inspections"
                showButtons={true}
                icon={icon}>
                <CreateButton title="Add" onClick={addNewQuery} />
            </ListHeader>
            {queries?.length > 0 &&
                <ListGroup className="m-2 ms-3 me-3 me-lg-0">
                    {queries.map(q =>
                        <ListGroupItem key={q.id} action
                            tag="button"
                            className={q.id === activeQuery.id ? "border-0 active" : "border-0"}
                            onClick={() => setActive(q)}
                        >
                            <ListGroupItemHeading className="mb-0">
                                {q.name}
                            </ListGroupItemHeading>
                        </ListGroupItem>
                    )}
                </ListGroup>
            }
        </React.Fragment>
    );
}