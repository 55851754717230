import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StorageLayout from './StorageLayout';
import { Section, Action, EntityType } from '../../helpers/enums';
import EntityLayout from '../common/EntityLayout';
import { UnitGroupColumns } from './Columns';
import { setStorageUnitGroups } from '.';
import { isStorageOwner } from '../../helpers/commonHelpers';
import workspaceActions from '../../actions/workspaceActions';

export function UnitGroups({ msalInstance }){
    const dispatch = useDispatch();

    const [enabledActions, setEnabledActions] = useState([]);

    let selectedStorage = useSelector(state => state.workspace.activeItem);
    let currentUser = useSelector(state => state.application.user);

    const setValues = (entity, newValues, action) => {
        if (action && action === Action.EDIT) {
            entity.name = newValues.name;
        }
    }

    const refreshStorageUnitGroups = () => {
        setStorageUnitGroups(selectedStorage.id, dispatch, msalInstance, currentUser.authUser);
    }

    const setUnitGroups = (unitGroups) => {
        dispatch(workspaceActions.setStorageUnitGroups(unitGroups));
    }

    useEffect(() => {
        if (currentUser) {
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }];
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            if (isStorageOwner(currentUser, selectedStorageId)) {
                userActions.push({ action: Action.CREATE });
                userActions.push({ action: Action.EDIT });
                userActions.push({ action: Action.COPY });
                userActions.push({ action: Action.DELETE });
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage]);

    return (
        <StorageLayout
            title="Manage unit groups"
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                <b>Unit group</b> is a container for <b>units</b> of the same kind. Each group can only contain units that have a unique unit code.
                <br />Examples: <i>currency</i>, <i>power</i>, <i>length</i>.
            </div>
            <EntityLayout
                type={Section.WORKSPACE}
                entityType={EntityType.UNITGROUP}
                columns={UnitGroupColumns()}
                setValues={setValues}
                enabledActions={enabledActions}
                separateFilterField={true}
                tableStyle="action-3"
                onEntitiesUpdated={refreshStorageUnitGroups}
                msalInstance={msalInstance}
                onEntitiesFetched={setUnitGroups}
            />
        </StorageLayout>
    );
}