import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, ButtonToolbar, Button } from 'reactstrap';
import { X } from 'react-bootstrap-icons';
import { CustomSpinner } from '../CustomSpinner';
import Draggable from "react-draggable";

function ModalBase({
    isOpen,
    toggle,
    title,
    subTitle,
    className,
    actionButtons = "",
    onModalIsClosing,
    children,
    loading = false,
    headerClassName = "text-white"
}) {
    const closeModal = () => {
        if (onModalIsClosing) {
            onModalIsClosing();
        }
        toggle();
    }
    //console.log('loading', loading);
    //console.log('actionButtons', actionButtons);

    const closeBtn = <Button className="modal-x-btn border-0 p-0" onClick={closeModal}><X size={30} /></Button>;
    
    let info = subTitle ? subTitle : "";

    return (
        <Container>
            <Draggable
                handle=".handle"
            >

                <Modal
                    isOpen={isOpen}
                    className={className + " no-cursor"}
                    centered scrollable
                    keyboard={true}
                    toggle={toggle}
                    fade={false}
                >
                    <ModalHeader className={headerClassName + " bg-dark fw-normal cursor handle"} toggle={closeModal} close={closeBtn}>
                        {title}
                        {(info.length > 0 || typeof info === "object") && <span className="heading-info ms-3">{info}</span>}
                    </ModalHeader>
                    <ModalBody>
                        {children}
                    </ModalBody>
                    <ModalFooter className={loading ? "justify-content-center" : ""}>
                        {
                            loading ? <CustomSpinner margin={false} /> :
                                <ButtonToolbar className="mb-3">
                                    {actionButtons}
                                    <Button outline color="primary" onClick={() => closeModal()}>Cancel</Button>
                                </ButtonToolbar>
                        }
                    </ModalFooter>
                </Modal>
            </Draggable>
        </Container>
    );
}

export default ModalBase;