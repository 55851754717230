import { ActionTypes } from "../helpers/enums"

const setUser = (userObj) => {
    return {
        type: ActionTypes.SET_USER,
        payload: userObj
    };
}

const setUserUnauthorized = (unauthorized) => {
    return {
        type: ActionTypes.SET_USER_UNAUTHORIZED,
        payload: unauthorized
    };
}

const setEnvironment = (env) => {
    return {
        type: ActionTypes.SET_ENVIRONMENT,
        payload: env
    };
}

const setFlags = (flags) => {
    return {
        type: ActionTypes.SET_APPLICATION_FLAGS,
        payload: flags
    };
}

let applicationActions = { setUser, setUserUnauthorized, setEnvironment, setFlags };

export default applicationActions;