import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ListHeader from '../common/ListHeader';
import { CustomSpinner } from '../common/CustomSpinner';
import { Error } from '../common/Error';
import CheckboxList from '../common/CheckboxList';
import { Section, IconType } from '../../helpers/enums';

export default function UnitGroupList({ setSelectedUnitGroups }){
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    //const [list, setList] = useState([]);
    const title = "Groups";
    const icon = IconType.GROUP;

    let property = Section.WORKSPACE;
    let groups = useSelector(state => state[property].storageUnitGroups);
    let selectedGroups = useSelector(state => state[property].selectedUnitGroups);//console.log('selectedGroups', selectedGroups);
    let isStorageDataLoading = useSelector(state => state[property].storageDataLoading);
    
    const setActive = (ids) => {
        //console.log('Selected unit groups', ids);
        setSelectedUnitGroups(ids);
    }
    
    useEffect(() => {
        if (isStorageDataLoading){
            setIsLoading(true);
            //setList([]);
        }
        else{
            setIsLoading(false);
            if (!groups || groups.length === 0){
                setError("No unit groups found!");
            }
            else {
                setError(null);//console.log('groups', groups);
            }
        }
    }, [isStorageDataLoading, groups]);

    if (loading){
        return (
            <>
                <ListHeader title={title} icon={icon}></ListHeader>
                <CustomSpinner />
            </>
        );
    }

    if (error){
        return (
            <>
                <ListHeader title={title} icon={icon}></ListHeader>
                <Error msg={error} />
            </>
        );
    }

    return (
        <CheckboxList title={title}
            listItems={groups} 
            onItemSelected={setActive}
            selectedItems={selectedGroups}
            showButtons={true}
            icon={icon} />
    );
}