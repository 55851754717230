import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section, Action, EntityType } from '../../helpers/enums';
import StorageLayout from './StorageLayout';
import EntityLayout from '../common/EntityLayout';
import { ElementTypeColumns } from './Columns';
import { setStorageElementTypes } from '.';
import { isStorageOwner } from '../../helpers/commonHelpers';
import workspaceActions from '../../actions/workspaceActions';

export function ElementTypes({ msalInstance }){
    const dispatch = useDispatch();

    const [enabledActions, setEnabledActions] = useState([]);

    let selectedStorage = useSelector(state => state.workspace.activeItem);
    let currentUser = useSelector(state => state.application.user);

    const setValues = (entity, newValues, action) => { //console.log('action, entity and new values', action, entity, newValues);
        if (action && action === Action.EDIT){
            entity.code = newValues.code;
            entity.description = newValues.description;
            entity.types = newValues.types;
            entity.valueTypes = newValues.valueTypes;
        }
    }

    const refreshStorageEntityTypes = () => { //console.log('refresh element types!');
        setStorageElementTypes(selectedStorage.id, dispatch, msalInstance, currentUser.authUser);
    }

    const setElementTypes = (elementTypes) => {
        dispatch(workspaceActions.setStorageElementTypes(elementTypes));
    }

    useEffect(() => {
        if (currentUser) {
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }];
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            if (isStorageOwner(currentUser, selectedStorageId)) {
                userActions.push({ action: Action.CREATE });
                userActions.push({ action: Action.EDIT });
                userActions.push({ action: Action.COPY });
                userActions.push({ action: Action.DELETE });
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage]);

    return (
        <StorageLayout
            title="Manage element types"
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                List here <b>element types</b> and their applicable <b>value types</b> that can be used to characterize <b>items</b>.
            </div>
            <EntityLayout
                type={Section.WORKSPACE}
                entityType={EntityType.ELEMENTTYPE}
                columns={ElementTypeColumns()}
                setValues={setValues}
                enabledActions={enabledActions}
                tableStyle="action-3"
                onEntitiesUpdated={refreshStorageEntityTypes}
                msalInstance={msalInstance}
                onEntitiesFetched={setElementTypes}
            />
        </StorageLayout>
    );
}