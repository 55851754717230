import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { callDeleteAPI } from '../../../helpers/api';
import ModalBase from './ModalBase';
import FormModalBase from './FormModalBase';
import { Action } from '../../../helpers/enums';

function DeleteModal({
    isOpen,
    toggle,
    //url,
    entityProperties,
    deleteIds,
    onDeleted,
    onDeleteModalIsClosing,
    subTitle,
    storageId,
    user,
    msalInstance,
    children,
    title = Action.DELETE
}){
    const properties = entityProperties ?? {};
    const confirmText = properties.confirmText;
    const titlePart = deleteIds?.length > 0 ?
        deleteIds.length + " " + (deleteIds.length > 1 ? properties.plural : properties.single) :
        " - No " + properties.plural + " selected!";
    const deleteTitle = title + " " + titlePart;

    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, setValue } = useForm({mode: 'onChange'});
    //console.log('delete props', props);
    const [accepted, setAccepted] = useState(confirmText?.length > 0 ? false : true);
    const [resetCount, setResetCount] = useState(0);

    const deleteEntity = async () => {
        //console.log('Delete deleteIds', deleteIds);
        setAccepted(confirmText?.length > 0 ? false : true);
        if (!properties.baseUrl || !deleteIds || deleteIds?.length === 0 || deleteIds.filter(id => id !== 0).length === 0) {
            //console.log('Delete url was empty, lets just redirect to parent event!');
            if (onDeleted) {
                onDeleted();
            }
            toggle();
        }
        else{
            let url = properties.baseUrl + 'deleteByList?ids=' + deleteIds.join(";") + "&storageId=" + (storageId ?? 0);
            if (properties.additionalUrlPart) {
                url += properties.additionalUrlPart;
            }
            let result = await callDeleteAPI(url, msalInstance, user);//console.log('result', result);
            const deletedResult = result.json;
            if (result.errorMsg && !deletedResult){
                setError("deleteError", {
                    type:"manual",
                    message: result.errorMsg
                }); 
            }
            else{
                if (deletedResult.errorMsg){
                    setError("deleteError", {
                        type:"manual",
                        message: deletedResult.errorMsg
                    }); 
                    if (deletedResult.deletedEntities && onDeleted){
                        onDeleted(deletedResult.deletedEntities);
                    }
                }
                else if (onDeleted){
                    if (deletedResult.deletedEntities){
                        onDeleted(deletedResult.deletedEntities);
                    }
                    else{
                        onDeleted(deletedResult);
                    }
                    toggle();
                }
            }
        }
        setResetCount(resetCount + 1);
    }

    const modalIsClosed = () => {
        reset();//console.log('Modal reset!');
        setResetCount(resetCount + 1);
        setAccepted(confirmText?.length > 0 ? false : true);
        if (onDeleteModalIsClosing){
            onDeleteModalIsClosing();
        }
    }

    const handleAcceptedChange = (e) => {
        const isChecked = e.target.checked; 
        setAccepted(isChecked);
        setValue('accept', isChecked);
    }

    useEffect(() => {
        if (deleteIds && entityProperties && entityProperties.single) {
            setResetCount(resetCount + 1);
            if (entityProperties.confirmText?.length > 0) {
                setAccepted(false);
                setValue('accept', false);
            }
        }
    }, [deleteIds, entityProperties]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!properties || !properties.baseUrl) {
        return (
            <ModalBase
                isOpen={isOpen}
                toggle={toggle}
                title={deleteTitle}
                subTitle="Not found!"
            >
                <p>No valid configurations found. Please check!</p>
            </ModalBase>)
    }

    return(
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            onSubmit={handleSubmit(() => deleteEntity())}
            onModalIsClosing={modalIsClosed}
            title={deleteTitle}
            subTitle={subTitle}
            actionButtonText={title}
            actionButtonColor="danger"
            className={properties.deleteModalClass}
            formIsValid={accepted && isValid}
            headerClassName="text-danger"
            setNotLoading={resetCount}
        >
            { confirmText && 
                <FormGroup row className="form-group">
                    <Col>
                        <Controller name="accept" control={control} defaultValue={accepted}
                            render={({field}) => <Input {...field} id="accept" type="checkbox" onChange={handleAcceptedChange} checked={accepted} className="danger" style={{cursor:'pointer'}}/>}
                        />
                        <Label for="accept" className="text-danger ps-2">{confirmText}</Label>
                    </Col>
                </FormGroup>
            }
            {children}
            {errors.deleteError && <p className="text-danger">{errors.deleteError.message}</p>}
        </FormModalBase>
    )
}

export default DeleteModal;