import React, { useState, useEffect } from 'react';
import ActionButtonModalBase from '../../common/modals/ActionButtonModalBase';
import { Action } from '../../../helpers/enums';
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { FormGroup, Label, Col } from 'reactstrap';
import { SelectWithStyles } from '../../common/CustomSelect';
import TextareaAutosize from 'react-textarea-autosize';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getFlagOptions } from '../../../helpers/commonHelpers';

const schema = yup.object().shape({
    flag: yup.object().shape({ value: yup.number().required('Select a Flag') })
        .nullable().required('Select a Flag'),
});

export default function FlagModal({
    isOpen,
    toggle,
    status,
    selectedFlag,
    existingFlags,
    onFlagUpdated,
    onFlagAdded
}) {
    const [operation, setOperation] = useState(0);

    let applicationFlags = useSelector(state => state.application.flags);
    let availableFlags = status === Action.ADD && applicationFlags?.length > 0 && existingFlags?.length > 0 ?
        applicationFlags.filter(f => !((existingFlags.map(e => e.flagId)).includes(f.id)))
        : applicationFlags;
    const title = status + " item flag";

    const flagOptions = getFlagOptions(availableFlags);

    const { reset, formState: { errors, isValid }, control, getValues, setValue } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    const flagAdded = () => {
        const data = getValues();
        if (onFlagAdded) {
            onFlagAdded(data);
        }
        toggle();
        modalIsClosed();
        setOperation(operation + 1);
    }

    const flagUpdated = () => {
        const roleData = getValues();
        if (onFlagUpdated) {
            onFlagUpdated(roleData);
        }
        toggle();
        modalIsClosed();
        setOperation(operation + 1);
    }

    const modalIsClosed = () => {
        reset();
    }

    let defaultValues = {
        flag: "",
        comments: ""
    }

    useEffect(() => {
        //console.log('selectedFlag', selectedFlag);
        if (selectedFlag?.flagId > 0) {
            setValue('flag', flagOptions.find(f => f.value === selectedFlag.flagId));
            setValue('comments', selectedFlag.comments);
        }
        else {
            setValue('flag', flagOptions[0]);
            setValue('comments', "");
        }
    }, [selectedFlag]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActionButtonModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            subTitle=""
            onClick={status === Action.ADD ? flagAdded : flagUpdated}
            formIsValid={isValid}
            onModalIsClosing={modalIsClosed}
            setNotLoading={operation}
        >
            <FormGroup row className="required form-group">
                <Label for="factory" sm={3}>Flag</Label>
                <Col sm={9}>
                    <Controller name="flag" control={control} defaultValue={defaultValues.flag} render={({ field }) =>
                        <SelectWithStyles {...field} options={flagOptions}
                            isDisabled={status === Action.EDIT} />
                    }
                    />
                    {errors.factory && <p className="text-danger">{errors.factory.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="comments" sm={3}>Comments</Label>
                <Col sm={9}>
                    <Controller name="comments" control={control} defaultValue={defaultValues.comments}
                        render={({ field }) => <TextareaAutosize {...field} maxRows={10} minRows={2} className="form-control" />}
                    />
                </Col>
            </FormGroup>
        </ActionButtonModalBase>
    )
}