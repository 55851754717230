import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Action, ConnectionType, EntityType, BoolenText, Role, PublishingRole } from '../../helpers/enums';
import { ElementColumns, ElementLogColumns, ElementColumnsUserAndDateCombined, ElementLogHistoryColumns } from './Columns';
import EntityLayout from '../common/EntityLayout';
import { Container, Col, Button } from 'reactstrap';
import { Error } from '../common/Error';
import { hasContributorRole, hasRoleForStorageAndFactory, hasPublishingRole, isStorageOwner, getPropertyValueByKey } from '../../helpers/commonHelpers';
import LogSearchModal from './modals/LogSearchModal';
import { getEntityProperties } from '../common/EntityProperties';

export function ElementSearch({
    type, 
    onElementsUpdated,
    msalInstance
}){
    let currentUser = useSelector(state => state.application.user); //console.log('currentUser', currentUser);
    let selectedStorage = useSelector(state => state[type].activeItem);
    let selectedItems = useSelector(state => state[type].selectedItems);
    let factories = useSelector(state => state[type].factories);
    let selectedFactories = useSelector(state => state[type].selectedFactories);
    let selectedElementTypes = useSelector(state => state[type].selectedElementTypes);
    let noItemsFound = useSelector(state => state[type].noItemsFound);
    const selectedStorageId = selectedStorage ? selectedStorage.id : 0;

    const [enabledActions, setEnabledActions] = useState([]);
    const [showLogModal, setShowLogModal] = useState(false);

    const setValues = (entity, newValues, action) => {
        //console.log('old & new', entity, newValues);
        if (action && (action === Action.EDIT || action === Action.PUBLISH)) {
            entity.code = newValues.code;
            entity.comments = newValues.comments;
            entity.connectionTypeId = newValues.connectionTypeId;
            entity.connectionType = newValues.connectionTypeId === 0 ? ConnectionType.ALWAYS : ConnectionType.CRITERION;
            entity.criterion = newValues.criterion;
            entity.elementTypeId = newValues.elementTypeId;
            entity.evaluationOk = newValues.evaluationOk;
            entity.factory = newValues.factory;
            entity.factoryId = newValues.factoryId;
            entity.factorySymbol = newValues.factorySymbol;
            entity.input = newValues.input;
            entity.internalId = newValues.internalId;
            entity.internalNotes = newValues.internalNotes;
            entity.isSubstitutable = newValues.isSubstitutable;
            entity.itemCode = newValues.itemCode;
            entity.itemDescription = newValues.itemDescription;
            entity.prodStatus = newValues.prodStatus;
            entity.revisionId = newValues.revisionId;
            entity.revisions = newValues.revisions;
            entity.stageStatus = newValues.stageStatus;
            entity.substitutable = { value: newValues.isSubstitutable, label: newValues.isSubstitutable ? BoolenText.TRUE : BoolenText.FALSE };
            entity.type = newValues.type;
            entity.unit = newValues.unit;
            entity.unitId = newValues.unitId;
            entity.updatedBy = newValues.updatedBy;
            entity.updatedDate = newValues.updatedDate;
            entity.valueType = newValues.valueType;
            entity.isConfigElement = newValues.isConfigElement;
        }

        entity.value = newValues.value ?? "";

        if (newValues.valueType) {
            if (!newValues.valueType.typeId) {
                entity.valueType.typeId = newValues.valueType.type;
            }
        }

        entity.disabledSelect = !hasRoleForStorageAndFactory(currentUser, Role.CONTRIBUTOR, selectedStorageId, newValues.factoryId);
        // For configuration elements user needs to be a storage owner
        if (entity.isConfigElement && !entity.disabledSelect && !isStorageOwner(currentUser, selectedStorageId)) {
            entity.disabledSelect = true;
        }
    }

    const toggleLogModal = () => {
        //console.log('selectedEntity', selectedEntity);
        setShowLogModal(!showLogModal);
    }

    useEffect(() => {
        if (currentUser) {
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }, { action: Action.LOG }];
            if (hasContributorRole(currentUser, selectedStorageId, selectedFactories)) {
                userActions.push({
                    action: Action.CREATE,
                    disabled: selectedItems.length === 0 || selectedFactories.length === 0 || selectedElementTypes.length === 0
                });
                userActions.push({
                    action: Action.EDIT,
                });
                userActions.push({
                    action: Action.COPY,
                });
                userActions.push({
                    action: Action.DELETE,
                });
                if (hasPublishingRole(currentUser, PublishingRole.STAGE, selectedStorageId, selectedFactories, false) ||
                    hasPublishingRole(currentUser, PublishingRole.PROD, selectedStorageId, selectedFactories, false)) {
                    userActions.push({
                        action: Action.PUBLISH,
                    });
                }
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage, selectedFactories, selectedElementTypes, selectedItems, selectedStorageId]);

    const logHistorySearch = <React.Fragment>
        <Container fluid={true}>
            <Button
                color="link"
                size="sm"
                className="pb-1"
                onClick={() => toggleLogModal()}>
                Log history
            </Button>
        </Container>
        <LogSearchModal
            isOpen={showLogModal}
            toggle={toggleLogModal}
            entityProperties={getEntityProperties(EntityType.ELEMENT)}
            columns={ElementLogHistoryColumns()}
            user={currentUser.authUser}
            msalInstance={msalInstance} >
        </LogSearchModal>
    </React.Fragment>

    if (!factories || factories.length === 0 || noItemsFound){
        return (<></>);
    }

    if (!selectedItems || selectedItems.length === 0){
        //console.log('No factories or items selected!');
        return (
            <React.Fragment>
                { logHistorySearch }
                <Container fluid={true} className="mt-4">
                    <Col lg={12} xl={10}>
                        <Error msg={"Please select at least one item to display elements."} />
                    </Col>
                </Container>
            </React.Fragment>
        );
    }

    return (
        <EntityLayout
            type={type}
            entityType={EntityType.ELEMENT}
            columns={ElementColumns()}
            setValues={setValues}
            enabledActions={enabledActions}
            logColumns={ElementLogColumns()}
            onEntitiesUpdated={onElementsUpdated}
            combinedColumns={ElementColumnsUserAndDateCombined()}
            msalInstance={msalInstance}
        >
            { logHistorySearch }
        </EntityLayout>
    );
}