import React, { useState, useEffect } from 'react';
import FactoryList from './FactoryList';
import FactoryForm from './FactoryForm';
import { useSelector } from 'react-redux';
import AdminLayout from './AdminLayout';
import { Role } from '../../helpers/enums';

function Factories({ msalInstance }) {
    let activeFactory = useSelector(state => state.adminFactories.activeItem);

    const editTitle = "Manage factory";
    const [title, setTitle] = useState(editTitle);

    const sideMenu = <FactoryList msalInstance={msalInstance} />

    useEffect(() => {
        if (activeFactory.id > 0) {
            setTitle(editTitle);
        }
        else {
            setTitle("Create new factory");
        }
    }, [activeFactory.id]);

    return (
        <AdminLayout
            title={title}
            sideMenu={sideMenu}
            requiredRole={Role.ADMIN}
        >
            <FactoryForm msalInstance={msalInstance}></FactoryForm>
        </AdminLayout>
    );
}

export default Factories;