import { Button } from "reactstrap";

export function CreateButton({
    title="Create",
    onClick }) {
    return (
        <Button color="primary" size="md" className="ms-1 align-self-center" onClick={onClick}>
            {title}
        </Button>
    )
}