export const ActionTypes = {
    // Admin 
    SET_ADMIN_FACTORIES: "SET_ADMIN_FACTORIES",
    SET_ADMIN_ACTIVE_FACTORY: "SET_ADMIN_ACTIVE_FACTORY",

    SET_ADMIN_STORAGES: "SET_ADMIN_STORAGES",
    SET_ADMIN_ACTIVE_STORAGE: "SET_ADMIN_ACTIVE_STORAGE",
    SET_ADMIN_STORAGE_FACTORIES: "SET_ADMIN_STORAGE_FACTORIES",
    // Storages
    SET_STORAGES: "SET_STORAGES",
    SET_STORAGES_ACTIVE_STORAGE: "SET_STORAGES_ACTIVE_STORAGE",
    SET_STORAGES_LOADING: "SET_STORAGES_LOADING",
    SET_STORAGES_LOADING_DATA: "SET_STORAGES_LOADING_DATA",
    SET_STORAGES_LOADING_ENTITIES: "SET_STORAGES_LOADING_ENTITIES",
    SET_STORAGES_NO_ITEMS_FOUND: "SET_STORAGES_NO_ITEMS_FOUND",
    SET_STORAGES_REFRESH_DATE: "SET_STORAGES_REFRESH_DATE",
    SET_STORAGES_SELECTED_ELEMENTTYPES: "SET_STORAGES_SELECTED_ELEMENTTYPES",
    SET_STORAGES_SELECTED_ELEMENTS: "SET_STORAGES_SELECTED_ELEMENTS",
    SET_STORAGES_SELECTED_ITEMS: "SET_STORAGES_SELECTED_ITEMS",
    SET_STORAGES_SELECTED_FACTORIES: "SET_STORAGES_SELECTED_FACTORIES",
    SET_STORAGES_SELECTED_UNITGROUPS: "SET_STORAGES_SELECTED_UNITGROUPS",
    SET_STORAGES_SELECTED_VALUETYPES: "SET_STORAGES_SELECTED_VALUETYPES",
    SET_STORAGES_STORAGE_CATEGORIES: "SET_STORAGES_STORAGE_CATEGORIES",
    SET_STORAGES_STORAGE_ELEMENT_TYPES: "SET_STORAGES_STORAGE_ELEMENT_TYPES",
    SET_STORAGES_STORAGE_FACTORIES: "SET_STORAGES_STORAGE_FACTORIES",
    SET_STORAGES_STORAGE_UNITS: "SET_STORAGES_STORAGE_UNITS",
    SET_STORAGES_STORAGE_VALUETYPES: "SET_STORAGES_STORAGE_VALUETYPES",
    SET_STORAGES_STORAGE_UNITGROUPS: "SET_STORAGES_STORAGE_UNITGROUPS",
    // Current user
    SET_USER: "SET_USER",
    SET_USER_UNAUTHORIZED: "SET_USER_UNAUTHORIZED",
    // Application
    SET_ENVIRONMENT: "SET_ENVIRONMENT",
    SET_APPLICATION_FLAGS: "SET_APPLICATION_FLAGS",
    // Inspector
    SET_QUERY_ACTIVE: "SET_QUERY_ACTIVE",
    SET_QUERY_NO_ITEMS_FOUND: "SET_QUERY_NO_ITEMS_FOUND",
    SET_QUERY_HIDE: "SET_QUERY_HIDE"
}

export const Section = {
    ADMIN_STORAGES: "adminStorages",
    ADMIN_FACTORIES: "adminFactories",
    FACTORIES: "factories",
    WORKSPACE: "workspace",
    INSPECTOR: "inspector"
}

export const Action = {
    CREATE: "Create",
    CREATE_NEW_REVISION: "Revise",
    EDIT: "Edit",
    DELETE: "Delete",
    COPY: "Copy",
    PUBLISH: "Publish",
    LOG: "revision log",
    SETTINGS: "Settings",
    ADD: "Add",
    CHANGE: "Change",
    VIEW: "View",
    FLAG: "Flags"
}

export const ConnectionType = {
    ALWAYS: "Always",
    CRITERION: "Criterion"
}

export const ValueTypeOrigin = {
    DEFAULT: "Default",
    CUSTOM: "Custom"
}

export const RevisionStatus = {
    NO_REVISION: 0,
    EARLIER_REVISION: 1,
    LATEST_REVISION: 2,
}

export const RevisionStatusText = {
    NO_REVISION: "None",
    LATEST_REVISION: "Latest",
    EARLIER_REVISION: "Earlier"
}

export const EntityStatus = {
    INWORK: 0,
    DELETED: 1,
    STAGE: 2,
    PROD: 3
}

export const EntityStatusText = {
    INWORK: "Work",
    DELETED: "Deleted",
    STAGE: "Stage",
    PROD: "Production"
}

export const ElementStatusText = {
    NONE: "None",
    UNPUBLISHED: "Unpublished",
    PUBLISHED: "Published",
    WITHOUT: "Without elements",
    WITH: "With elements",
    SOME: "Some unpublished",
    ALL: "All published"
}

export const UserInputType = {
    STRING: 0,
    INTEGER: 1,
    FLOAT: 2,
    STRING_ARRAY: 3,
    INTEGER_ARRAY: 4,
    FLOAT_ARRAY: 5,
    STRING_DICT: 6,
    INTEGER_DICT: 7,
    FLOAT_DICT: 8,
    STRING_ARRAY_DICT: 9,
    INTEGER_ARRAY_DICT: 10,
    FLOAT_ARRAY_DICT: 11
}

export const UserInputTypeText = {
    STRING: "String",
    INTEGER: "Integer",
    FLOAT: "Float",
    CUSTOM: "Custom",
    STRING_ARRAY: "Array: string",
    INTEGER_ARRAY: "Array: integer",
    FLOAT_ARRAY: "Array: float",
    STRING_DICT: "Dictionary: string, string",
    INTEGER_DICT: "Dictionary: string, int",
    FLOAT_DICT: "Dictionary: string, float",
    STRING_ARRAY_DICT: "Dictionary: string, array-string",
    INTEGER_ARRAY_DICT: "Dictionary: string, array-integer",
    FLOAT_ARRAY_DICT: "Dictionary: string, array-float"
}

export const BoolenText = {
    FALSE: "No",
    TRUE: "Yes"
}

export const EntityType = {
    ITEM: "Item",
    ELEMENT: "Element",
    PARAMETER: "Parameter",
    CATEGORY: "Category",
    VALUETYPE: "ValueType",
    CLIENTINTERFACE: "ClientInterface",
    UNIT: "Unit",
    ELEMENTTYPE: "ElementType",
    UNITGROUP: "UnitGroup",
    STORAGE: "Storage",
    FACTORY: "Factory"
}

export const IconType = {
    FOLDER: "Folder",
    BUILDING: "Building",
    PUZZLE: "Puzzle",
    GROUP: "Group",
    USER: "User",
    TEST: "Test"
}

export const UnitType = {
    NONE: 0,
    OPTIONAL: 1,
    NOT_PREDEFINED: 2,
    GROUP_PREDEFINED: 3,
    UNIT_PREDEFINED: 4
}

export const UnitTypeText = {
    NONE: "None",
    OPTIONAL: "Optional",
    NOT_PREDEFINED: "Not predefined",
    GROUP_PREDEFINED: "Predefined unit group",
    UNIT_PREDEFINED: "Predefined unit"
}

export const Role = {
    NONE: 0,
    READER: 1,
    CONTRIBUTOR: 2,
    OWNER: 3,
    ADMIN: 100
}

export const RoleText = {
    NONE: "None",
    READER: "Reader",
    CONTRIBUTOR: "Contributor",
    OWNER: "Owner",
    ADMIN: "Admin"
}

export const PublishingRole = {
    NONE: 0,
    STAGE: 2,
    PROD: 3
}

export const PublishingRoleText = {
    NONE: "None",
    STAGE: "Stage",
    PROD: "Stage and production"
}

export const PublishingRoleTextShort = {
    NONE: "None",
    STAGE: "S",
    PROD: "P&S"
}

export const UserStatus = {
    ACTIVE: 0,
    DISABLED: 1
}

export const UserStatusText = {
    ACTIVE: "Active",
    DISABLED: "Disabled"
}

export const ListType = {
    LISTGROUP: "ListGroup",
    COLLAPSE: "Dropdown"
}

export const IsText = {
    YES: "Yes",
    NO: "No"
}

export const LogStatusText = {
    ERROR: "Error",
    WARNING: "Warning",
    INFO: "Info"
}

export const ContexParameterTexts = {
    ENV: "ENV",
    STORAGE: "STORAGE",
    FACTORY: "FACTORY"
}

export const ActionStatus = {
    DELETE: 0,
    CREATE: 1,
    CREATE_NEW_REVISION: 2,
    EDIT: 3,
    STAGE: 4,
    PROD: 5
}