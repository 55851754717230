import React, { useState, useEffect } from 'react';
import { callAPI } from '../../helpers/api';
import ListHeader from '../common/ListHeader';
import { CustomSpinner } from '../common/CustomSpinner';
import { Error } from '../common/Error';
import ListGroupList from '../common/ListGroupList';
import { useDispatch, useSelector } from 'react-redux';
import adminFactoryActions from '../../actions/adminFactoryActions';
import { Section, IconType } from '../../helpers/enums';
import { CreateButton } from '../common/CreateButton';

export default function FactoryList({
    msalInstance
}) {
    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const title = "Factories";

    let user = useSelector(state => state.application.user);

    async function populateFactories(){
        //console.log('url', url);
        let result = await callAPI('factory', msalInstance, user ? user.authUser : {});
        if (result.errorMsg && !result.json){
            setError(result.errorMsg);
            setIsLoading(false);
            dispatch(adminFactoryActions.setFactories([]));
        }
        else{
            var factoryList = result.json;
            //console.log('result.json', result.json);console.log('factories', factories);
            dispatch(adminFactoryActions.setFactories(factoryList));
            if (factoryList.length > 0){
                setError(null);
                //console.log('factoryList[0]', factoryList[0]);
                dispatch(adminFactoryActions.setActiveFactory(factoryList[0]));
            }
            else{
                setError("No factories found!");
            }
            setIsLoading(false);
        }
    }

    const createNewFactory = () => {
        dispatch(adminFactoryActions.setActiveFactory({}));
    }

    const setActive = (factory) => {
        dispatch(adminFactoryActions.setActiveFactory(factory));
    }

    useEffect(() => {
        //console.log('Going to update storage list.', props.itemIsUpdated);
        populateFactories();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading){
        return (
            <>
                <ListHeader
                    title={title}
                    showButtons="true"
                    icon={IconType.BUILDING}>
                </ListHeader>
                <CustomSpinner />
            </>
        );
    }

    if (error){
        return (
            <>
                <ListHeader
                    title={title}
                    showButtons="true"
                    icon={IconType.BUILDING}>
                </ListHeader>
                <Error msg={error} />
            </>
        );
    }

    return (
        <>
            <ListHeader
                title={title}
                showButtons="true"
                icon={IconType.BUILDING}>
                <CreateButton onClick={createNewFactory} />
            </ListHeader>
            <ListGroupList
                type={Section.ADMIN_FACTORIES}
                onItemSelected={setActive} />
        </>
    );
}