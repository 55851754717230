import { graphRequest } from './authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

var graph = require('@microsoft/microsoft-graph-client');

async function getAuthenticatedClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken);
        }
    });

    return client;
}

async function performQuery(query, accessToken) {
    const client = await getAuthenticatedClient(accessToken);

    const response = await client
        .api('/' + query)
        .header('ConsistencyLevel', 'eventual')
        .get();

    return response;
}

export async function searchABBUsers(q, msalInstance, account) {
    const maxReturnCount = 100;
    let query = "users?$count=true&$orderby=displayName&$top=" + maxReturnCount +
        "&$search=\"displayName:" + q + "\" OR \"mail:" + q + "\"";
    let totalUsers = [];
    let totalCount = 0;
    let currentPage = 1;
    let allFetched = false;

    // Silently acquires an access token which is then attached to a request for MS Graph data
    const token = await msalInstance
        .acquireTokenSilent({
            ...graphRequest,
            account: account,
        })
        .then((response) => {
            return response.accessToken;
        })
        .catch(async (e) => {
            // Catch interaction_required errors and call interactive method to resolve
            if (e instanceof InteractionRequiredAuthError) {
                await msalInstance.acquireTokenRedirect(graphRequest);
            }

            throw e;
        });

    try {
        while (!allFetched && totalUsers.length < maxReturnCount && query) {
            var graphResponse = await performQuery(query, token)
                .then((response) => {
                    return response;
                });

            if (graphResponse) {
                if (graphResponse['@odata.count']) {
                    totalCount = graphResponse['@odata.count'];
                }
                // Let's only return users having abb.com as email address
                const users = graphResponse.value;
                if (users.length > 0) {
                    var abbUsers = users.filter(u => u.mail && u.mail.endsWith('abb.com')); //console.log('abbUsers', abbUsers);
                    if (abbUsers.length > 0) {
                        totalUsers.push(...abbUsers);
                    }
                }
                if (graphResponse['@odata.nextLink']) {
                    const url = graphResponse['@odata.nextLink'];
                    query = url.substring(url.indexOf('users?')); //console.log('query', query);
                }
                else {
                    query = null;
                }
            }

            currentPage++;
            allFetched = currentPage * maxReturnCount >= totalCount;
        }
        return {
            maxReturnCount: maxReturnCount,
            users: totalUsers,
            totalCount: totalCount,
            allFetched: allFetched
        };
    }
    catch (error) {
        console.log('Error: ', error);
        return {};
    }
}