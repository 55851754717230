import { ActionTypes } from "../helpers/enums";

const defaultValues = {
    list: [], 
    activeItem: {},
    loaded: false,
    storageFactories: []
}

const adminStorages = (state = defaultValues, action) => {
    switch (action.type){
        case ActionTypes.SET_ADMIN_STORAGES:
            return{
                ...state,
                list: action.payload,
                loaded: true
            }
        case ActionTypes.SET_ADMIN_ACTIVE_STORAGE:
            return{
                ...state,
                activeItem: action.payload
            }
        case ActionTypes.SET_ADMIN_STORAGE_FACTORIES:
            return{
                ...state,
                storageFactories: action.payload
            }
        default:
            return state;
    }
}

export default adminStorages;