import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Col, Button } from 'reactstrap';
import { Role, Action } from '../../helpers/enums';
import SiteLayout from '../common/SiteLayout';
import inspectorActions from '../../actions/inspectorActions';
import QueryList from './QueryList';
import { Info } from '../common/Info';
import Query from './Query';
import QueryModal from './modals/QueryModal';
import DeleteQueryModal from './modals/DeleteQueryModal';
import { callAPI } from '../../helpers/api';
import { Error } from '../common/Error';
import { CustomSpinner } from '../common/CustomSpinner';

function compareName(a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }

    return 0;
}

export default function Tool({ msalInstance }) {
    let activeQuery = useSelector(state => state.inspector.activeItem);
    let noQueriesFound = useSelector(state => state.inspector.noQueriesFound);
    let currentUser = useSelector(state => state.application.user); //console.log('currentUser', currentUser);

    const [existingQueries, setExistingQueries] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);
    const [query, setQuery] = useState(activeQuery ?? null);
    const [loading, setIsLoading] = useState(false);
    const [searchError, setSearchError] = useState(null);
    const [submitted, setSubmitted] = useState(0);

    const populate = async () => {
        setIsLoading(true);
        setSearchError(null);
        let result = await callAPI('query', msalInstance, currentUser.authUser);
        if (result.errorMsg) {
            setSearchError(result.errorMsg);
            setExistingQueries([]);
        }
        else {
            if (result.json?.length === 0) {
                dispatch(inspectorActions.setNoQueriesFound(true));
                setExistingQueries([]);
            }
            else {
                var parsedQueries = result.json.map((r) => {
                    if (r.request) {
                        return {
                            ...r,
                            request: JSON.stringify(JSON.parse(r.request), undefined, 2)
                        }
                    }
                    else {
                        return r;
                    }
                }); //console.log('parsedQueries', parsedQueries);
                setExistingQueries(parsedQueries);
                setQuery(parsedQueries[0]);
            }
        }
        setSubmitted(submitted + 1);
        setIsLoading(false);
    }

    const dispatch = useDispatch();

    const toggle = (action) => {
        setShowModal(!showModal);
        if (typeof action === "string") {
            setModalStatus(action);
        }
        else {
            setModalStatus(null);
        }
    }

    const toggleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    }

    const addNewQuery = () => {
        dispatch(inspectorActions.queryTabsHidden(true));
        toggle(Action.CREATE);
        dispatch(inspectorActions.setActiveQuery({}));
    }

    const getQueryList = () => {
        return existingQueries?.length > 0 ? [...existingQueries] : [];
    }

    const getUpdatedExistingQuery = (list, newValues) => {
        var q = list.find(i => i.id === newValues.id);
        if (q) {
            q.name = newValues.name;
            if (newValues.request) {
                q.request = newValues.request;
            }

            return q;
        }

        return list.length > 0 ? list[0] : {};
    }

    const queryNameAddedOrUpdated = (data, status) => {
        var newList = getQueryList(); //console.log('data', data);
        let q = {};
        if (status !== Action.CREATE) {
            // Let's update already existing query.
            q = getUpdatedExistingQuery(newList, {id: data.id, name: data.name});
        }
        else {
            // Let's create a new query
            q = { id: data.id, name: data.name }; //console.log('new query', q);
            newList.push(data);
        }
        newList.sort(compareName);
        setExistingQueries(newList);
        dispatch(inspectorActions.setActiveQuery(q));
        dispatch(inspectorActions.queryTabsHidden(false));
    }

    const queryRun = (q) => {
        //console.log('Update query', q);
        var newList = getQueryList();
        // Update the data
        var updatedQuery = getUpdatedExistingQuery(newList, q);
        setExistingQueries(newList);
        dispatch(inspectorActions.setActiveQuery(updatedQuery));
    }

    const queryDeleted = (id) => {
        //console.log('id', id);
        var newList = existingQueries?.length > 0 ? existingQueries.filter(q => q.id !== id) : [];
        setExistingQueries(newList);
        dispatch(inspectorActions.setActiveQuery(newList.length > 0 ? newList[0] : {}));
    }

    useEffect(() => {
        //console.log('active query changed or loaded!', activeQuery?.id, existingQueries?.length, noQueriesFound, searchError);

        if (activeQuery?.id) {
            setQuery(activeQuery);
        }
        else if (existingQueries?.length > 0 && !showModal) {
            dispatch(inspectorActions.setActiveQuery(existingQueries[0]));
        }

        if (!(existingQueries?.length > 0) && !noQueriesFound && !searchError && submitted < 1) {
            //console.log('Get queries from database');
            populate();
        }
    }, [activeQuery, existingQueries, showModal, dispatch, submitted]); // eslint-disable-line react-hooks/exhaustive-deps

    const subMenuItems = [
        {
            name: 'Inspector tool',
            to: '/inspector/tool',
            icon: './icons/inspector_tool.svg',
            requiredRole: Role.READER
        }
    ];

    const sideMenu = <QueryList
        queries={existingQueries}
        onAddNew={addNewQuery}
    />

    return (
        <SiteLayout
            sideMenu={sideMenu}
            subMenuItems={subMenuItems}
        >
            <Container fluid={true} className="mt-1 mb-3">
                <Col xs={12} sm={8} lg={6} xl={4}>
                    <h1>
                        Inspector tool
                        {query?.id > 0 && 
                            <>
                            <span className="heading-info ms-2">{query.name}</span>
                                <Button 
                                    color="link" 
                                    className="ms-2 action"
                                        title="Edit"
                                        onClick={() => toggle(Action.EDIT)}>
                                    <img className="icon" src="./icons/button_edit.svg" alt="" />
                                </Button>
                                <Button
                                    color="link"
                                    className="danger"
                                        title="Delete"
                                        onClick={() => toggleDeleteModal()}>
                                    <img className="icon" src="./icons/button_delete.svg" alt="" />
                                </Button>
                            </>
                        }
                    </h1>
                </Col>
            </Container>
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                Use this tool to test, review, and compare elements of items from a specific factory in a specific storage.
            </div>
            {existingQueries?.length > 0 ?
                <Query onRun={queryRun} msalInstance={msalInstance} /> :
                <Container fluid={true} className="mt-4">
                    <Col lg={12} xl={10}>
                        {loading && <CustomSpinner />}
                        {!loading && searchError && <Error msg={searchError} />}
                        {!loading && !searchError && <Info msg={"Create the first inspection by pressing the 'Add' button."} />}
                    </Col>
                </Container>
            }
            <QueryModal
                isOpen={showModal}
                toggle={toggle}
                status={modalStatus}
                onQueryUpdated={queryNameAddedOrUpdated}
                msalInstance={msalInstance}
                user={currentUser.authUser}
            />
            <DeleteQueryModal
                id={activeQuery.id}
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                onQueryDeleted={queryDeleted}
                msalInstance={msalInstance}
                user={currentUser.authUser}
            >
                <p>Are you sure you want to delete query</p>
                <p className="fw-bold">{activeQuery.name}</p>
            </DeleteQueryModal>
        </SiteLayout>
    );
}