import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import { UserWithRolesColumns } from './Columns';
import { CustomSpinner } from '../common/CustomSpinner';
import { callAPI } from '../../helpers/api';
import StorageUserRolesModal from './modals/StorageUserRolesModal';
import StorageUserModal from './modals/StorageUserModal';
import DeleteModal from '../common/modals/DeleteModal';
import { UserColumns } from './Columns';
import SimpleCustomTable from '../common/SimpleCustomTable';
import TableFiltersAndButtons from '../common/TableFiltersAndButtons';
import { getActionsColumnDef } from '../../helpers/tableHelpers';

export default function StorageUsers({ msalInstance }) {
    let selectedStorage = useSelector(state => state.adminStorages.activeItem);
    let currentUser = useSelector(state => state.application.user);
    const userColumns = UserColumns(); //console.log('selectedStorage', selectedStorage);

    const [storageUsers, setStorageUsers] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [searchError, setSearchError] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [deleteUsers, setDeleteUsers] = useState([]);
    const [editUsers, setEditUsers] = useState([]);

    const populateUsers = async (id) => {
        setIsLoading(true);
        let result = await callAPI("user/storage/" + id, msalInstance, currentUser.authUser);
        var userResult = result.json;
        if (result.errorMsg && !userResult) {
            setSearchError(result.errorMsg);
            setStorageUsers([]);
        }
        else {
            if (userResult.length === 0) {
                setSearchError('No users found!');
            }
            else {
                setSearchError(null);
            }
            setStorageUsers(userResult); //console.log('userResult', userResult);
        }
        setIsLoading(false);
    }

    const toggleAddModal = () => {
        setShowAddModal(!showAddModal);
    }

    const getUserListFromStorageUsers = (ids) => {
        return storageUsers.filter(u => ids.includes(u.id));
    }

    const toggleModal = (ids) => {
        const modalIsAboutToOpen = !showModal;

        if (ids?.length > 0 && modalIsAboutToOpen) {
            setEditUsers(getUserListFromStorageUsers(ids));
        }
        setShowModal(modalIsAboutToOpen);
    }

    const toggleDeleteModal = (ids) => {
        //console.log('ids', ids);
        if (ids?.length > 0) {
            setDeleteUsers(getUserListFromStorageUsers(ids));
        }
        setShowDeleteModal(!showDeleteModal);
    }

    const usersAdded = (users) => {
        if (users?.length > 0) {
            setSearchError(null);
            setStorageUsers([...storageUsers, ...users]);
        }
    }

    const usersDeleted = (userIds) => {
        if (userIds?.length > 0) {
            let newList = storageUsers.filter(u => !userIds.includes(u.id));
            setStorageUsers(newList);
            if (newList.length === 0) {
                setSearchError('No users found!');
                setSelectedUsers([]);
            }
        }
    }

    const usersUpdated = (users) => {
        if (users?.length > 0) {
            let updatedUsers = storageUsers;
            users.forEach(user => {
                var index = updatedUsers.findIndex(i => i.id === user.id);
                if (index >= 0) {
                    updatedUsers[index].storageOwnerIds = user.storageOwnerIds;
                    updatedUsers[index].roles = user.roles;
                }
            });
            setStorageUsers([...updatedUsers]);
        }
    }

    useEffect(() => {
        setSearchError(null);
        setIsLoading(false);
        if (selectedStorage?.id > 0) {
            populateUsers(selectedStorage.id);
        }
    }, [selectedStorage]); // eslint-disable-line react-hooks/exhaustive-deps

    const cellFn = (original) => {
        return (<ButtonToolbar>
            <Button
                color="primary"
                className="me-1 btn-xs"
                title="Edit user"
                onClick={() => toggleModal([original.id])}
            >
                <img className="icon" src="./icons/button_edit.svg" alt="" />
            </Button>
            <Button
                color="danger"
                className="me-1 btn-xs"
                title="Delete user from storage"
                onClick={() => toggleDeleteModal([original.id])}
            >
                <img className="icon" src="./icons/button_delete.svg" alt="" />
            </Button>
        </ButtonToolbar>);
    }
    const columnsWithActions = [...UserWithRolesColumns(selectedStorage ? selectedStorage.id : 0), getActionsColumnDef(cellFn)];

    const buttons = [{
        color: "primary",
        onClick: toggleAddModal,
        icon: "./icons/button_create.svg",
        title: "Add user",
        disabled: false
    }, {
        color: "primary",
        onClick: toggleModal,
        icon: "./icons/button_edit.svg",
        title: "Edit selected",
        disabled: !(selectedUsers?.length > 0)
    }, {
        color: "danger",
        onClick: toggleDeleteModal,
        icon: "./icons/button_delete.svg",
        title: "Delete selected",
        disabled: !(selectedUsers?.length > 0)
    }];

    if (!selectedStorage || !selectedStorage.id) {
        return (<></>);
    }

    if (loading) {
        return (<CustomSpinner />);
    }
    
    return (
        <React.Fragment>
            <TableFiltersAndButtons
                title="Users"
                data={storageUsers}
                selectedData={selectedUsers}
                columns={columnsWithActions}
                setSelectedData={setSelectedUsers}
                loading={loading}
                msg={searchError}
                localStorageKey="StorageUsersTable"
                buttons={buttons}
                pageSizes={[10, 20, 30]}
                tableClass="table-with-states"
            />
            <StorageUserModal
                isOpen={showAddModal}
                toggle={toggleAddModal}
                onUsersAdded={usersAdded}
                msalInstance={msalInstance}
                user={currentUser.authUser}
            />
            <StorageUserRolesModal
                isOpen={showModal}
                toggle={toggleModal}
                selectedUsers={editUsers}
                onUsersUpdated={usersUpdated}
                msalInstance={msalInstance}
                user={currentUser.authUser}
            />
            <DeleteModal
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                entityProperties={{
                    baseUrl: "user/",
                    single: "user",
                    plural: "users",
                }}
                deleteIds={deleteUsers.map(i => i.id)}
                onDeleted={usersDeleted}
                subTitle={selectedStorage ? selectedStorage.code : ""}
                storageId={selectedStorage ? selectedStorage.id : 0}
                user={currentUser.authUser}
                msalInstance={msalInstance}>
                <p>Are you sure you want to delete user(s) from storage?</p>
                <SimpleCustomTable
                    tableColumns={userColumns}
                    tableData={deleteUsers}
                    hiddenColumns={["storageOwner", "statusId"]}
                />
            </DeleteModal>
        </React.Fragment>
    );
}