import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { CustomTable, getCheckboxColumnDef } from '../../helpers/tableHelpers';
import {
    useReactTable,
    getCoreRowModel,
    flexRender
} from '@tanstack/react-table'

const CustomTableWithEditableCellsAndSelection = forwardRef(({
    tableColumns,
    tableData,
    className,
    onSelectedChanged,
    updateCellData,
    useRowSelection = true
}, ref) => {
    //console.log('CustomTableWithEditableCells');
    const [data, setData] = useState(() => []);
    const [columns] = useState(() => {
        if (useRowSelection) {
            return [getCheckboxColumnDef(), ...tableColumns]
        }
        return [...tableColumns];
    });
    const [rowSelection, setRowSelection] = useState({});

    const tableClasses = className ? className + " table-editable-cells mt-3" : "table-editable-cells mt-3";
    
    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
        },
        getRowId: row => {
            if (row.internalId) {
                return row.id + "_" + row.internalId;
            }
            return row.id;
        },
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        meta: {
            updateCellData: (rowIndex, columnId, value) => {
                setData((old) => old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value
                        };
                    }
                    return row;
                }));
                if (updateCellData) {
                    updateCellData(rowIndex, columnId, value);
                }
            }
        }
    });

    useImperativeHandle(ref, () => ({
        resetSelectedRows: () => table.toggleAllRowsSelected(false)
    }));

    useEffect(() => {
        setData([...tableData]);
    }, [tableData]);

    useEffect(() => {
        //console.log('row selection changed', rowSelection, table.getIsSomeRowsSelected(), table);
        if (onSelectedChanged) {
            const selectedIds = Object.keys(rowSelection).filter(key => rowSelection[key] === true).map(Number);
            if (selectedIds?.length > 0) {
                console.log('table.getSelectedRowModel().rows', table.getSelectedRowModel().rows)
                onSelectedChanged(table.getSelectedRowModel().rows.map(r => r.original));
            }
            else {
                onSelectedChanged([]);
            }
        }
    }, [rowSelection]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CustomTable
            table={table}
            render={flexRender}
            tableClasses={tableClasses}
            size="sm"
            enableSorting={false}
            showColumnFilters={false}
        />
    );
});

export default CustomTableWithEditableCellsAndSelection;