import React, { useState } from 'react';
import { EntityStatus, RevisionStatusText, BoolenText } from "../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import { Input } from 'reactstrap';
import { getValueString, getCellDiv } from '../../helpers/commonHelpers';
import Moment from 'react-moment';
import moment from 'moment';

// <Private methods>
const getRevisionIdAndValueFromList = (revision, propertyId, mappingPropertyId) => {
    if (!revision || !revision.revisionId){
        return {
            id: -1,
            value: null
        };
    }

    var property = revision[propertyId];
    if (!property || property.length === 0){
        return {
            id: revision.id,
            value: ""
        };
    }

    return {
        id: revision.id,
        value: property.map(p => p[mappingPropertyId]).join(", ")
    };
}

const getTextValue = ({ value: obj }) => {
    if (!obj && typeof obj != "boolean"){
        return "";
    }

    if (typeof obj === "boolean"){
        return obj === true ? BoolenText.TRUE : BoolenText.FALSE;
    }

    return obj;
}

const dataContainsSeveralRows = (rowCount, id) => {
    if (!id || !rowCount || rowCount < 2) {
        return false;
    }

    return true;
}

const isLastRow = (rowCount, index) => {
    if (index === rowCount - 1) {
        return true;
    }

    return false;
}

const isDOMTypeElement = (element) => {
    return React.isValidElement(element) && typeof element.type === 'string';
}

const getDeepKeyValue = (obj, keys) => {
    if (Array.isArray(keys)) {
        if (keys?.length > 0) {
            if (keys[0] in obj) {
                //let value = obj[keys[0]];
                if (keys.length === 1) {
                    return obj[keys[0]];
                }
                const newObj = obj[keys[0]];
                keys.shift();
                return getDeepKeyValue(newObj, keys);
            }
        }
        return "";
    }
    if (keys?.length > 0 && keys in obj) {
        return obj[keys];
    }
    return obj;
}

const getRevisionIdAndValue = (revision, columnId, maxLength, getMethod) => {
    if (!revision){
        return {id: -1, value: null};
    }

    if (columnId.includes('_')) {
        const keys = columnId.split('_');
        return { id: revision.revisionId, value: getDeepKeyValue(revision, keys) };
    }

    if (columnId === "type" && !getMethod && revision[columnId] === null) {
        return { id: -1, value: null };
    }

    if (getMethod) {
        return { id: revision.revisionId, value: getMethod({ value: revision[columnId], revisionData: revision, maxLength: maxLength, returnWithDiv: true })}
    }
    //console.log('revision.revisionId, revision[columnId]', revision.revisionId, revision[columnId]);
    return {id: revision.revisionId, value: revision[columnId]};
}

const getRevisionIdValueSymbol = (revision, columnId, maxLength, symbolId = "factorySymbol") => {
    //console.log('revision', revision);
    if (!revision) {
        return { id: -1, value: null };
    }
    if (Object.hasOwn(revision, symbolId)) {
        return {
            ...getRevisionIdAndValue(revision, columnId, maxLength), factorySymbol: revision[symbolId] ?? null
        };
    }
    return getRevisionIdAndValue(revision, columnId, maxLength);
}

const getRevisionValueDataForElement = (revision, columnId, maxLength) => {
    if (!revision) {
        return { id: -1, value: null };
    }

    const error = revision.evaluationOk === false;
    const originalValue = revision[columnId];

    return {
        id: revision.revisionId,
        orgValue: originalValue,
        value: getValueString(originalValue),
        cellValue: getCellDiv(originalValue, columnId, error ? "alert-danger" : "", maxLength),
        error: error
    };
}

const isSameRevision = (current, stage, prod) => {
    if ((stage.id === current.id || stage.id === -1) && (prod.id === current.id || prod.id === -1)) {
        return true;
    }
    return false;
}

const setAndCheckValuesAreSameInAllRevisions = (current, stage, prod) => {
    try {
        // Set the values empty if null or undefined or to corresponding text value for boolean
        stage.value = getTextValue({ value: stage.value });
        prod.value = getTextValue({ value: prod.value });

        stage.isSame = current.value.localeCompare(stage.value) === 0;
        prod.isSame = current.value.localeCompare(prod.value) === 0;
        // Values are the same if 
        // current and stage values are the same and there is no prod revision or 
        // current and  prod values are the same and there is no stage revision or
        // all values in work, stage and prod are the same
        if ((stage.isSame && prod.id <= 0) || (prod.isSame && stage.id <= 0) || (stage.isSame && prod.isSame)) {
            return true;
        }

        return false;
    }
    catch (error) {
        console.log('error occured', error);
        return true;
    }
}

const areProdAndStageValuesSame = (stage, prod, checkIdAndValue = false) => {
    //console.log('stage, prod', stage, prod);
    if (checkIdAndValue) {
        if ((prod.id === stage.id && prod.id > 0 &&
            prod.value && stage.value
                && prod.value.toString().localeCompare(stage.value.toString()) === 0)
            || (!prod.value && !stage.value)) {
            return true;
        }
    }
    else if ((prod.id === stage.id && prod.id > 0)
        || (prod.value && stage.value
            && prod.value.toString().localeCompare(stage.value.toString()) === 0)
        || (!prod.value && !stage.value)) {
        return true;
    }
    return false;
}

const getChangesCellDiv = (cellData, id = "value") => {
    try {
        if (!cellData) {
            return "";
        } //console.log('cellData', cellData);
        const cellValue = cellData[id];
        let className = "";
        if (cellData.className && cellData.className.lenght > 0) {
            className = cellData.className;
        }
        if (!cellValue || cellValue.length === 0) {
            return (<div className={className}></div>)
        }

        if (isDOMTypeElement(cellValue) && cellValue.props.hasOwnProperty('className') &&
            cellValue.props.className !== null && cellValue.props.className.length > 0) {
            if (cellValue.props.hasOwnProperty('children')) {
                // Let's combine the div element with set classes
                return (<div className={className + " " + cellValue.props.className}>{cellValue.props['children']}</div>)
            }
            return (<div className={className}>{cellValue}</div>)
        }
        return (<div className={className}>{cellValue}</div>)
    }
    catch (error) {
        console.log('Error: ', error);
        return "";
    }
}

const DifferenceDiv = ({value, id}) => {
    return (<div className="bg-warning">{getCellDiv(value, id)}</div>);
}

const DifferenceDivWithSymbol = ({ value, symbol }) => {
    return (<CellWithSymbol value={value} symbol={symbol} className="bg-warning" />);
}

const BaseChangesLogCell = (
    initialValue,
    index,
    id,
    table,
    getValueMethod,
    row,
    compareMethod
) => {
    //console.log('id, table:', id, table);//.getRowCount(), table.getCoreRowModel())
    const currentValue = getValueMethod({ value: initialValue, revisionData: row });//console.log('initial and current values', initialValue, currentValue);
    // If there is no more than one row within the data or it is the last row the value itself is returned
    const rowCount = table.getRowCount();
    if (!dataContainsSeveralRows(rowCount, id) || isLastRow(rowCount, index)) {
        return getCellDiv(currentValue, id);
    }

    const data = table.getCoreRowModel().flatRows;
    
    const previousValueRow = data[index + 1]?.original;
    if (!previousValueRow){
        return getCellDiv(currentValue, id);
    }
    const previousValue = getValueMethod({ value: previousValueRow[id], revisionData: previousValueRow });
    // if (id === 'input'){
    //     console.log('currentValue and previous values', currentValue, previousValue);
    // }

    if (compareMethod){
        if (compareMethod(row, previousValueRow)){
            return getCellDiv(currentValue, id); // The current and previous values are the same
        }
        else{
            return (<DifferenceDiv value={currentValue} id={id}/>);
        }
    }
    if (currentValue.localeCompare(previousValue) === 0){
        return getCellDiv(currentValue, id); // The current and previous values are the same
    }
    return (<DifferenceDiv value={currentValue} id={id}/>); // Current values has changed
}

const BaseChangesCell = (
    current,
    stage,
    prod,
    stageAndProdValuesAreSame,
    id = "value"
) => { 
    current.status = ["W"];
    stage.status = [];
    prod.status = [];
    
    if (stage.isSame && stage.id !== -1) {
        current.status = [...current.status, "S"];
    }
    else {
        stage.status = ["S"];
    }
    if (prod.isSame && prod.id !== -1){
        current.status = [...current.status, "P"];
    }
    else{
        if (stageAndProdValuesAreSame){
            stage.status = [...stage.status, "P"];
        }
        else if (prod.id !== -1 && prod[id] !== null){
            prod.status = ["P"];
        }
    }
    
    const EnvCell = ({ statuses, className }) => {
        return (<div className={"splitCellEnv " + className}>
            {
                statuses?.includes('W') &&
                <img src="./icons/access_write.svg" className="icon icon-sidebar write" title="Value in work environment" alt="" />
            }
            {
                statuses?.includes('S') &&
                <img src="./icons/access_stage.svg" className="icon icon-sidebar stage" title="Value in stage environment" alt="" />
            }
            {
                statuses?.includes('P') &&
                <img src="./icons/access_production.svg" className="icon icon-sidebar prod" title="Value in production environment" alt="" />
            }
        </div>)
    }

    const SplitCell = ({ entity, className }) => {
        //console.log('id, entity', id, entity);
        if (entity.status?.length > 0) {
            return (
                <>
                    <div className={(entity.error ? "alert-danger " : "") + "splitCellValue " + className}>
                        {entity.factorySymbol ?
                            <CellWithSymbol value={entity[id]} symbol={entity.factorySymbol} /> :
                            getChangesCellDiv(entity, id)}
                    </div>
                    <EnvCell statuses={entity.status} className={className} />
                </>
            );
        }

        return "";
    }
    
    return (
        <div className="splitCellWrapper">
            <SplitCell entity={current} className="latest" />
            <SplitCell entity={stage} className="earlier splitBorder" />
            <SplitCell entity={prod} className="earlier splitBorder" />
        </div>
    );
}

const BaseDateCell = ({date, longFormat = false}) => { 
    if (!date){
        return (<div className="empty">{date}</div>);
    }
    
    moment.updateLocale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: '%ds',
            ss: '%ds',
            m: '1m',
            mm: '%dm',
            h: '1h',
            hh: '%dh',
            d: '1d',
            dd: '%dd',
            M: '1mo',
            MM: '%dmo',
            y: '1y',
            yy: '%dy'
        }
    });
    const dateDiff = moment().diff(date, 'days', false); //console.log('dateDiff for ' + date, dateDiff, moment.locale());
    
    let className =
        (dateDiff < 1 ? "from-now highlightTag within1d text-minor" :
            (dateDiff < 7 ? "from-now highlightTag within7d text-minor" :
                "from-now text-minor"));

    return (
        <div className="d-flex flex-column">
            <span className="text-nowrap">
                <img src="./icons/calendar.svg" className="icon icon-time pe-1" alt="" />
                <Moment date={date} format='YYYY-MM-DD' className="text-minor pe-3" />
            </span>
            {longFormat &&  
                <span className="text-nowrap">
                        <img src="./icons/clock.svg" className="icon icon-time pe-1" alt="" />
                        <Moment date={date} format='HH:mm' className="text-minor" />
                    </span>
            }
            <span className="text-nowrap">
                <Moment fromNow className={className}>{date}</Moment>
            </span>
        </div>
    );
}

const ChangesRevisionValuesCell = ({
    current,
    stage,
    prod,
    columnId,
    maxLength = 0
}) => {
    if (isSameRevision(current, stage, prod)){
        // All the revisions are the same
        if (current.factorySymbol) {
            return (<CellWithSymbol value={current.value} symbol={current.factorySymbol} />);
        }
        return getCellDiv(current.value, current.id, current.className, maxLength);
    }
    try {
        const allSame = setAndCheckValuesAreSameInAllRevisions(current, stage, prod);
        if (allSame) {
            if (current.factorySymbol) {
                return (<CellWithSymbol value={current.value} symbol={current.factorySymbol} />);
            }
            return getCellDiv(current.value, columnId, current.className, maxLength);
        }

        const prodAndStageValuesAreSame = areProdAndStageValuesSame(stage, prod);

        // Set the div tags to values, get only text according to max length
        current.value = getCellDiv(current.value, columnId, null, maxLength);
        stage.value = getCellDiv(stage.value, columnId, null, maxLength);
        prod.value = getCellDiv(prod.value, columnId, null, maxLength);

        // For testing
        //if (columnId === 'formula') {
        //    console.log('Enhanced current, stage, prod', current, stage, prod);
        //}

        return (
            BaseChangesCell(
                current,
                stage,
                prod,
                prodAndStageValuesAreSame,
            )
        );
    }
    catch (error) {
        console.log('error occured', error);
        return current.value;
    }
}

// </Private methods>

export const CellWithSymbol = ({
    value,
    symbol,
    className
}) => {
    return (
        <div className={(className ? className + " " : "") + "d-flex flex-column"}>
            <span>{value}</span>
            {symbol && <span>{symbol}</span>}
        </div>
    );
}

export const StatusCellSimple = ({
    getValue
}) => {
    const initialValue = getValue();
    if (!initialValue || initialValue.length === 0){
        return (<div className="bg-secondary text-custom-light">{BoolenText.FALSE}</div>);
    }

    return (<div className="bg-success text-custom-light">{BoolenText.TRUE}</div>);
}

export const StatusCell = ({
    getValue
}) => {
    const initialValue = getValue();
    if (initialValue === RevisionStatusText.LATEST_REVISION){
        return (<div className="bg-success text-custom-light">{initialValue}</div>);
    }

    if (initialValue === RevisionStatusText.EARLIER_REVISION){
        return (<div className="bg-warning">{initialValue}</div>);
    }
    return (<div className="bg-secondary text-custom-light">{initialValue}</div>);
}

export const ChangesCell = ({
    getValue,
    row: { original },
    column: { id },
    maxLength = 0
}) => {
    // Set the value empty if null or undefined or to corresponding text value for boolean
    const revisions = original ? original.revisions : null; //console.log('original, id, maxLength, value', original, id, maxLength, getValue());
    const current = {
        id: original ? original.revisionId : null,
        value: getTextValue({ value: getValue() })
    };
    if (!revisions || revisions.length === 0 || !current.id) {
        return getCellDiv(current.value, id, null, maxLength);
    }

    // Get revision id and value for stage and production.
    const stage = getRevisionIdAndValue(revisions.find(obj => {return obj.status === EntityStatus.STAGE}), id);
    const prod = getRevisionIdAndValue(revisions.find(obj => { return obj.status === EntityStatus.PROD }), id);

    if (isSameRevision(current, stage, prod)) {
        return getCellDiv(current.value, id, null, maxLength);
    }

    // For testing purposes!!!
    //if ((original.id === 10 || original.id === 12) && id === "formula"){
    //if (id === "type"){
    //    console.log('current id & value', original.id, current);
    //    console.log('stageRevision & value', stage);
    //    console.log('prodRevision & value', prod);
    //}
   
    return (<ChangesRevisionValuesCell current={current} stage={stage} 
        prod={prod} columnId={id} maxLength={maxLength}
    />)
}

export const ChangesWithSymbolCell = ({
    value,
    row: { original },
    column: { id },
    symbolId
}) => {
    // Set the value empty if null or undefined or to corresponding text value for boolean
    const revisions = original ? original.revisions : null;
    const current = {
        id: original ? original.revisionId : null,
        value: getTextValue({ value: value }),
        factorySymbol: original[symbolId] ?? ""
    };
    if (!revisions || revisions.length === 0 || !current.id) {
        return (<CellWithSymbol value={current.value} symbol={current.factorySymbol} />);
    }

    // Get revision id and value for stage and production.
    const stage = getRevisionIdValueSymbol(revisions.find(obj => { return obj.status === EntityStatus.STAGE }), id);
    const prod = getRevisionIdValueSymbol(revisions.find(obj => { return obj.status === EntityStatus.PROD }), id);

    // All the revisions are the same
    if (isSameRevision(current, stage, prod)) {
        return (<CellWithSymbol value={current.value} symbol={current.factorySymbol} />);
    }

    try {
        const allSame = setAndCheckValuesAreSameInAllRevisions(current, stage, prod);
        if (allSame) {
            return (<CellWithSymbol value={current.value} symbol={current.factorySymbol} />);
        }

        return (
            BaseChangesCell(
                current,
                stage,
                prod,
                areProdAndStageValuesSame(stage, prod),
            )
        );
    }
    catch (error) {
        console.log('error occured', error);
        return current.value;
    }
}

export const RowChangesCell = ({
    value,
    row: { original },
    column: { id },
    getMethod,
    compareMethod,
    maxLength = 0
}) => { 
    const current = {
        id: original ? original.revisionId : null,
        value: getMethod({ value: value, revisionData: original, maxLength: maxLength, returnWithDiv: true})
    };

    if (!original){
        return current.value;
    }

    const revisions = original ? original.revisions: null;
    
    if (!revisions || revisions.length === 0 || !current.id){//console.log('No revisions found for ' + original.code);
        return current.value;
    }
    
    // Get revision id and value for stage and production
    const stageRevision = revisions.find(obj => {return obj.status === EntityStatus.STAGE});
    const stage = getRevisionIdAndValue(stageRevision, id, maxLength, getMethod);
    
    const prodRevision = revisions.find(obj => {return obj.status === EntityStatus.PROD});
    const prod = getRevisionIdAndValue(prodRevision, id, maxLength, getMethod);

    // For testing purposes!!!
    // if (id === "input" && original.id === 12){
    //     console.log('current id & value', original.id, currentValue);
    //     console.log('stage Revision and value', stageRevision, stageValue);
    //     console.log('prod Revision and value', prodRevision, prodValue);
    // }

    if (isSameRevision(current, stage, prod)) {
        // All the revisions are the same
        return current.value;
    }
    //console.log('id, current, stage, prod', id, original, stageRevision, prodRevision);
    stage.isSame = compareMethod(original, stageRevision, id);
    prod.isSame = compareMethod(original, prodRevision, id);
    // Let's return the value itself if values are the same in stage and there is no prod revision or the values are the same in prod and there is no stage revision
    // or all values in work, stage and prod are the same
    if ((stage.isSame && prod.id <= 0) || (prod.isSame && stage.id <= 0) || (stage.isSame && prod.isSame)){
        return current.value;
    }

    return (BaseChangesCell(current, stage, prod, compareMethod(stageRevision, prodRevision, id)));
}

export const ChangesWithSubstCell = ({ revisions, current, propertyId }) => {
    //console.log('current', current, revisions);
    if (!revisions || revisions.length === 0 || !current.id) {
        return (<CellWithSymbol value={current.value} symbol={current.factorySymbol} />);
    }
    const stageRevision = revisions.find(obj => {return obj.status === EntityStatus.STAGE});
    const prodRevision = revisions.find(obj => {return obj.status === EntityStatus.PROD});

    // Get revision id and value for stage and production
    let stage = getRevisionIdValueSymbol(stageRevision, propertyId); //console.log('stage', stage);
    let prod = getRevisionIdValueSymbol(prodRevision, propertyId); //console.log('prod', prod);
    
    if (isSameRevision(current, stage, prod)) {
        return (<CellWithSymbol value={current.value} symbol={current.factorySymbol} />);
    }

    if (stageRevision && stageRevision["isSubstitutable"] && stage && stage.value && stage.value.length > 0) {
        stage.value = stage.value + " (subst.)";
    }
    if (prodRevision && prodRevision["isSubstitutable"] && prod && prod.value && prod.value.length > 0){
        prod.value = prod.value + " (subst.)";
    }

    //console.log('current, stage, prod', current, stage, prod);
    return (<ChangesRevisionValuesCell current={current} stage={stage} 
        prod={prod} columnId={propertyId}
    />)
}

export const ChangesFromListCell = ({revisions, current, propertyId, mappingPropertyId}) => {
    if (!revisions || revisions.length === 0 || !current.id){
        return (<div>{current.value}</div>);
    }

    const stageRevision = revisions.find(obj => {return obj.status === EntityStatus.STAGE});//console.log('stageRevision', stageRevision);
    const prodRevision = revisions.find(obj => {return obj.status === EntityStatus.PROD});//console.log('prodRevision', prodRevision);

    var stage = getRevisionIdAndValueFromList(stageRevision, propertyId, mappingPropertyId);//console.log('stageValue', stageValue);
    var prod = getRevisionIdAndValueFromList(prodRevision, propertyId, mappingPropertyId);//console.log('prodValue', prodValue);

    if ((stage.value === null && prod.value === null) || // No stage or prod revisions found
        (stage.value === null && prod.value === current.value) ||
        (prod.value === null && stage.value === current.value)){
            
        return (<div>{current.value}</div>);
    }

    return (<ChangesRevisionValuesCell current={current} stage={stage} 
        prod={prod} columnId={propertyId}
    />)
}

export const ElementValueCell = ({
    row: { original },
    column: { id },
    maxLength = 0
}) => {
    const current = getRevisionValueDataForElement(original, id, maxLength); //console.log('current', current);
    const revisions = original ? original.revisions : null;
    if (!revisions || revisions.length === 0 || !current.id) {//console.log('No revisions found!');
        return current.cellValue;
    }
    const stageRevision = revisions.find(obj => {return obj.status === EntityStatus.STAGE});
    const prodRevision = revisions.find(obj => {return obj.status === EntityStatus.PROD});
    let stage = getRevisionValueDataForElement(stageRevision, id, maxLength);
    let prod = getRevisionValueDataForElement(prodRevision, id, maxLength);
    //console.log('original', original);
    //console.log('current, stage, prod', current, stage, prod);

    const allSame = setAndCheckValuesAreSameInAllRevisions(current, stage, prod);
    if (allSame) {
        return current.cellValue;
    }

    const prodAndStageValuesAreSame = areProdAndStageValuesSame(stage, prod, true);

    return (
        BaseChangesCell(
            current,
            stage,
            prod,
            prodAndStageValuesAreSame,
            "cellValue"
            )
        );
}

export const ElementUnitCell = ({
    value,
    row: { original }
}) => {
    const id = "unit";
    const revisions = original ? original.revisions: null;
    // Set the value empty if null or undefined or to corresponding text value for boolean
    const current = {
        id: original ? original.revisionId : null,
        value: getTextValue({ value: value })
    };
    if (!revisions || revisions.length === 0 || !current.id){
        return getCellDiv(current.value, id, null);
    }

    const stageRevision = revisions.find(obj => {return obj.status === EntityStatus.STAGE});
    const prodRevision = revisions.find(obj => {return obj.status === EntityStatus.PROD});
    let stage = getRevisionIdAndValue(stageRevision, id);
    let prod = getRevisionIdAndValue(prodRevision, id);
    
    const allSame = setAndCheckValuesAreSameInAllRevisions(current, stage, prod);
    if (allSame) {
        return getCellDiv(current.value, id, current.className);
    }

    const prodAndStageValuesAreSame = areProdAndStageValuesSame(stage, prod);

    // Set the div tags to values and cut to match max length
    current.value = getCellDiv(current.value, id, current.className);
    stage.value = getCellDiv(stage.value, id, stage.className);
    prod.value = getCellDiv(prod.value, id, prod.className);

    return (
        BaseChangesCell(
            current,
            stage,
            prod,
            prodAndStageValuesAreSame,
            )
        );
}

export const ChangesLogCell = ({
    getValue,
    row: { index }, 
    column: { id },
    table
}) => {
    //console.log('id, getValue, data:', id, getValue, table);
    return BaseChangesLogCell(getValue(), index, id, table, getTextValue);
}

export const CustomChangesLogCell = ({
    value,
    row: { index }, 
    column: { id },
    table,
    getMethod
}) => {
    return BaseChangesLogCell(value, index, id, table, getMethod);
}

export const RowChangesLogCell = ({
    value,
    row: {index, original},
    column: { id },
    table,
    getMethod,
    compareMethod
}) => {
    return BaseChangesLogCell(value, index, id, table, getMethod, original, compareMethod);
}

export const FactoryLogCell = ({
    value,
    row: { index, original },
    column: { id },
    table
}) => {
    const getValueIsSubst = (value, row) => {
        let currentValue = value;
        if (row && row.isSubstitutable) {
            currentValue = currentValue + " (subst.)"
        }
        return currentValue;
    }

    const currentValue = getValueIsSubst(value, original);//console.log('initial and current values', initialValue, currentValue);
    // If there is no more than one row within the data or it is the last row the value itself is returned
    const rowCount = table.getRowCount();
    if (!dataContainsSeveralRows(rowCount, id) || isLastRow(rowCount, index)) {
        return (<CellWithSymbol value={currentValue} symbol={original.factorySymbol} />);
        //getCellDiv(currentValue, id);
    }

    const data = table.getCoreRowModel().flatRows;
    const previousValueRow = data[index + 1]?.original; //console.log('currentValue, previousValueRow', currentValue, previousValueRow)
    if (!previousValueRow) {
        return (<CellWithSymbol value={currentValue} symbol={original.factorySymbol} />);
    }
    const previousValue = getValueIsSubst(previousValueRow[id], previousValueRow); //console.log('previousValue', previousValue)
    // if (id === 'input'){
    //     console.log('currentValue and previous values', currentValue, previousValue);
    // }

    if (currentValue.localeCompare(previousValue) === 0) {
        return (<CellWithSymbol value={currentValue} symbol={original.factorySymbol} />); // The current and previous values are the same
    }
    return (<DifferenceDivWithSymbol value={currentValue} symbol={original.factorySymbol} />); // Current values has changed
}

export const EditableCell = ({
    getValue,
    row: { index },
    column: { id },
    table
}) => {
    const { control } = useForm();
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    const onBlur = () => {
        //console.log('onBlur');
        table.options.meta?.updateCellData(index, id, value);
    }

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return (<Controller name="code" control={control} defaultValue={value}
        render={({ field }) =>
            <Input {...field}
                type="text"
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                value={value}
            />}
    />);
}

export const DivCell = ({
    getValue,
    column: { id },
}) => {
    return getCellDiv(getValue(), id);
}

export const DateCell = ({
    getValue
}) => {
    return (
        <BaseDateCell date={getValue()} />
    );
}

export const UserAndDateCell = ({
    value,
    date
}) => {
    
    if (!value){
        return (
            <BaseDateCell date={date} longFormat={true} />
        );
    }

    return (
        <div className="d-flex flex-column">
            <span className="text-major">{value}</span>
            <BaseDateCell date={date} longFormat={true} />
        </div>
    );
}

export const ItemCell = ({
    code,
    description
}) => {
    return (
        <div>
            <span className="text-major">{code}</span>
            {getCellDiv(description, "description")}
        </div>
    );
}

export const SimpleDivCell = ({
    value,
    toLower = false,
    className = ""
}) => {
    if (value?.length > 0) {
        if (toLower) {
            return (<div className={className}>{value.toLowerCase()}</div>)
        }
        return (<div className={className}>{value}</div>);
    }

    return (<div className="empty">{value}</div>);
}