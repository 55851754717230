import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormModalBase from '../../common/modals/FormModalBase';
import { FormGroup, Label, Col } from 'reactstrap';
import { Action, Role } from '../../../helpers/enums';
import { callAPI } from '../../../helpers/api';
import SimpleCustomTable from '../../common/SimpleCustomTable';
import { UserWithRolesColumns } from '../Columns';
import { isStorageOwner as isOwner } from '../../../helpers/commonHelpers';
import StorageFactoryRoles from '../StorageFactoryRoles';

const schema = yup.object().shape({
});

export default function StorageUserRolesModal({
    isOpen,
    toggle,
    selectedUsers,
    onUsersUpdated,
    msalInstance,
    user
}) {
    let selectedStorage = useSelector(state => state.adminStorages.activeItem);
    //console.log('selectedUsers', selectedUsers);
    const [isStorageOwner, setIsStorageOwner] = useState(false);
    const [roles, setRoles] = useState([]);
    const [submitted, setSubmitted] = useState(0);

    const { handleSubmit, reset, setError, formState: { errors, isValid } } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    const onSubmit = async () => {
        const rolesForBody = isStorageOwner ? [{
            storageId: selectedStorage.id,
            role: Role.OWNER
        }] : (roles?.length > 0 ?
            roles.map(r => {
                return {
                    storageId: selectedStorage.id,
                    role: r.role,
                    publishingRole: r.publishingRole,
                    factoryId: r.factoryId
                };
            }) : [{
                storageId: selectedStorage.id,
                role: Role.NONE
            }]);
        const body = selectedUsers.map(user => {
            return {
                id: user.id,
                roles: rolesForBody
            };
        });
        let method = 'PUT';
        let result = await callAPI("user/roles/" + selectedStorage.id, msalInstance, user, body, method);
        let updatedUserData = result.json;
        if (result.errorMsg && !updatedUserData) {
            setError("editError", {
                type: "manual",
                message: result.errorMsg
            });
        }
        else {
            reset();
            if (onUsersUpdated) {
                onUsersUpdated(updatedUserData);
            }
            toggle();
        }
        setSubmitted(submitted + 1);
    }

    const modalIsClosed = () => {
        reset();
        setRoles([]);
        setIsStorageOwner(false);
        toggle();
    }

    const mapRoles = (roleArray) => {
        return roleArray.filter(r => r.role !== Role.NONE).map(r => ({ ...r}));
    }

    useEffect(() => {
        //console.log('selectedUsers', selectedUsers);
        if (selectedUsers && selectedUsers.length > 0) {
            if (selectedUsers.length === 1) {
                const user = selectedUsers[0];
                setRoles(mapRoles(user.roles));
                setIsStorageOwner(isOwner(user, selectedStorage.id));
            }
            else {
                setRoles([]);
                setIsStorageOwner(false);
            }
        }
        else {
            setRoles([]);
            setIsStorageOwner(false);
        }
    }, [selectedUsers, selectedStorage.id]);

    const StorageUsers = ({ users }) => {
        if (!users) {
            return ("");
        }

        if (users.length === 1) {
            const user = users[0];
            return (
                <React.Fragment>
                    <FormGroup row>
                        <Label for="name" sm={3}>Name</Label>
                        <Col sm={9}>
                            <span className="input-group-text input-disabled">{user.name}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="name" sm={3}>Email</Label>
                        <Col sm={9}>
                            <span className="input-group-text input-disabled">{user.email}</span>
                        </Col>
                    </FormGroup>
                </React.Fragment>
            );
        }

        return (
            <SimpleCustomTable
                tableColumns={UserWithRolesColumns(selectedStorage.id)}
                tableData={users}
                className="table-with-states"
            />
        );
    }

    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={Action.EDIT + " user rights within storage"}
            subTitle={selectedStorage ? selectedStorage.code : ""}
            onSubmit={handleSubmit(data => onSubmit(data))}
            className="modal-lg"
            formIsValid={isValid}
            onModalIsClosing={modalIsClosed}
            setNotLoading={submitted}
        >
            <StorageUsers users={selectedUsers} />
            <StorageFactoryRoles
                roles={roles}
                isStorageOwner={isStorageOwner}
                setIsStorageOwner={setIsStorageOwner}
                setRoles={setRoles}
            />
            
            {errors.editError && <p className="text-danger">{errors.editError.message}</p>}
        </FormModalBase>
    );
}