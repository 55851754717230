import React from 'react';
import { Container, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';

export default function CollapsibleList({
    type,
    onItemSelected,
    disabled,
    showIconsFor = [],
    showIconForAll = false
}) {
    const setActive = (item) => {
        //console.log('Selected item', item);
        onItemSelected(item);
    }

    let listItems = useSelector(state => state[type].list);
    let selected = useSelector(state => state[type].activeItem);
    let selectedId = selected ? selected.id : 0;
    let selectedIndex = listItems && listItems.length > 0 ? listItems.findIndex(i => i.id === selectedId) : 0;
    
    const formatOptionLabel = ({ id, code, name }) => (
        <div>
            <ListGroupItemHeading className="mb-0">
                {code}
                {(showIconForAll || (showIconsFor.length > 0 && showIconsFor.includes(id))) &&
                    <span><img src="./icons/owner.svg" className="icon icon-sidebar owner ms-1" title="Full permissions" alt="" /></span>
                }
            </ListGroupItemHeading>
            <ListGroupItemText className="mb-0">{name}</ListGroupItemText>
        </div>
    );

    const filterOptions = (opt, input) => {
        return (opt.data.code && opt.data.code.toLowerCase().includes(input.toLowerCase())) ||
            (opt.data.name && opt.data.name.toLowerCase().includes(input.toLowerCase()));
    }

    return (
        <Container fluid={true} className="me-lg-0 pe-lg-0 p-3 pt-0">
            <Select
                classNames={{
                    control: (state) =>
                        'p-2',
                }}
                styles={{
                    control: (styles, state) => ({
                        ...styles,
                        outlineStyle: 'solid !important',
                        outlineColor: state.isFocused ? '#BDCAF4 !important' : 'transparent !important',
                        outlineWidth: '2px !important',
                        borderStyle: 'solid !important',
                        borderColor: '#CED4DA !important',
                        borderWidth: '1px !important',
                        cursor: 'pointer',
                    })
                }}
                defaultValue={listItems[selectedIndex]}
                options={listItems}
                hideSelectedOptions={true}
                getOptionValue={(option) => `${option['id']}`}
                onChange={setActive}
                formatOptionLabel={formatOptionLabel}
                isDisabled={disabled}
                filterOption={filterOptions}
            />
        </Container>
    );
}