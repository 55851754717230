import { ActionTypes } from "../helpers/enums"

const setActiveQuery = (query) => {
    return {
        type: ActionTypes.SET_QUERY_ACTIVE,
        payload: query
    };
}

const setNoQueriesFound = (notFound) => {
    return {
        type: ActionTypes.SET_QUERY_NO_ITEMS_FOUND,
        payload: notFound
    }
}

const queryTabsHidden = (b) => {
    return {
        type: ActionTypes.SET_QUERY_HIDE,
        payload: b
    };
}

let inspectorActions = { setActiveQuery, setNoQueriesFound, queryTabsHidden };

export default inspectorActions;