import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./helpers/authConfig";
import { MsalProvider } from '@azure/msal-react';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

//Display store in console
//store.subscribe(() => console.log(store.getState()));

const root = ReactDOM.createRoot(rootElement);
root.render(
  <BrowserRouter basename={baseUrl}>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </BrowserRouter>
 );

