import React, { useState, useEffect } from 'react';
import { Input  } from 'reactstrap';
import Select from 'react-select';
import { SelectWithStyles } from './CustomSelect';

export function DefaultColumnFilter({
    column: { getFilterValue, setFilterValue, columnDef },
    filterChanged,
  }) {
    //const count = preFilteredRows.length;
    //console.log('preFilteredRows', preFilteredRows);
    //console.log('columnDef', columnDef);
    //console.log('getFilterValue', getFilterValue());
    const divStyle = columnDef.maxFilterWidth && columnDef.maxFilterWidth > 0 ? { maxWidth: columnDef.maxFilterWidth + "px"} : {};

    const onChange = e => {
        if (filterChanged){
            filterChanged(e.target.value);
        }
        setFilterValue(e.target.value || undefined) // Set undefined to remove the filter entirely
    }
  
    return (
        <Input bsSize="sm"
            value={getFilterValue() || ''}
            onChange={onChange}
            placeholder={"Filter..."}
            style={divStyle}
        />
    )
}

export function InputFilter({
    disabled,
    id,
    filterChanged,
    clearFilterValue = false,
    isGlobal = false
}) {
    const [value, setValue] = useState("");
    const [filterTimeout, setFilterTimeout] = useState(0);

    useEffect(() => {
        //console.log('clearFilterValue', clearFilterValue);
        if (clearFilterValue){
            setValue("");
        }
    }, [clearFilterValue])

    const onChange = e => {
        setValue(e.target.value);
        clearTimeout(filterTimeout);

        const newTimeout = setTimeout(() => {
            filterChanged(e.target, isGlobal);
        }, 50);

        setFilterTimeout(newTimeout);
    }

    return (
        <Input type="text" name={id} id={id} disabled={disabled} onChange={onChange} placeholder="Search..." value={value} autoComplete="off" className="filter"/>
    )
}

export function SelectFilterOld({
    options,
    disabled,
    id,
    filterChanged,
    clearFilterValue = false,
    placeholder = "Search..."
}) {
    const [value, setValue] = useState([]);//console.log('filter options', options);

    useEffect(() => { 
        if (clearFilterValue){
            setValue([]);
        }
    }, [clearFilterValue])

    const onChange = (selectedOption) => {
        setValue(selectedOption);
        filterChanged({id: id, value: selectedOption ? selectedOption.label : ""});
    }

    return (
        <Select className="small filter react-select-container" classNamePrefix="react-select" options={options} name={id} id={id} isDisabled={disabled} isClearable onChange={onChange} placeholder={placeholder} value={value}
            styles={{
                control: (styles, state) => ({
                    ...styles,
                    outlineStyle: 'solid !important',
                    outlineColor: state.hasValue ? '#466EE8 !important' : state.isFocused ? '#BDCAF4 !important' : 'transparent !important',
                    outlineWidth: '2px !important',
                    borderStyle: 'solid !important',
                    borderColor: '#CED4DA !important',
                    borderWidth: '1px !important',
                    cursor: 'pointer',
                })
            }}
            />
    )
}

export function SelectFilter({
    options,
    disabled,
    id,
    filterChanged,
    clearFilterValue = false,
    placeholder = "Search..."
}) {
    const [value, setValue] = useState([]);//console.log('filter options', options);

    useEffect(() => {
        if (clearFilterValue) {
            setValue([]);
        }
    }, [clearFilterValue])

    const onChange = (selectedOption) => {
        setValue(selectedOption); //console.log('selectedOption', selectedOption);
        filterChanged({ id: id, value: selectedOption?.internalLabel ?? selectedOption?.label ?? "" });
    }

    return (
        <SelectWithStyles className="small filter react-select-container"
            classNamePrefix="react-select"
            options={options}
            name={id}
            id={id}
            isDisabled={disabled}
            isClearable
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            //menuIsOpen={true}
            styles={{
                control: (styles, state) => ({
                    ...styles,
                    outlineStyle: 'solid !important',
                    outlineColor: state.hasValue ? '#466EE8 !important' : state.isFocused ? '#BDCAF4 !important' : 'transparent !important',
                    outlineWidth: '2px !important',
                    borderStyle: 'solid !important',
                    borderColor: '#CED4DA !important',
                    borderWidth: '1px !important',
                    cursor: 'pointer',
                })
            }}
        />
    )
}

export function SelectMultiFilter({
    options,
    disabled,
    id,
    filterChanged,
    clearFilterValue = false,
    placeholder = "Search..."
}) {
    const [value, setValue] = useState([]);//console.log('filter options', options);

    useEffect(() => {
        if (clearFilterValue) {
            setValue([]);
        }
    }, [clearFilterValue])

    const onChange = (selectedOptions) => {
        setValue(selectedOptions); //console.log('selectedOptions', selectedOptions);
        if (Array.isArray(selectedOptions)) {
            filterChanged({ id: id, value: selectedOptions.map(o => o.symbolName).join(',') });
        }
        else {
            filterChanged({ id: id, value: selectedOptions ? selectedOptions.symbolName : "" });
        }
    }

    return (
        <SelectWithStyles className="small filter react-select-container"
            classNamePrefix="react-select"
            options={options}
            name={id}
            id={id}
            isDisabled={disabled}
            isClearable
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            isMulti
            styles={{
                control: (styles, state) => ({
                    ...styles,
                    outlineStyle: 'solid !important',
                    outlineColor: state.hasValue ? '#466EE8 !important' : state.isFocused ? '#BDCAF4 !important' : 'transparent !important',
                    outlineWidth: '2px !important',
                    borderStyle: 'solid !important',
                    borderColor: '#CED4DA !important',
                    borderWidth: '1px !important',
                    cursor: 'pointer',
                })
            }}
        />
    )
}

export function fuzzyMultipleFn(row, columnId, filterValue) {
    if (!filterValue || filterValue.length === 0) {
        return true;
    }
    const rowValue = row.getValue(columnId); //console.log(columnId, row, 'rowValue', '"' + rowValue + '"', !rowValue)
    // If row value is empty let's return false
    if (!rowValue) {
        return false;
    }
    // Let's allow user to use multiple filter values, separated with comma.
    const filters = filterValue.length > 0 ? filterValue.split(',') : [];
    return rowValue && matchOneOfFilters(filters, rowValue);
}

export function matchOneOfFilters(filters, value){
    var matchFound = false;
    if (Array.isArray(filters)) {
        filters.forEach(filter => {
            if (matchFilter(filter, value)) {
                matchFound = true;
            }
        });
    }
    else if (matchFilter(filters, value)){
        matchFound = true;
    }
    //console.log('filterArray & data', filters, value, matchFound);

    return matchFound;
}

function matchFilter(filter, value) {
    const trimmedFilter = filter.trim();
    if (trimmedFilter && value && value.toString().toLowerCase().includes(trimmedFilter.toLowerCase())) {
        return true;
    }

    return false;
}