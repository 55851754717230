import { ActionTypes } from "../helpers/enums"

const setStorages = (storages) => {
    return {
        type: ActionTypes.SET_ADMIN_STORAGES,
        payload: storages
    }
}

const setActiveStorage = (storage) => {
    return{
        type: ActionTypes.SET_ADMIN_ACTIVE_STORAGE,
        payload: storage
    }
}

const setStorageFactories = (factories) => {
    return{
        type: ActionTypes.SET_ADMIN_STORAGE_FACTORIES,
        payload: factories
    }
}

let adminStorageActions = { setStorages, setActiveStorage, setStorageFactories};

export default adminStorageActions;