import { ActionTypes } from "../helpers/enums";

const defaultValues = {
    refreshed: null,
    list: [], 
    activeItem: {},
    selectedStorageId: 0,
    selectedItems: [],
    selectedElements: [],
    selectedValueTypes: [],
    factories: [],
    selectedFactories: [],
    storageValueTypes: [],
    storageCategories: [],
    storageUnits: [],
    storageElementTypes: [],
    storageUnitGroups: [],
    selectedElementTypes: [],
    noItemsFound: false,
    storagesLoading: true,
    entitiesLoading: false,
    storageDataLoading: true,
    selectedUnitGroups: []
}

const workspace = (state = defaultValues, action) => {
    switch (action.type){
        case ActionTypes.SET_STORAGES_REFRESH_DATE:
            return {
                ...state,
                refreshed: Date.now()
            }
        case ActionTypes.SET_STORAGES:
            return {
                ...state,
                list: action.payload
            }
        case ActionTypes.SET_STORAGES_ACTIVE_STORAGE:
            return {
                ...state,
                activeItem: action.payload,
                selectedStorageId: action.payload.id
            }
        case ActionTypes.SET_STORAGES_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: action.payload
            }
        case ActionTypes.SET_STORAGES_SELECTED_ELEMENTS:
            return {
                ...state,
                selectedElements: action.payload
            }
        case ActionTypes.SET_STORAGES_SELECTED_VALUETYPES:
            return {
                ...state,
                selectedValueTypes: action.payload
            }
        case ActionTypes.SET_STORAGES_STORAGE_FACTORIES:
            return {
                ...state,
                factories: action.payload
            }
        case ActionTypes.SET_STORAGES_SELECTED_FACTORIES:
            return {
                ...state,
                selectedFactories: action.payload
            }
        case ActionTypes.SET_STORAGES_STORAGE_VALUETYPES:
            return {
                ...state,
                storageValueTypes: action.payload
            }
        case ActionTypes.SET_STORAGES_STORAGE_CATEGORIES:
            return {
                ...state,
                storageCategories: action.payload
            }
        case ActionTypes.SET_STORAGES_STORAGE_UNITS:
            return {
                ...state,
                storageUnits: action.payload
            }
        case ActionTypes.SET_STORAGES_STORAGE_ELEMENT_TYPES:
            return {
                ...state,
                storageElementTypes: action.payload
            }
        case ActionTypes.SET_STORAGES_STORAGE_UNITGROUPS:
            return {
                ...state,
                storageUnitGroups: action.payload
            }
        case ActionTypes.SET_STORAGES_SELECTED_ELEMENTTYPES:
            return {
                ...state,
                selectedElementTypes: action.payload
            }
        case ActionTypes.SET_STORAGES_NO_ITEMS_FOUND:
            return {
                ...state,
                noItemsFound: action.payload
            }
        case ActionTypes.SET_STORAGES_LOADING:
            return {
                ...state,
                storagesLoading: action.payload
            }
        case ActionTypes.SET_STORAGES_LOADING_ENTITIES:
            return {
                ...state,
                entitiesLoading: action.payload
            }
        case ActionTypes.SET_STORAGES_LOADING_DATA:
            return {
                ...state,
                storageDataLoading: action.payload
            }
        case ActionTypes.SET_STORAGES_SELECTED_UNITGROUPS:
            return {
                ...state,
                selectedUnitGroups: action.payload
            }
        default:
            return state;
    }
}

export default workspace;