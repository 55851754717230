import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Col } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Action, Role } from '../../../helpers/enums';
import { useSelector } from 'react-redux';
import ModalBase from '../../common/modals/ModalBase';
import ActionButtonModalBase from '../../common/modals/ActionButtonModalBase';
import RoleRadioButtons from '../../common/RoleRadioButtons';
import { getFactoryOptions, getSelectedOption } from '../../../helpers/commonHelpers';
import { SelectWithStyles } from '../../common/CustomSelect';
import PublishingRoleRadioButtons from '../../common/PublishingRoleRadioButtons';

const schema = yup.object().shape({
    factory: yup.object().shape({ value: yup.number().required('Select a Factory') })
        .nullable().required('Select a Factory'),
});

export default function FactoryRoleModal({
    isOpen,
    toggle,
    status,
    existingRoles,
    factoryRole,
    onRoleUpdated,
    onRoleAdded
}) {
    const [selectedRole, setSelectedRole] = useState(1);
    const [selectedPublishingRole, setSelectedPublishingRole] = useState(0);
    const [operation, setOperation] = useState(0);
    const title = status + " user role";

    let storageFactories = useSelector(state => state.adminStorages.storageFactories);
    let factories = storageFactories.filter(f => f.isSelected);
    if (existingRoles && existingRoles.length > 0 && factories.length > 0) {
        const existingFactoryIds = existingRoles.map(f => f.factoryId);
        if (status === Action.ADD) {
            // Let's filter out already existing factory roles. 
            // If user is editing the role, the factory dropdown is disabled and filtering is not needed.
            factories = factories.filter(f => !existingFactoryIds.includes(f.id));
        }
    }

    const factoryOptions = getFactoryOptions(factories); //console.log('factoryOptions', factoryOptions);

    const { reset, formState: { errors, isValid }, control, getValues, setValue } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    const roleAdded = () => {
        const roleData = getValues();
        if (onRoleAdded) {
            onRoleAdded(roleData);
        }
        toggle();
        modalIsClosed();
        setOperation(operation + 1);
    }

    const roleUpdated = () => {
        const roleData = getValues();
        if (onRoleUpdated) {
            onRoleUpdated(roleData);
        } 
        toggle();
        modalIsClosed();
        setOperation(operation + 1);
    }

    const roleValueChanged = (value) => {
        setSelectedRole(value);
        setValue('role', value);
        if (value === Role.READER) {
            setSelectedPublishingRole(0);
            setValue('publishingRole', 0);
        }
        setOperation(operation + 1);
    }

    const publishingRoleValueChanged = (value) => {
        setSelectedPublishingRole(value);
        setValue('publishingRole', value);
        setOperation(operation + 1);
    }

    const modalIsClosed = () => {
        reset();
    }

    let defaultValues = {
        role: factoryRole ? factoryRole.role : 1,
        publishingRole: factoryRole ? factoryRole.publishingRole : 0,
        //factoryId: factoryRole ? factoryRole.factoryId : 0,
        factory: factoryRole ? getSelectedOption(factoryOptions, factoryRole.factoryId) : factoryOptions[0]
    };

    useEffect(() => {
        if (factoryRole && factoryRole.role) {
            setSelectedRole(factoryRole.role);
            setValue('role', factoryRole.role);
            setSelectedPublishingRole(factoryRole.publishingRole);
            setValue('publishingRole', factoryRole.publishingRole);
            setValue('factory', getSelectedOption(factoryOptions, factoryRole.factoryId));
        }
        else {
            if (factoryOptions && factoryOptions.length >= 1) {
                setValue('factory', factoryOptions[0]);
            }
        }
        if (status === Action.ADD) {
            roleValueChanged(Role.READER);
        }
    }, [factoryRole]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var currentSelectedFactory = getValues("factory");
        if (factoryOptions && factoryOptions.length >= 1 && !factoryOptions.some(f => f.value === currentSelectedFactory?.value)) {
            setValue('factory', factoryOptions[0]);
        }
    }, [factoryOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    if (status === Action.CHANGE && (!factoryRole || factoryRole.factoryId <= 0)) {
        return (
            <ModalBase
                isOpen={isOpen}
                toggle={toggle}
                title={title}
                subTitle="Not found!"
            >
                <p>No user or factory role selected!</p>
            </ModalBase>)
    }

    if (status === Action.ADD && factories.length <= 0) {
        return (
            <ModalBase
                isOpen={isOpen}
                toggle={toggle}
                title={title}
                subTitle=""
            >
                <p>User already has roles assigned for factories {existingRoles.map(f => f.factory).join(', ')}!</p>
            </ModalBase>)
    }

    return (
        <ActionButtonModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            subTitle=""
            onClick={status === Action.ADD ? roleAdded : roleUpdated}
            formIsValid={isValid}
            onModalIsClosing={modalIsClosed}
            setNotLoading={operation}
        >
            <FormGroup row className="required form-group">
                <Label for="factory" sm={3}>Factory</Label>
                <Col sm={9}>
                    <Controller name="factory" control={control} defaultValue={defaultValues.factory} render={({ field }) =>
                        <SelectWithStyles {...field} options={factoryOptions}
                            isDisabled={status === Action.CHANGE} />
                    }
                    />
                    {errors.factory && <p className="text-danger">{errors.factory.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="role" sm={3}>Role</Label>
                <Col sm={6} md={3}>
                    <Controller name="role" control={control} defaultValue={defaultValues.role} render={({ field }) =>
                        <RoleRadioButtons
                            field={field}
                            defaultValue={selectedRole}
                            onRoleChanged={roleValueChanged}
                            includeOwner={false}
                        />
                    }
                    />
                </Col>
            </FormGroup>
            {selectedRole && selectedRole === Role.CONTRIBUTOR &&
                <FormGroup row className="required form-group">
                    <Label for="publishingRole" sm={3}>Publishing</Label>
                    <Col sm={9}>
                        <Controller name="publishingRole" control={control} defaultValue={defaultValues.publishingRole} render={({ field }) =>
                            <PublishingRoleRadioButtons
                                field={field}
                                defaultValue={selectedPublishingRole}
                                onRoleChanged={publishingRoleValueChanged}
                            />
                        }
                        />
                    </Col>
                </FormGroup>
            }
        </ActionButtonModalBase>
    );
}