import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import {callAPI} from '../../helpers/api';
import ConnectionModal from './modals/ConnectionModal';
import { CustomSpinner } from '../common/CustomSpinner';
import { Error } from '../common/Error';
import { useDispatch, useSelector } from 'react-redux';
import adminStorageActions from '../../actions/adminStorageActions';
import { FactoryColumns } from './Columns';
import TableFiltersAndButtons from '../common/TableFiltersAndButtons';
import applicationActions from '../../actions/applicationActions';
import DeleteModal from '../common/modals/DeleteModal';
import SimpleCustomTable from '../common/SimpleCustomTable';
import { getActionsColumnDef } from '../../helpers/tableHelpers';

function FactoryListForm({ msalInstance }) {
    let user = useSelector(state => state.application.user);
    let activeStorage = useSelector(state => state.adminStorages.activeItem); //console.log('activeStorage', activeStorage);
    
    const [allFactories, setAllFactories] = useState([]);
    const [storageFactories, setStorageFactories] = useState([]);
    const [selectedFactories, setSelectedFactories] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteConnections, setDeleteConnections] = useState([]);
    const dispatch = useDispatch();

    const toggle = () => {
        setShowModal(!showModal);
    }

    const toggleDelete = (ids) => {
        //console.log('ids, selectedFactories', ids, selectedFactories);
        if (ids?.length > 0) {
            setDeleteConnections(storageFactories.filter(f => ids.includes(f.id)));
        }
        else {
            setDeleteConnections([]);
        }
        setShowDeleteModal(!showDeleteModal);
    }

    const setStorageFactoriesFromAll = (factories) => {
        setStorageFactories(factories.filter(f => f.isSelected)
            .map(f => {
                return {
                    id: f.id,
                    code: f.code,
                    name: f.name,
                    isGlobal: f.isGlobal,
                    disabledSelect: f.isGlobal
                }
            }));
    }

    const setNewStorageFactories = (ids, isSelected) => {
        //console.log('ids ' + (isSelected === true ? "added" : "deleted"), ids, isSelected);
        let newStorageFactories = allFactories.map(factory => {
            return {
                ...factory,
                isSelected: ids.includes(factory.id) ? isSelected : factory.isSelected
            };
        }); //console.log('newStorageFactories', newStorageFactories);
        dispatch(adminStorageActions.setStorageFactories(newStorageFactories));
        setAllFactories(newStorageFactories);
        setStorageFactoriesFromAll(newStorageFactories);
    }

    const factoriesAdded = (ids) => {
        setNewStorageFactories(ids, true);
    }

    const factoriesDeleted = (result) => {
        //console.log('Factory deleted', result);
        setNewStorageFactories(result.map(i => i.id), false);
        setSelectedFactories([]);
    }

    async function populateFactories(id) {
        if (id > 0){
            let result = await callAPI('factory/storage/' + id, msalInstance, user ? user.authUser : {});
            if (result.errorMsg && !result.json){
                setError(result.errorMsg);
                dispatch(applicationActions.setUserUnauthorized(result.unAuthorized ?? false));
            }
            else{
                const fetchedFactories = result.json; //console.log('fetchedFactories', fetchedFactories);
                setAllFactories(fetchedFactories);
                setStorageFactoriesFromAll(fetchedFactories);
                dispatch(adminStorageActions.setStorageFactories(fetchedFactories));
                setError("");
            }
        }
        else {
            setError('Storage not set');
        }
        setIsLoading(false);
    }

    useEffect(() => {
        //console.log('activeStorage', activeStorage);
        setError("");
        setIsLoading(true);
        if (activeStorage?.id > 0) {
            populateFactories(activeStorage.id);
        }
    }, [activeStorage]); // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('activeStorage.id', activeStorage.id);
    if (!activeStorage.id || activeStorage.id === 0){
        return(<></>);
    }

    if (loading){
        return (<CustomSpinner />);
    }

    if (error){
        return (<Error msg={error} />);
    } 

    const factoryColumns = FactoryColumns();
    const cellFn = (original) => {
        return (
            <ButtonToolbar>
                <Button
                    color="danger"
                    className="ms-3 me-1 btn-xs"
                    title="Delete connection"
                    onClick={() => toggleDelete([original.id])}
                    disabled={original.isGlobal}
                >
                    <img className="icon" src="./icons/button_delete.svg" alt=""/>
                </Button>
            </ButtonToolbar>
        );
    }
    const columnsWithActions = [...factoryColumns, getActionsColumnDef(cellFn)];
    //console.log('Factories available:', allFactories.some(f => !f.isSelected));

    const buttons = [{
        color: "primary",
        onClick: toggle,
        icon: "./icons/button_create.svg",
        title: "Add factory",
        disabled: !allFactories.some(f => !f.isSelected)
    }, {
        color: "danger",
        onClick: toggleDelete,
        icon: "./icons/button_delete.svg",
        title: "Delete selected",
        disabled: !(selectedFactories?.length > 0)
    }];

    return (
        <React.Fragment>
            <TableFiltersAndButtons
                title="Factories"
                data={storageFactories}
                selectedData={selectedFactories}
                setSelectedData={setSelectedFactories}
                columns={columnsWithActions}
                loading={loading}
                msg={error}
                localStorageKey="StorageFactoriesTable"
                buttons={buttons}
                tableXl={6}
            />
            <ConnectionModal
                isOpen={showModal}
                toggle={toggle}
                onConnectionsAdded={factoriesAdded}
                user={user.authUser}
                msalInstance={msalInstance}
            />
            <DeleteModal
                isOpen={showDeleteModal}
                toggle={toggleDelete}
                entityProperties={{
                    baseUrl: "storagefactory/",
                    single: "factory",
                    plural: "factories",
                }}
                deleteIds={deleteConnections.map(i => i.id)}
                onDeleted={factoriesDeleted}
                subTitle={activeStorage ? activeStorage.code : ""}
                storageId={activeStorage ? activeStorage.id : 0}
                user={user.authUser}
                msalInstance={msalInstance}>
                <p>Are you sure you want to delete connection(s) from storage?</p>
                <SimpleCustomTable
                    tableColumns={factoryColumns}
                    tableData={deleteConnections}
                />
            </DeleteModal>
        </React.Fragment>
    )
}

export default FactoryListForm;