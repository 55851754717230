import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Section, Action, EntityType } from '../../helpers/enums';
import StorageLayout from './StorageLayout';
import EntityLayout from '../common/EntityLayout';
import { ClientInputsColumns } from './Columns';
import { getBaseType } from '.';
import { isStorageOwner } from '../../helpers/commonHelpers';

export function ClientInterfaces({ msalInstance }){

    const [enabledActions, setEnabledActions] = useState([]);

    let selectedStorage = useSelector(state => state.workspace.activeItem);
    let currentUser = useSelector(state => state.application.user);

    const setValues = (entity, newValues, action) => {
        if (action && action === Action.EDIT){
            entity.code = newValues.code;
            entity.typeId = newValues.typeId;
            entity.namespace = newValues.namespace;
            entity.description = newValues.description;
        }
        entity.type = getBaseType(newValues.typeId, newValues.type);
    }

    const columns = ClientInputsColumns();
    
    useEffect(() => {
        if (currentUser) {
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }];
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            if (isStorageOwner(currentUser, selectedStorageId)) {
                userActions.push({ action: Action.CREATE });
                userActions.push({ action: Action.EDIT });
                userActions.push({ action: Action.COPY });
                userActions.push({ action: Action.DELETE });
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage]);

    return (
        <StorageLayout
            title="Manage supported client inputs"
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                <b>Client inputs</b> are values that can be received from the client application. These can be used as inputs to elements, whose values are being evaluated.
                <br />Use syntax <mark>Client(&quot;CODE&quot;)</mark> to refer to client inputs. This function is available in <b>value types</b> and <b>elements</b>.
            </div>
            <EntityLayout
                type={Section.WORKSPACE}
                entityType={EntityType.CLIENTINTERFACE}
                columns={columns}
                setValues={setValues}
                enabledActions={enabledActions}
                tableStyle="action-3"
                msalInstance={msalInstance}
            />
        </StorageLayout>
    );
}