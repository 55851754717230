import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormGroup, Col, Label, Button, ButtonToolbar, Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import { CustomSpinner } from '../../common/CustomSpinner';
import ModalBase from '../../common/modals/ModalBase';
import FormModalBase from '../../common/modals/FormModalBase';
import SimpleCustomTable from '../../common/SimpleCustomTable';
import { Action, Role } from '../../../helpers/enums';
import { callAPI } from '../../../helpers/api';
import { StorageUserColumns } from '../Columns';
import { Error } from '../../common/Error';
import { X } from 'react-bootstrap-icons';
import StorageFactoryRoles from '../StorageFactoryRoles';
import { getActionsColumnDef } from '../../../helpers/tableHelpers';

const schema = yup.object().shape({
    factories: yup.array().nullable()
        .when('role', {
            is: Role.READER || Role.CONTRIBUTOR,
            then: yup.array().min(1, 'Select at least one Factory').required('Select a Factory')
        }),
});

export default function StorageUserModal({
    isOpen,
    toggle,
    onUsersAdded,
    msalInstance,
    user
}) {
    let selectedStorage = useSelector(state => state.adminStorages.activeItem);

    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [nameError, setNameError] = useState(null);
    const [searchError, setSearchError] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [, setShoudRender] = useState(false);
    const [submitted, setSubmitted] = useState(0);
    const [isStorageOwner, setIsStorageOwner] = useState(false);
    const [roles, setRoles] = useState([]);

    const title = Action.ADD + " users";

    const { handleSubmit, reset, setError, formState: { errors, isValid }, control } =
        useForm({ resolver: yupResolver(schema), mode: 'onChange' });
    //console.log('isValid, submitDisabled, errors', isValid, submitDisabled, errors);

    //let factoryOptions = getFactoryOptions(storageFactories.filter(f => f.isSelected));

    const onSubmit = async () => {
        setSubmitDisabled(true);
        const rolesForBody = isStorageOwner ? [{
            storageId: selectedStorage.id,
            role: Role.OWNER
        }] : (roles?.length > 0 ?
            roles.map(r => {
                return {
                    storageId: selectedStorage.id,
                    role: r.role,
                    publishingRole: r.publishingRole,
                    factoryId: r.factoryId
                };
            }) : [{
                storageId: selectedStorage.id,
                role: Role.NONE
            }]);
        const body = selectedUsers.map(user => {
            return {
                id: user.id,
                roles: rolesForBody
            }
        });
       
        let method = 'POST';
        let result = await callAPI("user/roles/" + selectedStorage.id, msalInstance, user, body, method);
        let newUsers = result.json;
        if (result.errorMsg && !newUsers) {
            setError("createError", {
                type: "manual",
                message: result.errorMsg
            });
        }
        else {
            reset();
            if (onUsersAdded) {
                onUsersAdded(newUsers);
            }
            modalIsClosed();
            toggle();
        }
        setSubmitted(submitted + 1);
    }

    const search = async () => {
        setSearchError(null);
        setNameError(null);
        if (searchTerm && searchTerm.length > 2) {
            setIsLoading(true);
            let url = "user/storage/" + (selectedStorage ? selectedStorage.id : 0) + "/search?term=" + searchTerm;
            let result = await callAPI(url, msalInstance, user);
            var userResult = result.json;
            if (result.errorMsg && !userResult) {
                setSearchError(result.errorMsg);
                setUsers([]);
            }
            else {
                setUsers(userResult);
                if (userResult.length === 0) {
                    setSearchError('No users found! If you cannot find the required user, ask an administrator to add it into RUCA users from Azure AD.')
                }
            }
            setIsLoading(false);
        }
        else {
            setNameError("name must be at least 3 characters");
        }
    }

    const render = () => {
        setShoudRender((t) => !t);
    }

    const addUser = (selected) => {
        // Has the user been added into the list already
        var index = selectedUsers.findIndex(i => i.id === selected.id);
        if (index < 0) {
            setSelectedUsers([...selectedUsers, {
                id: selected.id, name: selected.name
            }]);
            render();
            setSubmitDisabled(false);
        }
    }

    const removeUser = (id) => {
        setSelectedUsers(selectedUsers.filter(u => u.id !== id));
        render();
        setSubmitDisabled(selectedUsers.length <= 0);
    }

    const resetValues = () => {
        reset();
        setSearchTerm("");
        setUsers([]);
        setSearchError(null);
        setSelectedUsers([]);
        setRoles([]);
    }

    const modalIsClosed = () => {
        resetValues();
    }

    const checkName = e => {
        setSearchError(null);
        setSearchTerm(e.target.value);
        if (e.target.value && e.target.value.length > 2) {
            setNameError(null);
        }
    }

    const cellFn = (original) => {
        return (<ButtonToolbar>
            <Button
                color="primary"
                className="ms-3 me-1 btn-xs"
                title={"Add as " + (selectedStorage && selectedStorage.code ? selectedStorage.code : "storage") + " user"}
                onClick={() => addUser(original)}
            >
                <img className="icon" src="./icons/button_create.svg" alt="" />
            </Button>
        </ButtonToolbar>);
    }
    const columnsWithActions = [...StorageUserColumns(), getActionsColumnDef(cellFn)];

    useEffect(() => {
        if (isOpen) {
            // Let's reset all values.
            resetValues();
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const UserTags = ({ users }) => {
        return (<ButtonToolbar>
            {users.map(user =>
                <Button key={"tag_" + user.id } outline color="success" className="me-1 mb-1" title="Remove" onClick={() => removeUser(user.id)}>
                    {user.name}
                    <X size={30} />
                </Button>
            )}
        </ButtonToolbar>)
    }

    if (!selectedStorage || selectedStorage.id <= 0) {
        return (<ModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            subTitle="Not found!"
            formIsValid={isValid}>
            <p>No storage selected!</p>
        </ModalBase>)
    }

    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            subTitle={selectedStorage ? selectedStorage.code : ""}
            className="modal-lg"
            onSubmit={handleSubmit(data => onSubmit(data))}
            actionButtonText={Action.ADD}
            formIsValid={isValid && !submitDisabled && !errors.createError}
            onModalIsClosing={modalIsClosed}
            setNotLoading={submitted}
        >
            <div className="border-bottom mb-2">
                <h4>Search users</h4>
            </div>
            <FormGroup row>
                <Label for="name" sm={3}>Name or email</Label>
                <Col sm={6}>
                    <Controller name="name" control={control} defaultValue={searchTerm} render={({ field }) =>
                        <Input {...field} type="text" onChange={checkName} value={searchTerm} />
                    }
                    />
                    {nameError && <p className="text-danger">{nameError}</p>}
                </Col>
                <Col sm={3}>
                    <Button
                        color="primary"
                        className="me-1"
                        disabled={searchTerm.length < 3}
                        onClick={() => search()}
                    >Search
                    </Button>
                </Col>
            </FormGroup>
            {loading && <CustomSpinner />}
            {(!loading && searchError) && <Error msg={searchError} />}
            {!loading && !searchError && users && users.length > 0 &&
                <SimpleCustomTable
                    tableColumns={columnsWithActions}
                    tableData={users}
                />
            }
            {selectedUsers && selectedUsers.length > 0 &&
                <React.Fragment>
                    <div className="border-bottom mt-4 mb-2">
                        <h4>Set user rights within storage</h4>
                    </div>
                    <FormGroup row className="required form-group">
                        <Label for="users" sm={3}>Users</Label>
                        <Col sm={9}>
                            <ButtonToolbar>
                                <UserTags users={selectedUsers} />
                            </ButtonToolbar>
                            {errors.newUsers && <p className="text-danger">{errors.newUsers}</p>}
                        </Col>
                    </FormGroup>
                    <StorageFactoryRoles
                        roles={roles}
                        isStorageOwner={isStorageOwner}
                        setIsStorageOwner={setIsStorageOwner}
                        setRoles={setRoles}
                    />
                </React.Fragment>
            }
            {errors.createError && <p className="text-danger">{errors.createError.message}</p>}
        </FormModalBase>
    );
}