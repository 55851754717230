import React, { useState } from "react";
import {
    Pagination,
    PaginationItem,
    PaginationLink,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { setLocalStorageValue } from '../../helpers/localStorageHelpers';

export default function CustomPaging({
    table, 
    pageSizes, 
    pageKey
}){
    const defaultDropdownValue = "Show ";
    const pageCount = table.getPageCount();
    const pageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize
    const [dropdownOpen, setOpen] = useState(false);
    const [dropdownValue, setDropdownValue] = useState(defaultDropdownValue + pageSize);
    const toggle = () => setOpen(!dropdownOpen); //console.log('pageCount, pageIndex', pageCount, pageIndex);

    const selectedPageSize = (size) => {
        //console.log('Show ' + size + ' items!');
        setDropdownValue(defaultDropdownValue + size);
        table.setPageSize(size);
        setLocalStorageValue(pageKey, size);
    }

    const sequenceLength = 3;
    let pagingStart = pageIndex - sequenceLength;//console.log('pagingStart', pagingStart);
    let pagingEnd = pageIndex + sequenceLength;//console.log('pagingEnd', pagingEnd);
    if (pagingStart < 0){
        pagingStart = 0;
        pagingEnd = 8;
    }
    else if (pagingEnd > pageCount - 1){
        pagingEnd = pageCount - 1;
        pagingStart = pageCount - 1 - (2 * sequenceLength);
    }
    //console.log('pagingStart', pagingStart);console.log('pagingEnd', pagingEnd);

    const CustomPaginationItem = ({currentIndex}) => {
        const pageNbr = currentIndex + 1; //console.log();
        if (pageNbr === 1 || pageNbr === pageCount // First and last page is always shown
            || (pageNbr > pagingStart && currentIndex <= pagingEnd)){
            return(
                <PaginationItem active={currentIndex === pageIndex}>
                    <PaginationLink
                        onClick={() => table.setPageIndex(currentIndex)}>{pageNbr}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        if (pageNbr === pagingStart || currentIndex === pagingEnd + 1){
            return (
                <PaginationItem>
                    <PaginationLink disabled={true}>...</PaginationLink>
                </PaginationItem>);
        }

        return null;
    }

    const CustomPaginationItems = ({ count }) => {
        const pages = Array(count).fill(null); //console.log('pages', pages);
        if (count > 10) {
            return (pages.map((x, index) =>
                <CustomPaginationItem
                    currentIndex={index}
                    pageKey={pageKey}
                    key={pageKey + index} />)
            );
        }
        return (pages.map((x, index) =>
            <PaginationItem
                key={pageKey + index}
                active={index === pageIndex}>
                <PaginationLink
                    onClick={() => table.setPageIndex(index)}>{index + 1}
                </PaginationLink>
            </PaginationItem>
        ));
    }

    if (pageCount === 0) {
        return (<></>);
    }
  
    return (
        <Pagination className="d-flex flex-row">
            { pageSizes && pageSizes.length > 0 &&
                <ButtonDropdown
                    className="me-2 bg-white"
                    isOpen={dropdownOpen}
                    toggle={toggle}>
                    <DropdownToggle caret size="sm" outline>
                        {dropdownValue}
                    </DropdownToggle>
                    <DropdownMenu>
                    { pageSizes.map(size =>
                        <DropdownItem
                            key={size}
                            onClick={() => selectedPageSize(size)}>{defaultDropdownValue + size}
                        </DropdownItem>
                    )}
                    </DropdownMenu>
              </ButtonDropdown>
            }
            <PaginationItem key={pageKey + "first"}>
                <PaginationLink first
                    onClick={() => table.firstPage()}
                    disabled={!table.getCanPreviousPage()}
                />
            </PaginationItem>
            <PaginationItem  key={pageKey + "previous"}>
                <PaginationLink previous
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                />
            </PaginationItem>
            <CustomPaginationItems count={pageCount} />
            <PaginationItem key={pageKey + "next"}>
                <PaginationLink next
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                />
            </PaginationItem>
            <PaginationItem key={pageKey + "last"}>
                <PaginationLink last
                    onClick={() => table.lastPage()}
                    disabled={!table.getCanNextPage()}
                />
            </PaginationItem>
          </Pagination>
    )
}