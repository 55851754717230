import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StorageList from '../common/StorageList';
import FactoryList from '../common/FactoryList';
import { CUSTOM_ELEMENT_TYPE, subMenuItems, shouldBeRefreshed, setStorageFactories, setStorageUnits,
    setStorageValueTypes, setStorageCategories, setStorageElementTypes, setStorageUnitGroups } from './';
import workspaceActions from '../../actions/workspaceActions';
import { Section, Role, ListType } from '../../helpers/enums';
import { Container, Tooltip } from 'reactstrap';
import ElementTypeList from './ElementTypeList';
import UnitGroupList from './UnitGroupList';
import { isAdmin, VALUE_NOT_CALCULABLE } from '../../helpers/commonHelpers';
import { NotAuthorized } from '../common/NotAuthorized';
import SiteLayout from '../common/SiteLayout';
import applicationActions from '../../actions/applicationActions';
import { setLocalStorageValue } from '../../helpers/localStorageHelpers';
import { STORAGE_LOCALSTORAGE_KEY } from '../../helpers/commonHelpers';

export default function StorageLayout({
    title, 
    onActiveStorageChanged, 
    children, 
    showFactoryList = false, 
    showElementList = false,
    showUnitGroupList = false,
    msalInstance
}){
    const dispatch = useDispatch();//console.log('Loaded storage layout with title', title);
    
    let type = Section.WORKSPACE;

    let currentUser = useSelector(state => state.application.user);
    let isUnauthorize = useSelector(state => state.application.userUnauthorized);
    let selectedStorage = useSelector(state => state[type].activeItem);
    //console.log('selectedStorage', selectedStorage );console.log('currentUser', currentUser, currentUser.roles.filter(r => r.storageId === selectedStorage.id && r.role > Role.NONE));
    
    const isStorageUser = isAdmin(currentUser) || !selectedStorage?.id ||
        currentUser.roles.some(r => r.storageId === selectedStorage.id && r.role > Role.NONE);

    let storages = useSelector(state => state[type].list);
    let lastRefreshed = useSelector(state => state[type].refreshed);
    let selectedFactories = useSelector(state => state[type].selectedFactories);
    let refreshStorageList = !storages || storages.length === 0 || shouldBeRefreshed(lastRefreshed); //console.log('refreshStorageList', refreshStorageList);
    let selectedElementTypes = useSelector(state => state[type].selectedElementTypes);

    const [showTooltip, setShowTooltip] = useState(false);
    const [showUnAuthorized, setShowUnAuthorized] = useState(isUnauthorize);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    }

    const setStorages = (storages) => {
        dispatch(workspaceActions.setStorages(storages));
        dispatch(workspaceActions.setRefreshedDate());
    }

    const setSelectedStorageEntities = (entities, currentSelected, setMethod) => {
        if (entities && entities.length > 0){
            // Let's keep the already selected entities if those can be found from currently selected list
            if (currentSelected && currentSelected.length > 0){
                // Lets put only the entities that can be found from storage factory list into the list of selected ones.
                const ids = entities.map(f => f.id);//console.log('ids', ids);
                const newSelected = currentSelected.filter(f => ids.includes(f));//console.log('newSelected', newSelected);
                dispatch(setMethod(newSelected));
            }
        }
        else{
            dispatch(setMethod([]));
        }
    }

    const setSelectedStorageFactories = (factories) => {
        // console.log('Factories', factories);
        setSelectedStorageEntities(factories, selectedFactories, workspaceActions.setSelectedFactories);
    }

    const setSelectedStorageElementTypes = (elementTypes) => {
        //console.log('elementTypes', elementTypes);
        let enrichedElementTypes = [...elementTypes, CUSTOM_ELEMENT_TYPE];//console.log('enrichedElementTypes', enrichedElementTypes);
        setSelectedStorageEntities(enrichedElementTypes, selectedElementTypes, workspaceActions.setSelectedElementTypes);
    }

    const setStorageData = (storage, dispatch) => {
        //console.log('Storage.js - Starting to load storage data');
        const authUser = currentUser ? currentUser.authUser : {};
        const promises = [
            new Promise((resolve) =>{
                resolve(setStorageFactories(storage.id, dispatch, msalInstance, authUser));
            }),
            new Promise((resolve) =>{
                resolve(setStorageValueTypes(storage.id, dispatch, msalInstance, authUser));
            }),
            new Promise((resolve) =>{
                resolve(setStorageCategories(storage.id, dispatch, msalInstance, authUser));
            }),
            new Promise((resolve) =>{
                resolve(setStorageUnits(storage.id, dispatch, msalInstance, authUser));
            }),
            new Promise((resolve) =>{
                resolve(setStorageElementTypes(storage.id, dispatch, msalInstance, authUser));
            }),
            new Promise((resolve) =>{
                resolve(setStorageUnitGroups(storage.id, dispatch, msalInstance, authUser));
            })
        ];

        Promise.all(promises)
        .then((responses) => {
            //console.log(responses);
            dispatch(workspaceActions.setDataLoading(false)); //console.log('Storage data loaded!!!');
            // Set the selected factories & element types
            setSelectedStorageFactories(responses && responses.length > 0 ? responses[0]: []);
            setSelectedStorageElementTypes(responses && responses.length > 6 ? responses[6]: []);
            //setSelectedUnitGroups(responses && responses.length > 7 ? responses[7]: []);
        })
        .catch((err) => {
            console.log('Something went wrong while loading storage data!', err);
        });
    }

    const setActive = (storage) => {
        //console.log('Set active storage!');
        var storageId = 0;
        if (storage && storage.id > 0) {
            dispatch(workspaceActions.setDataLoading(true));
            dispatch(workspaceActions.setActiveStorage(storage));
            if (onActiveStorageChanged) {
                onActiveStorageChanged(storage);
            }
            dispatch(workspaceActions.setSelectedUnitGroups([]));
            setStorageData(storage, dispatch);
            storageId = storage.id;
        }
        else {
            // There were no storages found for the user
            dispatch(workspaceActions.setDataLoading(false));
        }
        // Put the selected value into localStorage
        setLocalStorageValue(STORAGE_LOCALSTORAGE_KEY, storageId); //console.log('storage set into localStorage', storageId);
    }

    const setSelectedFactories = (factoryIds) => { 
        dispatch(workspaceActions.setSelectedFactories(factoryIds))
    }

    const setSelectedElementTypes = (ids) => { 
        dispatch(workspaceActions.setSelectedElementTypes(ids));
    }

    const setSelectedUnitGroups = (ids) => {
        //console.log('selected unit groups', ids);
        dispatch(workspaceActions.setSelectedUnitGroups(ids));
    }

    const isLoading = (isLoading) => {
        dispatch(workspaceActions.setLoading(isLoading));
    }

    const setUserUnauthorized = (reset) => {
        if (reset) {
            console.log('Reset user because no privileges!');
            dispatch(workspaceActions.setActiveStorage({}));
            dispatch(applicationActions.setUserUnauthorized(true));
            setShowUnAuthorized(true);
        }
        else {
            dispatch(applicationActions.setUserUnauthorized(false));
            setShowUnAuthorized(false);
        }
    }

    const sideMenu = <React.Fragment>
        <StorageList
            setStorages={setStorages}
            setActiveStorage={setActive}
            type={type}
            refreshStorages={refreshStorageList}
            msalInstance={msalInstance}
            loadingStorages={isLoading}
            mode={ListType.COLLAPSE}
            setUserUnauthorized={setUserUnauthorized}
        />
        {showElementList &&
            <ElementTypeList setSelectedElementTypes={setSelectedElementTypes}/>
        }
        {showFactoryList &&
            <FactoryList mainStoreProperty={type} setSelectedFactories={setSelectedFactories} />
        }
        {showUnitGroupList &&
            <UnitGroupList setSelectedUnitGroups={setSelectedUnitGroups} />
        }
    </React.Fragment>

    if (showUnAuthorized) {
        return (<NotAuthorized centered={true} />);
    }

    return (
        <SiteLayout
            sideMenu={sideMenu}
            subMenuItems={subMenuItems}
        >
            <Container fluid={true} className="mt-1 mb-3">
                <h1>
                    {title}
                    {selectedStorage &&
                        <>
                            <span className="heading-info ms-2">{selectedStorage.code}</span>
                            <span className="ms-1" id="tooltip">
                                <img src="./icons/information.svg" className="icon icon-modal ms-1" alt="Storage owners" />
                                <Tooltip placement="bottom" isOpen={showTooltip} target="tooltip" toggle={toggleTooltip}>
                                    Storage owners:
                                    <ul>
                                        {
                                            selectedStorage.owners && selectedStorage.owners.length > 0 ?
                                                selectedStorage.owners.map(function (name, index) {
                                                    return <li key={index}>{name.toLowerCase()}</li>;
                                                }) :
                                            <li>{VALUE_NOT_CALCULABLE}</li>
                                        }
                                    </ul>
                                </Tooltip>
                            </span>
                        </>
                    }
                </h1>
            </Container>
            {isStorageUser ? children :
                <Container fluid={true} className="mt-1 mb-3">
                    <NotAuthorized centered={false} />
                </Container>}
        </SiteLayout>
    );
}