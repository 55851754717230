import { Spinner } from 'reactstrap';

export function CustomSpinner({
    margin = true
}) {

    let customClass = "d-flex justify-content-center";
    if (margin) {
        customClass += " mt-4";
    }

    return (
        <div className={customClass}><Spinner color="secondary" /></div>
    );
}