import React, { forwardRef, useRef } from 'react';
import { Input } from 'reactstrap';

export const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, disabled, hideIcon, ...rest }, ref) => {
        const defaultRef = useRef(); //console.log('rest', rest);
        const resolvedRef = ref || defaultRef;
  
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        if (disabled) {
            if (hideIcon) {
                return (<span ref={resolvedRef} />)
            }
            return (<span ref={resolvedRef}><img src="./icons/access_readonly.svg" className="icon icon-table readonly" title="Read-only access" alt="" /></span>)
        }
        return (
            <Input type="checkbox" ref={resolvedRef} {...rest} />
        )
    }
  )