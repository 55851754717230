import React, { useState } from 'react';
import StorageList from '../common/StorageList';
import FactoryListForm from './FactoryListForm';
import StorageForm from './StorageForm';
import { useDispatch, useSelector } from 'react-redux';
import adminStorageActions from '../../actions/adminStorageActions';
import { Section, Role } from '../../helpers/enums';
import StorageUsers from './StorageUsers';
import AdminLayout from './AdminLayout';

function Storages({ msalInstance }) {
    const editTitle = "Manage storage";

    let storagesLoaded = useSelector(state => state.adminStorages.loaded);
    let activeStorage = useSelector(state => state.adminStorages.activeItem);

    const [title, setTitle] = useState(editTitle);
    const [storage, setStorage] = useState(activeStorage ? activeStorage : {});
    const [storageAdded, setStorageAdded] = useState(false);
    const [resetErrors, setResetErrors] = useState(false);
    const dispatch = useDispatch();
    const setStorages = (storages) => {
        dispatch(adminStorageActions.setStorages(storages));
    }

    const setActiveStorage = (storage, newButtonClicked = false) => {
        setStorage(storage);
        dispatch(adminStorageActions.setActiveStorage(storage));
        if (newButtonClicked){
            setResetErrors(true);
            setTitle("Create new storage")
        }
        else{
            setResetErrors(false);
            setTitle(editTitle)
        }
    }

    const sideMenu = <StorageList
        setStorages={setStorages}
        setActiveStorage={setActiveStorage}
        createStorageEnabled="true"
        refreshStorages={!storagesLoaded}
        type={Section.ADMIN_STORAGES}
        newStorageAdded={storageAdded}
        msalInstance={msalInstance} />

    return (
        <AdminLayout
            title={title}
            subTitle={storage?.code}
            sideMenu={sideMenu}
            requiredRole={Role.OWNER}
        >
            <StorageForm
                onStorageAdded={() => setStorageAdded(true)}
                resetErrors={resetErrors}
                msalInstance={msalInstance}>
            </StorageForm>

            <FactoryListForm msalInstance={msalInstance} />
            <StorageUsers msalInstance={msalInstance} />
        </AdminLayout>
    );
}

export default Storages;