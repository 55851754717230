import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    // ABB dev
    auth: {
        authority: "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
        //clientId: "38f5d32a-0808-4f62-b229-e0ca572aa1f7", // Dev - Application (client) ID from Overview blade in App Registration
        clientId: "cd707034-40e0-47b6-889f-6ae71454c926", // Stage - Application (client) ID from Overview blade in App Registration
        //clientId: "fa5c41a8-db8e-4888-9e76-c8eac40bf108", // Prod - Application (client) ID from Overview blade in App Registration
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,

        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: false
    },
    system: {
        loggerOptions: {
            //loggerCallback: (level, message, containsPii) => {
            //    if (containsPii) {
            //        return;
            //    }
            //    switch (level) {
            //        case LogLevel.Error:
            //            console.error(message);
            //            return;
            //        case LogLevel.Info:
            //            console.info(message);
            //            return;
            //        case LogLevel.Verbose:
            //            console.debug(message);
            //            return;
            //        case LogLevel.Warning:
            //            console.warn(message);
            //            return;
            //        default:
            //            return;
            //    }
            //}
        }
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    //scopes: ["api://6d90b990-c557-46fa-8b10-e0cfd3840459/access-as-user"] // prod scope
    scopes: ["api://ef742193-1091-43a3-9ec4-fe539d65eb7a/access-as-user"] // stage scope
    //scopes: ["api://8116904c-5fe9-4b97-af2b-534e468abddf/access-as-user"] // dev scope
};

export const graphRequest = {
    scopes: ['User.ReadBasic.All']
}