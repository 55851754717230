import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormModalBase from '../../common/modals/FormModalBase';
import { getButtonText } from '../../../helpers/commonHelpers';
import { Action } from '../../../helpers/enums';
import { callAPI } from '../../../helpers/api';

export default function EntityModalBase({
    isOpen,
    toggle,
    status,
    className,
    titleBase,
    baseUrl,
    getData,
    createNewRevision = false,
    formIsValid = false,
    errors,
    setError,
    reset,
    handleSubmit,
    entityIsCreated,
    entityIsUpdated,
    children,
    msalInstance
}) {
    const [submitCount, setSubmitCount] = useState(0);
    const selectedStorage = useSelector(state => state.workspace.activeItem);
    const currentUser = useSelector(state => state.application.user);
    //console.log('isValid, errors', formIsValid, errors);

    const title = (createNewRevision ? Action.CREATE_NEW_REVISION : status) + " " + titleBase;
    const buttonText = getButtonText(status);

    const onSubmit = async (data) => {
        const body = getData ? getData(data) : { ...data };
        //console.log('body', body, formIsValid, errors);
        if (formIsValid) {
            if (selectedStorage) {
                body.storageId = selectedStorage.id;
            }
            let method = 'POST';
            if (status === Action.EDIT) {
                method = 'PUT';
            }
            let result = await callAPI(baseUrl, msalInstance, currentUser.authUser, body, method);
            let entity = result.json;
            if (result.errorMsg && !entity) {
                setError("submitError", {
                    type: "manual",
                    message: result.errorMsg
                });
            }
            else {
                reset();
                if (status === Action.EDIT) {
                    entityIsUpdated(entity);
                }
                else {
                    entityIsCreated(entity);
                }
                toggle();
            }
        }
        setSubmitCount(submitCount + 1);
    }

    const onToggle = () => {
        reset();
        toggle();
    }

    //console.log('set not loading', submitCount, errors);
    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={onToggle}
            className={className}
            title={title}
            subTitle={selectedStorage ? selectedStorage.code : ""}
            onSubmit={handleSubmit(onSubmit)}
            actionButtonText={buttonText}
            formIsValid={formIsValid}
            disabled={status === Action.VIEW}
            setNotLoading={submitCount}
        >
            {children}
            {errors.submitError && <p className="text-danger">{errors.submitError.message}</p>}
        </FormModalBase>
    );
}