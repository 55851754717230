import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import adminFactoryActions from '../../actions/adminFactoryActions';
import AdminFormBase from './AdminFormBase';
import { EntityType } from '../../helpers/enums';

const schema = yup.object().shape({
    code: yup.string().label('Code').required().max(50),
    name: yup.string().label('Description').required().max(50),
    factorySymbol: yup.string().label('Symbol').max(5)
});

function FactoryForm({ msalInstance }){
    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, setValue } = useForm({
        resolver: yupResolver(schema), mode: 'onChange'
    });

    let activeFactory = useSelector(state => state.adminFactories.activeItem);

    const setFormValues = (factory) => {
        let id = factory.id ?? 0;
        let code = factory.code ?? "";
        let name = factory.name ?? "";
        let symbol = factory.factorySymbol ?? ""
        setValue("id", id);
        setValue("code", code);
        setValue("name", name);
        setValue("factorySymbol", symbol);
    }

    return (
        <AdminFormBase
            entityType={EntityType.FACTORY}
            setActiveAction={adminFactoryActions.setActiveFactory}
            setEntitiesAction={adminFactoryActions.setFactories}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            setFormValues={setFormValues}
            msalInstance={msalInstance}>
            <FormGroup className="required form-group">
                <Label for="code">Factory code</Label>
                <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field}
                        type="text"
                        disabled={activeFactory.isGlobal}
                    />}
                />
                {errors.code && <p className="text-danger">{errors.code.message}</p>}
            </FormGroup>
            <FormGroup className="required form-group">
                <Label for="name">Factory description</Label>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field}
                        type="text"
                        disabled={activeFactory.isGlobal}
                    />}
                />
                {errors.name && <p className="text-danger">{errors.name.message}</p>}
            </FormGroup>
            <FormGroup className="required form-group">
                <Label for="factorySymbol">Factory symbol</Label>
                <Controller
                    name="factorySymbol"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field}
                        type="text"
                    />}
                />
                {errors.factorySymbol && <p className="text-danger">{errors.factorySymbol.message}</p>}
            </FormGroup>
        </AdminFormBase>
    )
}

export default FactoryForm;