import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { PublishingRole, PublishingRoleText } from '../../helpers/enums';
import { parseToNumber } from '../../helpers/commonHelpers';

export default function PublishingRoleRadioButtons({
    field,
    defaultValue,
    onRoleChanged
}) {
    const [value, setValue] = useState(defaultValue);

    const handleRoleChange = (e) => {
        let newValue = 1;
        if (e.target.value) {
            newValue = parseToNumber(e.target.value);
        }
        setValue(newValue);
        if (onRoleChanged) {
            onRoleChanged(newValue);
        }
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])

    return (<>
        <FormGroup check className="mt-2">
            <Label check>
                <Input {...field}
                    type="radio"
                    name="publishingRole"
                    value={PublishingRole.NONE}
                    checked={value === PublishingRole.NONE}
                    onChange={handleRoleChange}
                />
                <div className='ms-2'>{PublishingRoleText.NONE}</div>
            </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
            <Label check>
                <Input {...field}
                    type="radio"
                    name="publishingRole"
                    value={PublishingRole.STAGE}
                    checked={value === PublishingRole.STAGE}
                    onChange={handleRoleChange}
                />
                <div className='ms-2'>{PublishingRoleText.STAGE}</div>
            </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
            <Label check>
                <Input {...field}
                    type="radio"
                    name="publishingRole"
                    value={PublishingRole.PROD}
                    checked={value === PublishingRole.PROD}
                    onChange={handleRoleChange}
                />
                <div className='ms-2'>{PublishingRoleText.PROD}</div>
            </Label>
        </FormGroup>
    </>)
}