import { ActionTypes } from "../helpers/enums";

const defaultValue = {
    list: [], 
    activeItem: {},
}

const adminFactories = (state = defaultValue, action)  => {
    switch (action.type){
        case ActionTypes.SET_ADMIN_FACTORIES:
            return{
                ...state,
                list: action.payload
            }
        case ActionTypes.SET_ADMIN_ACTIVE_FACTORY:
            return{
                ...state,
                activeItem: action.payload
            }
        default:
            return state;
    }
}

export default adminFactories;