import { EntityType, EntityStatus, ElementStatusText, Section } from '../../helpers/enums';
import { getFlagOptions } from '../../helpers/commonHelpers';

const ConfirmTXT = "I understand this action cannot be undone.";
const getRevisionEntityConfirmText = (txt) => {
    return "I understand that deleting the displayed " + txt + " will also delete all their past revisions (viewable in the log modal). This action cannot be undone.";
}

const getCategoryOptions = (categories) => {
    //console.log('categories',categories);
    return categories && categories.length > 0 ? categories.map(c => {
        return { value: c.id, label: c.name }
    }) : [];
}

export const getEntityProperties = (entityType, categories, flags) => {
    switch (entityType) {
        case EntityType.ITEM:
            return {
                type: EntityType.ITEM,
                single: "item",
                plural: "items",
                baseUrl: "item/",
                revisionEntity: false,
                confirmText: "I understand that the below items and all their elements will be deleted. This action cannot be undone.",
                deleteModalClass: "modal-xl",
                tableWithStates: true,
                filters: [
                    {
                        id: "global",
                        isGlobal: true,
                    },
                    {
                        id: "category",
                        placeholder: "Categories...",
                        options: getCategoryOptions(categories)
                    },
                    {
                        id: "elementStatus",
                        placeholder: "Elements...",
                        options: [
                            { value: EntityStatus.PROD, internalLabel: ElementStatusText.ALL, label: ElementStatusText.ALL },
                            { value: EntityStatus.STAGE, internalLabel: ElementStatusText.SOME, label: ElementStatusText.SOME },
                            { value: 100, internalLabel: ElementStatusText.ALL + ',' + ElementStatusText.SOME, label: ElementStatusText.WITH },
                            { value: EntityStatus.INWORK, internalLabel: ElementStatusText.WITHOUT, label: ElementStatusText.WITHOUT },
                        ]
                    },
                    {
                        id: "flagSymbols",
                        placeholder: "Flags...",
                        options: getFlagOptions(flags),
                        isMulti: true
                    }
                ],
                tableWidth: 12, pageSizes: [5, 10, 15]
            };
        case EntityType.ELEMENT:
            return {
                type: EntityType.ELEMENT,
                single: "element",
                plural: "elements",
                baseUrl: "element/",
                revisionEntity: true,
                confirmText: getRevisionEntityConfirmText("elements"),
                deleteModalClass: "modal-xl",
                tableWidth: 12,
                pageSizes: [10, 20, 30]
            };
        case EntityType.PARAMETER:
            return {
                type: EntityType.PARAMETER,
                single: "parameter",
                plural: "parameters",
                baseUrl: "parameter/",
                revisionEntity: true,
                confirmText: getRevisionEntityConfirmText("parameters"),
                deleteModalClass: "modal-xl",
                tableWidth: 12,
                pageSizes: [10, 20, 30]
            };
        case EntityType.CATEGORY:
            return {
                type: EntityType.CATEGORY,
                single: "category",
                plural: "categories",
                baseUrl: "category/",
                revisionEntity: false,
                deleteModalClass: "modal-md",
                filters: [{
                    id: "name",
                    isGlobal: true,
                }],
                tableWidth: 7,
                pageSizes: [10, 20, 30]
            };
        case EntityType.VALUETYPE:
            return {
                type: EntityType.VALUETYPE,
                single: "value type",
                plural: "value types",
                baseUrl: "valueType/",
                revisionEntity: true,
                confirmText: getRevisionEntityConfirmText("value types"),
                deleteModalClass: "modal-xl",
                tableWidth: 12,
                pageSizes: [10, 20, 30]
            };
        case EntityType.CLIENTINTERFACE:
            return {
                type: EntityType.CLIENTINTERFACE,
                single: "client input",
                plural: "client inputs",
                baseUrl: "clientInput/",
                revisionEntity: false,
                deleteModalClass: "modal-lg",
                tableWithStates: true,
                tableWidth: 10,
                pageSizes: [10, 20, 30]
            };
        case EntityType.UNIT:
            return {
                type: EntityType.UNIT,
                single: "unit",
                plural: "units",
                baseUrl: "unit/",
                revisionEntity: false,
                deleteModalClass: "modal-lg",
                tableWidth: 8,
                pageSizes: [10, 20, 30]
            };
        case EntityType.ELEMENTTYPE:
            return {
                type: EntityType.ELEMENTTYPE,
                single: "element type",
                plural: "element types",
                baseUrl: "elementType/",
                revisionEntity: false,
                deleteModalClass: "modal-lg",
                tableWidth: 8,
                pageSizes: [10, 20, 30]
            };
        case EntityType.UNITGROUP:
            return {
                type: EntityType.UNITGROUP,
                single: "unit group",
                plural: "unit groups",
                baseUrl: "unitGroup/",
                revisionEntity: false,
                deleteModalClass: "modal-md",
                filters: [{
                    id: "name",
                    isGlobal: true,
                }],
                tableWidth: 6,
                pageSizes: [10, 20, 30]
            };
        case EntityType.STORAGE:
            return {
                type: EntityType.STORAGE,
                single: "storage",
                plural: "storages",
                baseUrl: "storage/",
                section: Section.ADMIN_STORAGES,
                confirmText: ConfirmTXT
            }
        case EntityType.FACTORY: {
            return {
                type: EntityType.FACTORY,
                single: "factory",
                plural: "factories",
                baseUrl: "factory/",
                section: Section.ADMIN_FACTORIES,
                confirmText: ConfirmTXT
            }
        }
        default:
            return null;
    };
}
