import React, { useState, useEffect } from 'react';
import { callAPI } from '../../helpers/api';
import ListHeader from './ListHeader';
import { CustomSpinner } from './CustomSpinner';
import { Error } from './Error';
import ListGroupList from './ListGroupList';
import { useSelector } from 'react-redux';
import { IconType, Section, Role, ListType } from '../../helpers/enums';
import { CreateButton } from './CreateButton';
import CollapsibleList from './CollapsibleList';
import { getLocalStorageValue } from '../../helpers/localStorageHelpers';
import { STORAGE_LOCALSTORAGE_KEY } from '../../helpers/commonHelpers';

export default function StorageList({
    type, 
    setStorages, 
    setActiveStorage, 
    newStorageAdded = false, 
    createStorageEnabled = false, 
    refreshStorages = true,
    loadingStorages,
    msalInstance,
    mode = ListType.LISTGROUP,
    setUserUnauthorized
}){
    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const title = "Storages";
    const icon = IconType.FOLDER;

    const user = useSelector(state => state.application.user);
    const selectedStorageId = useSelector(state => state[type].selectedStorageId); 
    const storageDataIsLoading = useSelector(state => state[type].storageDataLoading) ?? false;
    const entitiesLoading = useSelector(state => state[type].entitiesLoading) ?? false;
    
    async function populateStorages() {//console.log('populateStorages!!!!!');
        if (loadingStorages) {
            loadingStorages(true);
        }
        const level = type === Section.WORKSPACE ? Role.READER : Role.OWNER;
        const includeOwners = type === Section.WORKSPACE ? true : false;
        let result = await callAPI('storage?level=' + level + '&includeOwners=' + includeOwners, msalInstance, user ? user.authUser : {});
        if (result.errorMsg && !result.json){
            setError(result.errorMsg);
            setIsLoading(false);
            setStorages([]);
            if (setUserUnauthorized) {
                setUserUnauthorized(result.unAuthorized ?? false);
            }
        }
        else{
            var storageList = result.json;
            setStorages(storageList);//console.log('storageList', storageList);
            if (storageList.length > 0){
                setError(null);
                if (selectedStorageId > 0){
                    if (!storageList.some(s => s.id === selectedStorageId)){
                        setActiveStorage(storageList[0]);//console.log('storageList[0]', storageList[0]);
                    }
                }
                else {
                    // Let's check if we can find the last viewed storage from localStorage
                    const lastViewedStorage = getLocalStorageValue(STORAGE_LOCALSTORAGE_KEY, 0); //console.log('Found from localStorage: ', lastViewedStorage, typeof lastViewedStorage);
                    if (lastViewedStorage > 0 && storageList.some(s => s.id === lastViewedStorage)) {
                        setActiveStorage(storageList.find(s => s.id === lastViewedStorage));
                    }
                    else {
                        setActiveStorage(storageList[0]);
                    }
                }
            }
            else{
                setError("No storages found!");
                setActiveStorage({});
            }
            setIsLoading(false);
        }
        if (loadingStorages) {
            loadingStorages(false);
        }
    }

    const createNewStorage = () => {
        setActiveStorage({}, true);
        setIsLoading(false);
        setError(null);
    }

    const setActive = (storage) => {
        setActiveStorage(storage);
    }

    const StorageListHeader = () => {
        if (createStorageEnabled && user.highestRole === Role.ADMIN){
            return (
                <ListHeader
                    title={title}
                    showButtons="true"
                    icon={icon}>
                    <CreateButton onClick={createNewStorage} />
                </ListHeader>
            );
        }

        return (<ListHeader title={title} icon={icon}></ListHeader>);
    }

    useEffect(() => {
        if (refreshStorages && user && user.id){
            populateStorages();
        }
        else{
            setIsLoading(false);
        }
    }, [refreshStorages]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>{
        if (newStorageAdded){
            setIsLoading(false);
            setError(null);
        }
    }, [newStorageAdded])

    if (loading){
        return (
            <>
                <StorageListHeader />
                <CustomSpinner />
            </>
        );
    }

    if (error){
        return (
            <>
                <StorageListHeader />
                <Error msg={error} />
            </>
        );
    }

    return (
        <>
            <StorageListHeader />
            {
                mode === ListType.LISTGROUP ?
                <ListGroupList
                    type={type}
                    onItemSelected={setActive}
                    disabled={loading || storageDataIsLoading || entitiesLoading}
                    showIconsFor={type === Section.WORKSPACE ? user.storageOwnerIds : []}
                    showIconForAll={type === Section.WORKSPACE ? user.highestRole === Role.ADMIN : false}
                    /> :
                <CollapsibleList
                    type={type}
                    onItemSelected={setActive}
                    disabled={loading || storageDataIsLoading || entitiesLoading}
                    showIconsFor={type === Section.WORKSPACE ? user.storageOwnerIds : []}
                    showIconForAll={type === Section.WORKSPACE ? user.highestRole === Role.ADMIN : false}
                />
            }
        </>
    );
}