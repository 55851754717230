import { combineReducers } from 'redux';
import adminFactories from './adminFactories';
import adminStorages from './adminStorages';
import application from './application';
import workspace from './workspace';
import inspector from './inspector';

const allReducers = combineReducers({
    adminFactories,
    adminStorages,
    application,
    workspace,
    inspector
});

export default allReducers;