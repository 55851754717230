import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ListHeader from '../common/ListHeader';
import { CustomSpinner } from '../common/CustomSpinner';
import { Error } from '../common/Error';
import CheckboxList from '../common/CheckboxList';
import { Section, IconType } from '../../helpers/enums';
import { CUSTOM_ELEMENT_TYPE } from '.';

export default function ElementTypeList({ setSelectedElementTypes }){
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const title = "Elements";
    const icon = IconType.PUZZLE;

    let property = Section.WORKSPACE;
    let elementTypes = useSelector(state => state[property].storageElementTypes);
    let selectedElementTypes = useSelector(state => state[property].selectedElementTypes);//console.log('selectedElementTypes', selectedElementTypes);
    let isStorageDataLoading = useSelector(state => state[property].storageDataLoading); //console.log('isStorageDataLoading', isStorageDataLoading);
    let isStoragesLoading = useSelector(state => state[property].storagesLoading);
    let extendedElementTypes = elementTypes && elementTypes.length > 0 ? [...elementTypes, CUSTOM_ELEMENT_TYPE] : [CUSTOM_ELEMENT_TYPE];
    
    const setActive = (ids) => {
        //console.log('Selected element types', ids);
        setSelectedElementTypes(ids);
    }
    
    useEffect(() => {
        if (isStorageDataLoading){
            setIsLoading(true);
        }
        else{
            setIsLoading(false);
            if (!extendedElementTypes || extendedElementTypes.length === 0){
                setError("No element types found!");
            }
            else {
                setError(null);//console.log('elementTypes', elementTypes);
            }
        }
    }, [isStorageDataLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isStoragesLoading) {
        return (<></>);
    }

    if (loading){
        return (
            <>
                <ListHeader title={title} icon={icon}></ListHeader>
                <CustomSpinner />
            </>
        );
    }

    if (error){
        return (
            <>
                <ListHeader title={title} icon={icon}></ListHeader>
                <Error msg={error} />
            </>
        );
    }

    return (
        <CheckboxList title={title}
            listItems={extendedElementTypes} 
            onItemSelected={setActive}
            selectedItems={selectedElementTypes}
            showButtons={true}
            icon={icon} />
    );
}