import React, { useEffect } from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Action, Section } from '../../../helpers/enums';
import { useSelector } from 'react-redux';
import { CustomSelect } from '../../common/CustomSelect';
import TextareaAutosize from 'react-textarea-autosize';
import EntityModalBase from './EntityModalBase';

const schema = yup.object().shape({
    code: yup.string().label('Code').required().max(50),
    description: yup.string().label('Description').required().max(100),
    internalId: yup.string().label('Internal ID').max(255),
    internalNotes: yup.string().label('Internal notes').max(1000)
});

function ItemModal({
    isOpen,
    toggle,
    status,
    selectedItem,
    filters,
    onEntityCreated,
    onEntityUpdated,
    msalInstance
}) {
    const type = Section.WORKSPACE; 
    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, setValue, clearErrors, trigger } = useForm({resolver: yupResolver(schema), mode: 'onChange'});
    //console.log('errors',errors);
    const storageCategories = useSelector(state => state[type].storageCategories);

    let categories = storageCategories && storageCategories.length > 0 ? storageCategories.map(c => { return {value: c.id, label: c.name}}) : [];

    let defaultValues = { 
        id: 0, 
        code: "",
        description: "",
        category: [],
        internalNotes: "",
        internalId: ""
    };

    const getData = (data) => {
        let { category, ...body } = data;
        if (data.category) {
            body.categoryId = data.category.value;
        } 
        return body;
    }

    const handleCodeChange = (input) => {
        clearErrors('submitError');
        trigger('submitError');
        setValue('code', input.target.value);
        trigger('code');
    }

    useEffect(() => {
        if (selectedItem && selectedItem.id > 0) {
            if (status !== Action.COPY) {
                setValue('id', selectedItem.id);
            }
            setValue('code', selectedItem.code);
            setValue('description', selectedItem.description);
            setValue('category', (selectedItem.categoryId !== 0 && selectedItem.category) ? { value: selectedItem.categoryId, label: selectedItem.category } : []);
            setValue('internalNotes', selectedItem.internalNotes ?? "");
            setValue('internalId', selectedItem.internalId ?? "");
            trigger();
        }
        else {
            setValue('id', 0);
            setValue('code', "");
            setValue('description', "");
            let selectedCategory = [];
            const categoryFilterValue = filters?.length > 0 && filters.some(f => f.id === 'category') ?
                filters.find(f => f.id === 'category').value : "";
            if (categoryFilterValue.length > 0) {
                //console.log('category filter', filters['category']);
                var options = categories.filter(o => o.label === categoryFilterValue);
                if (options && options.length > 0) {
                    selectedCategory = options[0];
                }
            }
            setValue('category', selectedCategory);
            setValue('internalNotes', "");
            setValue('internalId', "");
        }
    }, [selectedItem, status, filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <EntityModalBase 
            isOpen={isOpen}
            toggle={toggle}
            status={status}
            titleBase="item"
            baseUrl="item"
            getData={getData}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            entityIsCreated={onEntityCreated}
            entityIsUpdated={onEntityUpdated}
            msalInstance={msalInstance}
        >
            <Controller name="id" control={control} defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <FormGroup row className="form-group required">
                <Label for="code" sm={3}>Item code</Label>
                <Col sm={9}>
                    <Controller name="code" control={control} defaultValue={defaultValues.code}
                        render={({ field }) =>
                            <Input
                                {...field}
                                type="text"
                                onChange={handleCodeChange}
                            />
                        }
                    />
                    {errors.code && <p className="text-danger">{errors.code.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group required">
                <Label for="code" sm={3}>Description</Label>
                <Col sm={9}>
                    <Controller name="description" control={control} defaultValue={defaultValues.description}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="category" sm={3}>Category</Label>
                <Col sm={9}>
                    <Controller name="category" control={control} defaultValue={defaultValues.category}
                        render={({ field }) =>
                            <CustomSelect {...field}
                                options={categories}
                                isDisabled={status === Action.VIEW}
                            />}
                    />
                    {errors.category && <p className="text-danger">{errors.category.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="internalNotes" sm={3}>Internal notes</Label>
                <Col sm={9}>
                    <Controller name="internalNotes" control={control} defaultValue={defaultValues.internalNotes}
                        render={({field}) => <TextareaAutosize {...field} maxRows={10} minRows={2} className="form-control"/>}
                    />
                    {errors.internalNotes && <p className="text-danger">{errors.internalNotes.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="internalId" sm={3}>Internal ID</Label>
                <Col sm={9}>
                    <Controller name="internalId" control={control} defaultValue={defaultValues.internalId}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                {errors.internalId && <p className="text-danger">{errors.internalId.message}</p>}
                </Col>
            </FormGroup>
        </EntityModalBase>
    )
}

export default ItemModal;