import React, { useState } from 'react';
import { Label, Col, Tooltip } from 'reactstrap';

export default function LabelWithTooltip({
    id,
    labelFor,
    tooltipInfoText,
    iconUrl,
    tooltipText,
    children
}) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltipInfo, setShowTooltipInfo] = useState(false);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    }

    const toggleTooltipInfo = () => {
        setShowTooltipInfo(!showTooltipInfo);
    }

    if (tooltipInfoText || tooltipText) {
        return (
            <Col sm={3}>
                <Label for={labelFor}>{children}</Label>
                {tooltipInfoText  && <span className="p-1 pe-0" id={id + "info"}>
                    <img src="./icons/information.svg" className="icon icon-modal p-1" alt="" />
                    <Tooltip
                        placement="right"
                        isOpen={showTooltipInfo}
                        target={id + "info"}
                        toggle={toggleTooltipInfo}
                    >
                        {tooltipInfoText}
                    </Tooltip>
                </span>}
                {iconUrl && tooltipText && <span className="p-1" id={id}>
                    <img src={iconUrl} className="icon icon-modal p-1" alt="" />
                    <Tooltip
                        placement="right"
                        isOpen={showTooltip}
                        target={id}
                        toggle={toggleTooltip}
                    >
                        {tooltipText}
                    </Tooltip>
                </span>}
            </Col>
        );
    }

    return (<Label for={labelFor} sm={3}>{children}</Label>);
}