import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ListHeader from './ListHeader';
import { CustomSpinner } from './CustomSpinner';
import { Error } from './Error';
import CheckboxList from './CheckboxList';
import { Section, IconType } from '../../helpers/enums';

export default function FactoryList({
    setFactories,
    setSelectedFactories,
    mainStoreProperty
}) {
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const title = "Factories";
    const icon = IconType.BUILDING;

    let property = mainStoreProperty ?? Section.WORKSPACE;
    let selectedStorageId = useSelector(state => state[property].selectedStorageId);
    let factories = useSelector(state => state[property].factories); //console.log('factories', factories);
    let selectedFactories = useSelector(state => state[property].selectedFactories);//console.log('selectedFactories', selectedFactories);
    let isStorageDataLoading = useSelector(state => state[property].storageDataLoading);
    let isStoragesLoading = useSelector(state => state[property].storagesLoading);

    //console.log('selectedStorageId', selectedStorageId);

    const updateFactoryList = (list) => {
        if (setFactories){
            setFactories(list);
        }
    }

    const setActive = (factoryIds) => {
        //console.log('Selected factory', factoryIds);
        setSelectedFactories(factoryIds);
    }

    useEffect(() => {
        if (selectedStorageId === 0){
            updateFactoryList([]);
        }
    }, [selectedStorageId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isStorageDataLoading){
            setIsLoading(true);
        }
        else{
            setIsLoading(false);
            if (!factories || factories.length === 0){
                setError("No factories found!");
            }
            else {
                setError(null);
            }
        }
    }, [isStorageDataLoading, factories]);

    if (isStoragesLoading) {
        return (<></>);
    }

    if (loading){
        return (
            <>
                <ListHeader title={title} icon={icon}></ListHeader>
                <CustomSpinner />
            </>
        );
    }

    if (error){
        return (
            <>
                <ListHeader title={title} icon={icon}></ListHeader>
                <Error msg={error} />
            </>
        );
    }

    return (
        <CheckboxList title={title}
            listItems={factories}
            onItemSelected={setActive}
            selectedItems={selectedFactories}
            showButtons={true}
            icon={icon}
            restricted={true}
        />
    );
}