import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Role, RoleText } from '../../helpers/enums';
import { parseToNumber } from '../../helpers/commonHelpers';

export default function RoleRadioButtons({
    field,
    defaultValue,
    onRoleChanged,
    includeOwner = true
}) {
    const [value, setValue] = useState(defaultValue);

    const handleRoleChange = (e) => {
        let newValue = 1;
        if (e.target.value) {
            newValue = parseToNumber(e.target.value);
        }
        setValue(newValue);
        if (onRoleChanged) {
            onRoleChanged(newValue);
        }
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])

    return (<>
        <FormGroup check className="mt-2">
            <Label check>
                <Input {...field}
                    type="radio"
                    name="role"
                    value={Role.READER}
                    checked={value === Role.READER}
                    onChange={handleRoleChange}
                />
                <div className='ms-2'>{RoleText.READER}</div>
            </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
            <Label check>
                <Input {...field}
                    type="radio"
                    name="role"
                    value={Role.CONTRIBUTOR}
                    checked={value === Role.CONTRIBUTOR}
                    onChange={handleRoleChange}
                />
                <div className='ms-2'>{RoleText.CONTRIBUTOR}</div>
            </Label>
        </FormGroup>
        {includeOwner &&
            <FormGroup check className="mt-2">
                <Label check>
                    <Input {...field}
                        type="radio"
                        name="role"
                        value={Role.OWNER}
                        checked={value === Role.OWNER}
                        onChange={handleRoleChange}
                    />
                    <div className='ms-2'>{RoleText.OWNER}</div>
                </Label>
            </FormGroup>
        }
    </>)
}