import React from 'react';
import { Col } from 'reactstrap';
import ExportData from './ExportData';
import { CustomTable, getHiddenColumnsObject } from '../../helpers/tableHelpers';
import {
    useReactTable,
    getCoreRowModel,
    flexRender
} from '@tanstack/react-table'

export default function SimpleCustomTable({
    tableColumns,
    tableData,
    className,
    hiddenColumns,
    exportData
}) {
    //console.log('tableColumns', tableColumns);
    const data = React.useMemo(() => tableData, [tableData]);
    const columns = React.useMemo(() => tableColumns, [tableColumns]);
    const hidden = getHiddenColumnsObject(hiddenColumns); //console.log('hidden', hidden);
    const tableClasses = className ? className + " mt-2" : "mt-2";
    const showExport = exportData?.show ?? false;
    const fileName = exportData?.fileName ?? 'data';

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        initialState:{
            columnVisibility: hidden
        }
    });

    //console.log(table.getRowModel(), columns);
    return (
        <>
            <CustomTable
                table={table}
                render={flexRender}
                tableClasses={tableClasses}
                size="sm"
                enableSorting={false}
                showColumnFilters={false}
            />
            {showExport && <Col xs={12} className="d-flex justify-content-end">
                <ExportData rows={table.getRowModel().rows} fileName={fileName} />
            </Col>}
        </>
    );
}