import React, { useEffect } from 'react';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { SelectWithStyles } from '../../common/CustomSelect';
import { Section, Action } from '../../../helpers/enums';
import { getOption } from '../../../helpers/commonHelpers';
import EntityModalBase from './EntityModalBase';

const schema = yup.object().shape({
    code: yup.string().label('Code').required().max(30),
    description: yup.string().label('Description').required().max(50),
    types: yup.array().min(1,'Select at least one Type').required('Select a Type')
});

const GetSelectedValueTypes = (valueTypes, valueTypeOptions) => {
    //console.log('valueTypes and options', valueTypes, valueTypeOptions);
    if (!valueTypes || valueTypes.length === 0 || !valueTypeOptions || valueTypeOptions.length === 0){
        return [];
    }

    var vtIds = valueTypes.map(vt => vt.id);
    return valueTypeOptions.filter(o => vtIds.includes(o.value));
}

export default function ElementTypeModal({
    isOpen,
    toggle,
    status,
    selectedElementType,
    onEntityCreated,
    onEntityUpdated,
    msalInstance
}){
    const stateProperty = Section.WORKSPACE;
    const valueTypes = useSelector(state => state[stateProperty].storageValueTypes);
    const valueTypeOptions = valueTypes && valueTypes.length > 0 ? valueTypes.map(type => {
        return getOption(type.id, type.code, type.description )
    }) : [];
    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, setValue, clearErrors, trigger } = useForm({resolver: yupResolver(schema), mode: 'onChange'}); //console.log('errors', errors); console.log('getValues', getValues());
    //console.log('isValid, errors, values', isValid, errors, getValues());

    const defaultValues = {
        id: 0,
        code: "",
        description: "",
        types: []
    };

    const getData = (data) => {
        let { types, ...body } = data;
        if (data.types?.length > 0) {
            body.valueTypes = data.types.map(vt => vt.value);
        }
        return body;
    }

    const handleCodeChange = (input) => {
        clearErrors('submitError');
        trigger('submitError');
        setValue('code', input.target.value);
        trigger('code');
    }

    useEffect(() => {
        if (selectedElementType && selectedElementType.id > 0) {
            if (status !== Action.COPY) {
                setValue('id', selectedElementType.id);
            }
            setValue('code', selectedElementType.code);
            setValue('description', selectedElementType.description);
            setValue('types', GetSelectedValueTypes(selectedElementType.valueTypes, valueTypeOptions));
            trigger();
        }
        else {
            setValue('id', 0);
            setValue('code', "");
            setValue('description', "");
            setValue('types', []);
        }
    }, [selectedElementType]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <EntityModalBase
            isOpen={isOpen}
            toggle={toggle}
            status={status}
            titleBase="element type"
            baseUrl="elementType"
            getData={getData}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            entityIsCreated={onEntityCreated}
            entityIsUpdated={onEntityUpdated}
            msalInstance={msalInstance}
        >
            <Controller name="id"
                control={control}
                defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <FormGroup row className="required form-group">
                <Label for="code" sm={3}>Elem. code</Label>
                <Col sm={9}>
                    <Controller name="code"
                        control={control}
                        defaultValue={defaultValues.code}
                        render={({ field }) =>
                            <Input
                                {...field}
                                type="text"
                                onChange={handleCodeChange}
                            />
                        }
                    />
                    {errors.code && <p className="text-danger">{errors.code.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="description" sm={3}>Description</Label>
                <Col sm={9}>
                    <Controller name="description"
                        control={control}
                        defaultValue={defaultValues.description}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="group" sm={3}>Value types</Label>
                <Col sm={9}>
                    <Controller name="types"
                        control={control}
                        defaultValue={defaultValues.types}
                        render={({ field }) =>
                            <SelectWithStyles {...field}
                                options={valueTypeOptions} isClearable isMulti
                                isDisabled={status === Action.VIEW}
                            />}
                    />
                    {errors.types && <p className="text-danger">{errors.types.message}</p>}
                </Col>
            </FormGroup>
        </EntityModalBase>
    );
}