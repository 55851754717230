import React from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { useSelector } from 'react-redux';

function ListGroupList({
    type,
    onItemSelected,
    disabled,
    showIconsFor = [],
    showIconForAll = false,
    }){
    
    const setActive = (item) => {
        //console.log('Selected item', item);
        onItemSelected(item);
    }

    let listItems = useSelector(state => state[type].list);
    let selected = useSelector(state => state[type].activeItem);
    let selectedId = selected ? selected.id : 0;

    return (
        <ListGroup className="m-2 ms-3 me-3 me-lg-0">
            {listItems.map(entity => 
                <ListGroupItem key={entity.id} action
                    tag="button"
                    className={entity.id === selectedId ? "border-0 active" : "border-0"} 
                    onClick={() => setActive(entity)}
                    disabled={disabled}
                >
                    <ListGroupItemHeading className="mb-0">
                        {entity.code}
                        {(showIconForAll || (showIconsFor.length > 0 && showIconsFor.includes(entity.id))) && 
                            <span><img src="./icons/owner.svg" className="icon icon-sidebar owner ms-1" title="Full permissions" alt="" /></span>
                        }
                    </ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">{entity.name}</ListGroupItemText>
                    {
                        entity.factorySymbol && 
                        <ListGroupItemText className="mb-0">{entity.factorySymbol}</ListGroupItemText>
                    }
                </ListGroupItem>
            )}
        </ListGroup>
    );
}

export default ListGroupList;