import { ActionTypes } from "../helpers/enums";

const defaultValues = {
    activeItem: {},
    noItemsFound: false,
    formHidden: false
}

const inspector = (state = defaultValues, action) => {
    switch (action.type) {
        case ActionTypes.SET_QUERY_ACTIVE:
            return {
                ...state,
                activeItem: action.payload
            };
        case ActionTypes.SET_QUERY_NO_ITEMS_FOUND:
            return {
                ...state,
                noItemsFound: action.payload
            }
        case ActionTypes.SET_QUERY_HIDE:
            return {
                ...state,
                formHidden: action.payload
            };
        default:
            return state;
    }
}

export default inspector;